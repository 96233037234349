import {
  Button,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import { UserNickname } from "components/common/UserNickname";
import { MBATable, Query } from "components/table/MBATable";
import { RouteParams } from "conf/routes";
import { getUserFolder } from "helpers/millionbb";
import { FileStatusEnum, IsAdultEnum } from "millionbb-common";
import { client, mutations, queries } from "ql";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ConfirmDialogue from "./Upload/ConfirmDialogue";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  uploadThumbnail: {
    width: 100,
    height: 100,
    objectFit: "cover",
  },
}));

export enum UploadDialogueEnum {
  delete = "delete",
  undelete = "undelete",
  markAdult = "mark adult",
  unmarkAdult = "unmark adult",
}

export const Uploads = withRouter(
  ({ match, history }: RouteComponentProps<RouteParams>) => {
    const tableRef: any = useRef(null);
    const classes = useStyles({});
    const { t } = useTranslation("uploads");
    const [filter] = useState("");
    const [isDialogue, setDialogue] = useState(false);
    const [confirm, setConfirm] = useState<any>();
    const [action, setAction] = useState<UploadDialogueEnum>();
    const [updateFile] = useMutation(mutations.updateFile);
    const handleCancel = () => {
      setDialogue(false);
    };
    let nickname = filter;

    useEffect(() => {
      const { field, value } = match.params;
      if (field && !value) history.push("/admin/uploads");
      if (field && !["userId", "username"].includes(field))
        history.push("/admin/uploads");
    }, [history, match.params.field, match.params.value, match.params]);

    return (
      <>
        <ConfirmDialogue
          isDialogue={isDialogue}
          setDialogue={setDialogue}
          confirm={confirm}
          handleCancel={handleCancel}
          action={action}
        />
        <MBATable
          title={t("menu:uploads")}
          options={{ filtering: true }}
          tableRef={tableRef}
          columns={[
            { title: t("field.file"), field: "file", filtering: true },
            {
              title: t("field.user"),
              render: ({ user }: any) => <UserNickname {...user} />,
            },
            { title: t("field.date"), field: "date", filtering: false },
            { title: t("field.status"), field: "status", filtering: false },
            { title: t("field.adult"), field: "isAdult", filtering: false },
            { title: t("field.manage"), field: "manage", filtering: false },
          ]}
          data={async ({ page, pageSize }: Query<any>) => {
            return new Promise(async (res, rej) => {
              const { field, value } = match.params;
              // console.log(field, value);
              const { data } = await client.query({
                query:
                  filter !== "" || (field && value)
                    ? queries.fileUserUploads
                    : queries.fileUploads,
                variables: {
                  ...(filter !== "" ? { username: filter } : {}),
                  ...(!field ? {} : { [field]: value }),
                  page: page + 1,
                  pageSize: pageSize,
                },
              });
              const dataArray = data.files.files.map((fileObject: any) => {
                fileObject.date = new Date(
                  parseInt(fileObject.lastModifyDt, 10)
                ).toLocaleString();
                fileObject.file = (
                  <img
                    className={classes.uploadThumbnail}
                    src={getUserFolder(fileObject.userId) + fileObject.filename}
                    alt="upload"
                  />
                );
                fileObject.manage = (
                  <>
                    {fileObject.isAdult === IsAdultEnum.Family ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={async () => {
                          setDialogue(true);
                          setAction(UploadDialogueEnum.markAdult);
                          setConfirm({
                            handle: async () => {
                              const result = await updateFile({
                                variables: {
                                  fileId: fileObject.fileId,
                                  userId: fileObject.userId,
                                  status: FileStatusEnum.Active,
                                  isAdult: IsAdultEnum.Adult,
                                },
                              });
                              if (
                                result.data.putFile.isAdult ===
                                IsAdultEnum.Adult
                              ) {
                                alert("Marked as Adult Sucessfully");
                                tableRef.current.onQueryChange();
                              } else {
                                alert("Error occurred");
                              }
                            },
                          });
                        }}
                      >
                        Mark as Adult
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={async () => {
                          setDialogue(true);
                          setAction(UploadDialogueEnum.unmarkAdult);
                          setConfirm({
                            handle: async () => {
                              const result = await updateFile({
                                variables: {
                                  fileId: fileObject.fileId,
                                  userId: fileObject.userId,
                                  status: FileStatusEnum.Active,
                                  isAdult: IsAdultEnum.Family,
                                },
                              });
                              if (
                                result.data.putFile.isAdult ===
                                IsAdultEnum.Family
                              ) {
                                alert("Marked as Family Sucessfully");
                                tableRef.current.onQueryChange();
                              } else {
                                alert("Error occurred");
                              }
                            },
                          });
                        }}
                      >
                        Mark as Family
                      </Button>
                    )}

                    {fileObject.status === FileStatusEnum.Deleted ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={async () => {
                          setDialogue(true);
                          setAction(UploadDialogueEnum.undelete);
                          setConfirm({
                            handle: async () => {
                              const result = await updateFile({
                                variables: {
                                  fileId: fileObject.fileId,
                                  userId: fileObject.userId,
                                  status: FileStatusEnum.Active,
                                },
                              });
                              if (
                                result.data.putFile.status ===
                                FileStatusEnum.Active
                              ) {
                                alert("Marked as Active Sucessfully");
                                tableRef.current.onQueryChange();
                              } else {
                                alert("Error occurred");
                              }
                            },
                          });
                        }}
                      >
                        Mark Active
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={async () => {
                          setDialogue(true);
                          setAction(UploadDialogueEnum.delete);
                          setConfirm({
                            handle: async () => {
                              const result = await updateFile({
                                variables: {
                                  fileId: fileObject.fileId,
                                  userId: fileObject.userId,
                                  status: FileStatusEnum.Deleted,
                                },
                              });
                              if (
                                result.data.putFile.status ===
                                FileStatusEnum.Deleted
                              ) {
                                alert("File is Deleted Successfully");
                                tableRef.current.onQueryChange();
                              } else {
                                alert("Error occurred");
                              }
                            },
                          });
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </>
                );
                return fileObject;
              });

              res({
                data: dataArray,
                page: data.files.page - 1,
                totalCount: data.files.count,
              });
            });
          }}
          components={{
            FilterRow: ({ columns, onFilterChanged }: any) => {
              return (
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <TextField
                      variant="outlined"
                      defaultValue={filter}
                      onChange={(e) => {
                        nickname = e.target.value;
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          // setFilter(nickname);
                          // onFilterChanged(1, nickname);
                          history.push(`/admin/uploads/username/${nickname}`);
                          window.location.reload();
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                  <TableCell colSpan={4} />
                </TableRow>
              );
            },
          }}
        />
      </>
    );
  }
);
export default Uploads;
