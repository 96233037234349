import { GraphQLResolveInfo } from "graphql";
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export type Admin = {
  __typename?: "Admin";
  idToken?: Maybe<Scalars["String"]>;
  adminId: Scalars["ID"];
  username: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["Int"]>;
  createDt?: Maybe<Scalars["String"]>;
  lastModifyDt?: Maybe<Scalars["String"]>;
  lastIpv4?: Maybe<Scalars["String"]>;
};

export type AdminLoginInput = {
  username: Scalars["String"];
  password: Scalars["String"];
};

export type AdminUpgradeUserInput = {
  userId: Scalars["ID"];
  type?: Maybe<Scalars["Int"]>;
  day?: Maybe<Scalars["Int"]>;
  frdQuota?: Maybe<Scalars["Int"]>;
  credit?: Maybe<Scalars["Int"]>;
  creditSpent?: Maybe<Scalars["Int"]>;
  msgQuota?: Maybe<Scalars["Int"]>;
  wine?: Maybe<Scalars["Int"]>;
  emptyWine?: Maybe<Scalars["Int"]>;
};

export type AdminUserInput = {
  userId: Scalars["ID"];
  gender?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  dob?: Maybe<Scalars["String"]>;
  aboutMe?: Maybe<Scalars["String"]>;
  dateOutline?: Maybe<Scalars["String"]>;
  frdQuota?: Maybe<Scalars["Int"]>;
  telegram?: Maybe<Scalars["String"]>;
  wechat?: Maybe<Scalars["String"]>;
  whatsapp?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["String"]>;
  status?: Maybe<UserStatusEnum>;
  restrictedLevel?: Maybe<RestrictedLevelEnum>;
  reason?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  logoHalf?: Maybe<Scalars["String"]>;
  logoFull?: Maybe<Scalars["String"]>;
  millionbb?: Maybe<Scalars["Boolean"]>;
  princessDating?: Maybe<Scalars["Boolean"]>;
};

export type Album = {
  __typename?: "Album";
  albumId: Scalars["ID"];
  user?: Maybe<User>;
  userId: Scalars["ID"];
  files?: Maybe<FilePage>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  permission: PermissionEnum;
  isAdult: IsAdultEnum;
  status: FileStatusEnum;
  createDt: Scalars["String"];
  lastModifyDt: Scalars["String"];
};

export type AlbumFilesArgs = {
  input: PageInput;
};

export type AlbumInput = {
  albumId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  permission?: Maybe<PermissionEnum>;
  isAdult?: Maybe<IsAdultEnum>;
  status: FileStatusEnum;
};

export type AlbumPage = {
  __typename?: "AlbumPage";
  albums: Array<Album>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export type AlbumSearchInput = {
  userId?: Maybe<Scalars["ID"]>;
  appName?: Maybe<AppNameEnum>;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export enum AppNameEnum {
  Millionbb = "MILLIONBB",
  PricessDating = "PRICESS_DATING",
}

export enum CacheControlScope {
  Public = "PUBLIC",
  Private = "PRIVATE",
}

export enum CreditActionEnum {
  MakeFriendDirectly = "MAKE_FRIEND_DIRECTLY",
  FriendRequestSent = "FRIEND_REQUEST_SENT",
  FriendRequestAccept = "FRIEND_REQUEST_ACCEPT",
  FriendRequestReject = "FRIEND_REQUEST_REJECT",
  PrivateMsg = "PRIVATE_MSG",
  PurchasePremium = "PURCHASE_PREMIUM",
  PurchaseCredit = "PURCHASE_CREDIT",
  AdminUpgrade = "ADMIN_UPGRADE",
  AdminEdit = "ADMIN_EDIT",
  SystemDistribute = "SYSTEM_DISTRIBUTE",
  RefundPremium = "REFUND_PREMIUM",
  RefundCredit = "REFUND_CREDIT",
  PurchaseFrdQuota = "PURCHASE_FRD_QUOTA",
  PurchaseWine = "PURCHASE_WINE",
  ReturnWine = "RETURN_WINE",
  BonusGrasVerify = "BONUS_GRAS_VERIFY",
  BonusGrasRate = "BONUS_GRAS_RATE",
}

export enum CreditCurrencyEnum {
  Cred = "CRED",
  Ucre = "UCRE",
  Frdq = "FRDQ",
  Msgq = "MSGQ",
  Wine = "WINE",
  Gras = "GRAS",
}

export type CreditLog = {
  __typename?: "CreditLog";
  id: Scalars["ID"];
  userId?: Maybe<Scalars["ID"]>;
  currency?: Maybe<Scalars["String"]>;
  amount?: Maybe<Scalars["Int"]>;
  action?: Maybe<CreditActionEnum>;
  user?: Maybe<User>;
  createDt: Scalars["String"];
  lastModifyDt: Scalars["String"];
};

export type CreditLogPage = {
  __typename?: "CreditLogPage";
  creditLogs: Array<CreditLog>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export type CreditLogSearchInput = {
  id?: Maybe<Scalars["ID"]>;
  userId?: Maybe<Scalars["ID"]>;
  username?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  action?: Maybe<Scalars["String"]>;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type CryptocurrencyPurchaseInput = {
  id?: Maybe<Scalars["ID"]>;
  userId?: Maybe<Scalars["ID"]>;
  status: WineReturnStatusEnum;
  cryptocurrencyType: CryptocurrencyTypeEnum;
  quantity: Scalars["Int"];
  accountNumber: Scalars["String"];
};

export enum CryptocurrencyTypeEnum {
  Eth = "ETH",
  Bitcoin = "BITCOIN",
  Usdt = "USDT",
}

export enum DatatypeEnum {
  Base64 = "BASE64",
  Text = "TEXT",
}

export enum DeviceTypeEnum {
  Ios = "IOS",
  Android = "ANDROID",
}

export type FavQueryInput = {
  userId?: Maybe<Scalars["ID"]>;
  favouriteUserId?: Maybe<Scalars["ID"]>;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type File = {
  __typename?: "File";
  fileId: Scalars["ID"];
  user?: Maybe<User>;
  userId: Scalars["ID"];
  albumId: Scalars["ID"];
  filename: Scalars["String"];
  mime: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  permission: PermissionEnum;
  isAdult: IsAdultEnum;
  status: FileStatusEnum;
  createDt: Scalars["String"];
  lastModifyDt: Scalars["String"];
};

export type FileInput = {
  fileId?: Maybe<Scalars["ID"]>;
  userId?: Maybe<Scalars["ID"]>;
  type?: Maybe<FileInputTypeEnum>;
  albumId?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  mime?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  permission?: Maybe<PermissionEnum>;
  isAdult?: Maybe<IsAdultEnum>;
  status?: Maybe<FileStatusEnum>;
};

export enum FileInputTypeEnum {
  ProfilePic = "PROFILE_PIC",
  ProfilePicHalf = "PROFILE_PIC_HALF",
  ProfilePicFull = "PROFILE_PIC_FULL",
  AlbumImg = "ALBUM_IMG",
  VoiceIntro = "VOICE_INTRO",
}

export type FilePage = {
  __typename?: "FilePage";
  files: Array<File>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export type FileSearchInput = {
  albumId?: Maybe<Scalars["ID"]>;
  userId?: Maybe<Scalars["ID"]>;
  username?: Maybe<Scalars["String"]>;
  appName?: Maybe<AppNameEnum>;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export enum FileStatusEnum {
  Active = "ACTIVE",
  Deleted = "DELETED",
  Banned = "BANNED",
}

export type FriendQueryInput = {
  userId?: Maybe<Scalars["ID"]>;
  friendUserId?: Maybe<Scalars["ID"]>;
  status?: Maybe<UserFriendStatusEnum>;
  nickname?: Maybe<Scalars["String"]>;
  orderDirection?: Maybe<OrderDirectionEnum>;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export enum IsAdultEnum {
  Family = "FAMILY",
  Adult = "ADULT",
}

export enum LogTypeEnum {
  Undefined = "UNDEFINED",
  PayRequest = "PAY_REQUEST",
  PayResponse = "PAY_RESPONSE",
  SubscribeRequest = "SUBSCRIBE_REQUEST",
  SubscribeInitial = "SUBSCRIBE_INITIAL",
  SubscribeRebill = "SUBSCRIBE_REBILL",
  SubscribeCancel = "SUBSCRIBE_CANCEL",
  SubscribeExtend = "SUBSCRIBE_EXTEND",
  SubscribeExpiry = "SUBSCRIBE_EXPIRY",
  ChargeBack = "CHARGE_BACK",
}

export type Message = {
  __typename?: "Message";
  id: Scalars["ID"];
  sender: User;
  recipant: User;
  filename?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  status: MessageStatusEnum;
  createDt: Scalars["String"];
  lastModifyDt: Scalars["String"];
};

export type MessagePage = {
  __typename?: "MessagePage";
  messages: Array<Message>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export enum MessageStatusEnum {
  Sent = "SENT",
  Read = "READ",
  Deleted = "DELETED",
  Request = "REQUEST",
  Accept = "ACCEPT",
  Reject = "REJECT",
}

export type MsgQueryInput = {
  userId?: Maybe<Scalars["ID"]>;
  unread?: Maybe<Scalars["Boolean"]>;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type MsgSentInput = {
  recipant: Scalars["ID"];
  file?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  _?: Maybe<Scalars["String"]>;
  adminLogin: Admin;
  adminUpgradeUser?: Maybe<Scalars["Int"]>;
  adminPutUser?: Maybe<User>;
  adminPutMessage?: Maybe<Scalars["String"]>;
  makeFriend: UserFriend;
  rateFriend: UserFriend;
  addFavourite?: Maybe<UserFav>;
  putFile: File;
  putAlbum: Album;
  putWallPost: WallPost;
  sendMessage?: Maybe<Message>;
  putUserObject: UserObject;
  putPayment: Scalars["String"];
  putSubscription: Subscription;
  putVirtualPurchase: Scalars["String"];
  putCryptocurrencyPurchase: Scalars["String"];
  putUserSettings: Array<UserSetting>;
  putReport: Report;
  putTicket: Ticket;
  userFbLogin: User;
  userAppleLogin: User;
  userLogin: User;
  userLogout: Scalars["String"];
  userRegister: User;
  userVerify: User;
  userPutProfile: User;
  userPutAbout: User;
  userForgetPassword: User;
  userResetPassword: User;
  userPutVerify: User;
  userDelete: User;
  userLastSeen: User;
};

export type MutationAdminLoginArgs = {
  input: AdminLoginInput;
};

export type MutationAdminUpgradeUserArgs = {
  input: AdminUpgradeUserInput;
};

export type MutationAdminPutUserArgs = {
  input: AdminUserInput;
};

export type MutationAdminPutMessageArgs = {
  input: MsgSentInput;
};

export type MutationMakeFriendArgs = {
  input: UserMakeFriendRequest;
};

export type MutationRateFriendArgs = {
  input: UserRateFriendRequest;
};

export type MutationAddFavouriteArgs = {
  input: UserAddFavouriteRequest;
};

export type MutationPutFileArgs = {
  input: FileInput;
};

export type MutationPutAlbumArgs = {
  input: AlbumInput;
};

export type MutationPutWallPostArgs = {
  input: WallPostInput;
};

export type MutationSendMessageArgs = {
  input: MsgSentInput;
};

export type MutationPutUserObjectArgs = {
  input: UserObjectInput;
};

export type MutationPutPaymentArgs = {
  input: PaymentInput;
};

export type MutationPutSubscriptionArgs = {
  input: SubscriptionInput;
};

export type MutationPutVirtualPurchaseArgs = {
  input: VirtualPurchaseInput;
};

export type MutationPutCryptocurrencyPurchaseArgs = {
  input: CryptocurrencyPurchaseInput;
};

export type MutationPutUserSettingsArgs = {
  inputs: Array<UserSettingInput>;
};

export type MutationPutReportArgs = {
  input: ReportInput;
};

export type MutationPutTicketArgs = {
  input: TicketInput;
};

export type MutationUserFbLoginArgs = {
  input: UserFbInput;
};

export type MutationUserAppleLoginArgs = {
  input: UserAppleInput;
};

export type MutationUserLoginArgs = {
  input: UserLoginInput;
};

export type MutationUserLogoutArgs = {
  input: UserLogoutInput;
};

export type MutationUserRegisterArgs = {
  input: UserRegisterInput;
};

export type MutationUserVerifyArgs = {
  input: UserVerifyInput;
};

export type MutationUserPutProfileArgs = {
  input: UserPutProfileInput;
};

export type MutationUserPutAboutArgs = {
  input: UserPutAboutInput;
};

export type MutationUserForgetPasswordArgs = {
  input: UserForgetPasswordInput;
};

export type MutationUserResetPasswordArgs = {
  input: UserResetPasswordInput;
};

export type MutationUserPutVerifyArgs = {
  input: UserPutVerifyInput;
};

export enum OrderDirectionEnum {
  Asc = "ASC",
  Desc = "DESC",
}

export type PageInput = {
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type PaymentInput = {
  id?: Maybe<Scalars["ID"]>;
  email?: Maybe<Scalars["String"]>;
  paymentType: PaymentTypeEnum;
  plan: PaymentPlanEnum;
  currency: Scalars["String"];
  amount: Scalars["Int"];
  logType: LogTypeEnum;
  token?: Maybe<Scalars["String"]>;
};

export type PaymentLog = {
  __typename?: "PaymentLog";
  id?: Maybe<Scalars["ID"]>;
  user?: Maybe<User>;
  paymentType: PaymentTypeEnum;
  currency: Scalars["String"];
  amount: Scalars["Int"];
  logType: LogTypeEnum;
  data?: Maybe<Scalars["String"]>;
  createDt: Scalars["String"];
  lastModifyDt: Scalars["String"];
};

export type PaymentPage = {
  __typename?: "PaymentPage";
  payments: Array<PaymentLog>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export enum PaymentPlanEnum {
  HqMember = "HQ_MEMBER",
  HLvMember = "H_LV_MEMBER",
  Premium = "PREMIUM",
  Credit_10 = "CREDIT_10",
  Credit_50 = "CREDIT_50",
  Credit_100 = "CREDIT_100",
}

export type PaymentQueryInput = {
  id?: Maybe<Scalars["ID"]>;
  userId?: Maybe<Scalars["ID"]>;
  nickname?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  plan?: Maybe<PaymentPlanEnum>;
  paymentType?: Maybe<PaymentTypeEnum>;
  logType?: Maybe<LogTypeEnum>;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export enum PaymentTypeEnum {
  Verotel = "VEROTEL",
  Stripe = "STRIPE",
}

export enum PermissionEnum {
  AllowAll = "ALLOW_ALL",
  OnlyFriend = "ONLY_FRIEND",
}

export type Query = {
  __typename?: "Query";
  _?: Maybe<Scalars["String"]>;
  friends: UserFriendPage;
  favourites: UserFavPage;
  files: FilePage;
  albums: AlbumPage;
  wallPosts: WallPostPage;
  messages: MessagePage;
  userObject?: Maybe<UserObject>;
  payments: PaymentPage;
  subscriptions: SubscriptionPage;
  wineReturns: WineReturnPage;
  userSettings: Array<UserSetting>;
  reports: ReportPage;
  tickets: TicketPage;
  user: User;
  users: Array<Maybe<User>>;
  searchUsers: UserPage;
  views: UserViewPage;
  creditLogs: CreditLogPage;
  userShare: UserReferreePage;
};

export type QueryFriendsArgs = {
  input: FriendQueryInput;
};

export type QueryFavouritesArgs = {
  input: FavQueryInput;
};

export type QueryFilesArgs = {
  input: FileSearchInput;
};

export type QueryAlbumsArgs = {
  input: AlbumSearchInput;
};

export type QueryWallPostsArgs = {
  input: WallPostSearchInput;
};

export type QueryMessagesArgs = {
  input: MsgQueryInput;
};

export type QueryUserObjectArgs = {
  input: UserObjectQueryInput;
};

export type QueryPaymentsArgs = {
  input: PaymentQueryInput;
};

export type QuerySubscriptionsArgs = {
  input: SubscriptionQueryInput;
};

export type QueryWineReturnsArgs = {
  input?: Maybe<WineReturnInput>;
};

export type QueryReportsArgs = {
  input?: Maybe<ReportSearchInput>;
};

export type QueryTicketsArgs = {
  input?: Maybe<TicketSearchInput>;
};

export type QueryUserArgs = {
  input?: Maybe<SingleUserInput>;
};

export type QueryUsersArgs = {
  input: UserSearchInput;
};

export type QuerySearchUsersArgs = {
  input: UserSearchInput;
};

export type QueryViewsArgs = {
  input?: Maybe<UserViewQueryInput>;
};

export type QueryCreditLogsArgs = {
  input: CreditLogSearchInput;
};

export type QueryUserShareArgs = {
  input?: Maybe<UserReferreeInput>;
};

export type RateSummary = {
  __typename?: "RateSummary";
  rate: Scalars["Float"];
  count: Scalars["Int"];
};

export type Report = {
  __typename?: "Report";
  id: Scalars["ID"];
  reporter?: Maybe<User>;
  user?: Maybe<User>;
  file?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  status: TicketStatusEnum;
  createDt: Scalars["String"];
  lastModifyDt: Scalars["String"];
};

export type ReportInput = {
  id?: Maybe<Scalars["ID"]>;
  userId?: Maybe<Scalars["ID"]>;
  fileId?: Maybe<Scalars["ID"]>;
  description?: Maybe<Scalars["String"]>;
  status: TicketStatusEnum;
};

export type ReportPage = {
  __typename?: "ReportPage";
  reports: Array<Report>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export type ReportSearchInput = {
  status: TicketStatusEnum;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export enum RestrictedLevelEnum {
  Normal = "NORMAL",
  MillionbbOnly = "MILLIONBB_ONLY",
  MillionbbOnlyPending = "MILLIONBB_ONLY_PENDING",
  Banned = "BANNED",
  BannedPending = "BANNED_PENDING",
}

export enum SettingKeyEnum {
  DisableNotifyUserView = "DISABLE_NOTIFY_USER_VIEW",
}

export type SingleUserInput = {
  userId?: Maybe<Scalars["ID"]>;
  referralCode?: Maybe<Scalars["String"]>;
  princessDating?: Maybe<Scalars["Boolean"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  subscriptionId: Scalars["ID"];
  user?: Maybe<User>;
  paymentType: PaymentTypeEnum;
  plan: PaymentPlanEnum;
  currency: Scalars["String"];
  amount: Scalars["Int"];
  status: SubscriptionStatusEnum;
  nextChargeDt: Scalars["String"];
  createDt: Scalars["String"];
  lastModifyDt: Scalars["String"];
};

export type SubscriptionInput = {
  id: Scalars["ID"];
  status: SubscriptionStatusEnum;
};

export type SubscriptionPage = {
  __typename?: "SubscriptionPage";
  subscriptions: Array<Subscription>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export type SubscriptionQueryInput = {
  id?: Maybe<Scalars["ID"]>;
  userId?: Maybe<Scalars["ID"]>;
  nickname?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  plan?: Maybe<PaymentPlanEnum>;
  status?: Maybe<SubscriptionStatusEnum>;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export enum SubscriptionStatusEnum {
  Active = "ACTIVE",
  InactiveBySystem = "INACTIVE_BY_SYSTEM",
  InactiveByAdmin = "INACTIVE_BY_ADMIN",
}

export type Ticket = {
  __typename?: "Ticket";
  id: Scalars["ID"];
  user?: Maybe<User>;
  type: TicketTypeEnum;
  description: Scalars["String"];
  status: TicketStatusEnum;
  createDt: Scalars["String"];
  lastModifyDt: Scalars["String"];
};

export type TicketInput = {
  id?: Maybe<Scalars["ID"]>;
  inputUserId?: Maybe<Scalars["ID"]>;
  type?: Maybe<TicketTypeEnum>;
  description?: Maybe<Scalars["String"]>;
  status: TicketStatusEnum;
};

export type TicketPage = {
  __typename?: "TicketPage";
  tickets: Array<Ticket>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export type TicketSearchInput = {
  userId?: Maybe<Scalars["ID"]>;
  status: TicketStatusEnum;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export enum TicketStatusEnum {
  Active = "ACTIVE",
  Deleted = "DELETED",
  Proceeded = "PROCEEDED",
}

export enum TicketTypeEnum {
  User = "USER",
  Admin = "ADMIN",
}

export type User = {
  __typename?: "User";
  idToken?: Maybe<Scalars["String"]>;
  userId: Scalars["ID"];
  gender: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  referrer?: Maybe<Scalars["ID"]>;
  referralCode?: Maybe<Scalars["String"]>;
  dob?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  logoHalf?: Maybe<Scalars["String"]>;
  logoFull?: Maybe<Scalars["String"]>;
  ageMin?: Maybe<Scalars["Int"]>;
  ageMax?: Maybe<Scalars["Int"]>;
  whatsapp?: Maybe<Scalars["String"]>;
  wechat?: Maybe<Scalars["String"]>;
  telegram?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["String"]>;
  aboutEconomy?: Maybe<UserAboutEconomyEnum>;
  aboutHeight?: Maybe<UserAboutHeightEnum>;
  aboutBody?: Maybe<UserAboutBodyEnum>;
  aboutWaist?: Maybe<UserAboutWaistEnum>;
  aboutStyle?: Maybe<Scalars["Int"]>;
  aboutDrink?: Maybe<UserAboutDrinkEnum>;
  aboutSmoke?: Maybe<UserAboutSmokeEnum>;
  aboutMe?: Maybe<Scalars["String"]>;
  dateExpense?: Maybe<UserDateExpenseEnum>;
  dateOutline?: Maybe<Scalars["String"]>;
  contactPrivacy?: Maybe<UserContactPrivacyEnum>;
  lang?: Maybe<Scalars["String"]>;
  showAdult?: Maybe<Scalars["Int"]>;
  credit?: Maybe<Scalars["Int"]>;
  creditSpent?: Maybe<Scalars["Int"]>;
  frdQuota?: Maybe<Scalars["Int"]>;
  wine?: Maybe<Scalars["Int"]>;
  emptyWine?: Maybe<Scalars["Int"]>;
  msgQuota?: Maybe<Scalars["Int"]>;
  silverEndDt?: Maybe<Scalars["String"]>;
  premiumEndDt?: Maybe<Scalars["String"]>;
  role: UserRoleEnum;
  voice: UserVerifyEnum;
  ageVerify: UserVerifyEnum;
  status: UserStatusEnum;
  lastSeen?: Maybe<Scalars["String"]>;
  rateLevel?: Maybe<UserRateLevelEnum>;
  restrictedLevel?: Maybe<RestrictedLevelEnum>;
  vip?: Maybe<Scalars["Int"]>;
  millionbb?: Maybe<Scalars["Boolean"]>;
  princessDating?: Maybe<Scalars["Boolean"]>;
  lastIpv4?: Maybe<Scalars["String"]>;
  rateSummary?: Maybe<RateSummary>;
  favourite?: Maybe<UserFavStatusEnum>;
  friendStatus?: Maybe<UserFriendStatusEnum>;
  createDt: Scalars["String"];
  lastModifyDt: Scalars["String"];
};

export enum UserAboutBodyEnum {
  Athletic = "ATHLETIC",
  Normal = "NORMAL",
  Other = "OTHER",
  Stout = "STOUT",
  Superfluous = "SUPERFLUOUS",
  Thin = "THIN",
}

export enum UserAboutDrinkEnum {
  Never = "NEVER",
  Sometime = "SOMETIME",
  Usually = "USUALLY",
}

export enum UserAboutEconomyEnum {
  Urgent = "URGENT",
  NotEnough = "NOT_ENOUGH",
  SometimeNo = "SOMETIME_NO",
  Sufficient = "SUFFICIENT",
  SomeSpare = "SOME_SPARE",
  LotSpare = "LOT_SPARE",
  TooMuch = "TOO_MUCH",
}

export enum UserAboutHeightEnum {
  Cm150 = "CM150",
  Cm151 = "CM151",
  Cm156 = "CM156",
  Cm161 = "CM161",
  Cm166 = "CM166",
  Cm171 = "CM171",
  Cm181 = "CM181",
  Cm191 = "CM191",
  Cm200 = "CM200",
}

export enum UserAboutSmokeEnum {
  Never = "NEVER",
  Sometime = "SOMETIME",
  Always = "ALWAYS",
}

export enum UserAboutWaistEnum {
  In19 = "IN19",
  In20 = "IN20",
  In23 = "IN23",
  In26 = "IN26",
  In29 = "IN29",
  In32 = "IN32",
  In36 = "IN36",
  In41 = "IN41",
  In51 = "IN51",
  In60 = "IN60",
}

export type UserAddFavouriteRequest = {
  userId: Scalars["ID"];
  status?: Maybe<UserFavStatusEnum>;
};

export type UserAppleInput = {
  gender: Scalars["String"];
  lang?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  authorizationCode: Scalars["String"];
  user: Scalars["String"];
};

export enum UserContactPrivacyEnum {
  AllPaid = "ALL_PAID",
  OnlyPremium = "ONLY_PREMIUM",
  NoOne = "NO_ONE",
}

export enum UserDateExpenseEnum {
  AssumeAll = "ASSUME_ALL",
  Split = "SPLIT",
  ExpectOther = "EXPECT_OTHER",
  DoesntMatter = "DOESNT_MATTER",
}

export type UserFav = {
  __typename?: "UserFav";
  id?: Maybe<Scalars["ID"]>;
  user?: Maybe<User>;
  favouriteUser?: Maybe<User>;
  status?: Maybe<UserFavStatusEnum>;
  createDt: Scalars["String"];
  lastModifyDt: Scalars["String"];
};

export type UserFavPage = {
  __typename?: "UserFavPage";
  favs: Array<UserFav>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export enum UserFavStatusEnum {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type UserFbInput = {
  referrer?: Maybe<Scalars["ID"]>;
  gender: Scalars["String"];
  lang?: Maybe<Scalars["String"]>;
  fbToken: Scalars["String"];
  email: Scalars["String"];
  princessDating?: Maybe<Scalars["Boolean"]>;
};

export type UserForgetPasswordInput = {
  email?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  princessDating?: Maybe<Scalars["Boolean"]>;
  lang: Scalars["String"];
};

export type UserFriend = {
  __typename?: "UserFriend";
  id?: Maybe<Scalars["ID"]>;
  user?: Maybe<User>;
  friendUser?: Maybe<User>;
  rate?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  status?: Maybe<UserFriendStatusEnum>;
  createDt: Scalars["String"];
  lastModifyDt: Scalars["String"];
};

export type UserFriendPage = {
  __typename?: "UserFriendPage";
  friends: Array<UserFriend>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export enum UserFriendStatusEnum {
  None = "NONE",
  Unrespond = "UNRESPOND",
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
  Requested = "REQUESTED",
  ByQuota = "BY_QUOTA",
}

export type UserLoginInput = {
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  princessDating?: Maybe<Scalars["Boolean"]>;
  deviceType?: Maybe<DeviceTypeEnum>;
  deviceToken?: Maybe<Scalars["String"]>;
};

export type UserLogoutInput = {
  deviceType?: Maybe<DeviceTypeEnum>;
  deviceToken?: Maybe<Scalars["String"]>;
};

export type UserMakeFriendRequest = {
  userId: Scalars["ID"];
  wine?: Maybe<Scalars["Int"]>;
  status: UserFriendStatusEnum;
};

export type UserObject = {
  __typename?: "UserObject";
  userId: Scalars["ID"];
  datatype: DatatypeEnum;
  key: UserObjectKeyEnum;
  data: Scalars["String"];
  createDt?: Maybe<Scalars["String"]>;
  lastModifyDt?: Maybe<Scalars["String"]>;
};

export type UserObjectInput = {
  key: UserObjectKeyEnum;
  data: Scalars["String"];
};

export enum UserObjectKeyEnum {
  AgeVerify = "AGE_VERIFY",
  AgeVerifyRejectReason = "AGE_VERIFY_REJECT_REASON",
  VoiceIntroRejectReason = "VOICE_INTRO_REJECT_REASON",
  IosLogin = "IOS_LOGIN",
  IosLogout = "IOS_LOGOUT",
  AndroidLogin = "ANDROID_LOGIN",
  AndroidLogout = "ANDROID_LOGOUT",
}

export type UserObjectQueryInput = {
  userId?: Maybe<Scalars["ID"]>;
  key: UserObjectKeyEnum;
};

export type UserPage = {
  __typename?: "UserPage";
  users: Array<User>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export type UserPutAboutInput = {
  userId?: Maybe<Scalars["ID"]>;
  lang?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  dob?: Maybe<Scalars["String"]>;
  ageMin?: Maybe<Scalars["Int"]>;
  ageMax?: Maybe<Scalars["Int"]>;
  aboutEconomy?: Maybe<UserAboutEconomyEnum>;
  aboutHeight?: Maybe<UserAboutHeightEnum>;
  aboutBody?: Maybe<UserAboutBodyEnum>;
  aboutWaist?: Maybe<UserAboutWaistEnum>;
  aboutStyle?: Maybe<Scalars["Int"]>;
  aboutDrink?: Maybe<UserAboutDrinkEnum>;
  aboutSmoke?: Maybe<UserAboutSmokeEnum>;
  aboutMe?: Maybe<Scalars["String"]>;
  dateExpense?: Maybe<UserDateExpenseEnum>;
  dateOutline?: Maybe<Scalars["String"]>;
  whatsapp?: Maybe<Scalars["String"]>;
  wechat?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["String"]>;
  telegram?: Maybe<Scalars["String"]>;
  contactPrivacy?: Maybe<UserContactPrivacyEnum>;
};

export type UserPutProfileInput = {
  nickname: Scalars["String"];
  dob: Scalars["String"];
  city: Scalars["String"];
  whatsapp?: Maybe<Scalars["String"]>;
  wechat?: Maybe<Scalars["String"]>;
  telegram?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["String"]>;
};

export type UserPutVerifyInput = {
  userId?: Maybe<Scalars["ID"]>;
  voice?: Maybe<UserVerifyEnum>;
  ageVerify?: Maybe<UserVerifyEnum>;
  reason?: Maybe<Scalars["String"]>;
};

export type UserRateFriendRequest = {
  userId: Scalars["ID"];
  rate: Scalars["Int"];
  comment?: Maybe<Scalars["String"]>;
};

export enum UserRateLevelEnum {
  Normal = "NORMAL",
  Verified = "VERIFIED",
  Premium = "PREMIUM",
}

export type UserReferreeInput = {
  userId?: Maybe<Scalars["ID"]>;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type UserReferreePage = {
  __typename?: "UserReferreePage";
  user?: Maybe<Array<Maybe<User>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export type UserRegisterInput = {
  referrer?: Maybe<Scalars["ID"]>;
  gender: Scalars["String"];
  lang?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  princessDating?: Maybe<Scalars["Boolean"]>;
};

export type UserResetPasswordInput = {
  verifyCode: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
};

export enum UserRoleEnum {
  Normal = "NORMAL",
  Silver = "SILVER",
  HLvMember = "H_LV_MEMBER",
  Premium = "PREMIUM",
}

export type UserSearchInput = {
  userId?: Maybe<Scalars["ID"]>;
  nickname?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  district?: Maybe<Scalars["String"]>;
  ageMin?: Maybe<Scalars["Int"]>;
  ageMax?: Maybe<Scalars["Int"]>;
  ageVerify?: Maybe<Scalars["String"]>;
  voice?: Maybe<Scalars["String"]>;
  status?: Maybe<UserStatusEnum>;
  rateLevel?: Maybe<UserRateLevelEnum>;
  role?: Maybe<UserRoleEnum>;
  order?: Maybe<Scalars["String"]>;
  orderDirection?: Maybe<OrderDirectionEnum>;
  princessDating?: Maybe<Scalars["Boolean"]>;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type UserSetting = {
  __typename?: "UserSetting";
  id: Scalars["ID"];
  user?: Maybe<User>;
  datatype: DatatypeEnum;
  key: SettingKeyEnum;
  value?: Maybe<Scalars["String"]>;
};

export type UserSettingInput = {
  id?: Maybe<Scalars["ID"]>;
  datatype?: Maybe<DatatypeEnum>;
  key: SettingKeyEnum;
  value?: Maybe<Scalars["String"]>;
};

export enum UserStatusEnum {
  NotVerify = "NOT_VERIFY",
  Verified = "VERIFIED",
  ProfileCompleted = "PROFILE_COMPLETED",
  Deleted = "DELETED",
  Banned = "BANNED",
}

export enum UserVerifyEnum {
  Empty = "EMPTY",
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
  RemovedByUser = "REMOVED_BY_USER",
  RemovedByAdmin = "REMOVED_BY_ADMIN",
}

export type UserVerifyInput = {
  verifyCode: Scalars["String"];
};

export type UserView = {
  __typename?: "UserView";
  id?: Maybe<Scalars["ID"]>;
  user?: Maybe<User>;
  target?: Maybe<User>;
  createDt: Scalars["String"];
  lastModifyDt: Scalars["String"];
};

export type UserViewPage = {
  __typename?: "UserViewPage";
  views: Array<UserView>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export type UserViewQueryInput = {
  userId?: Maybe<Scalars["ID"]>;
  targetId?: Maybe<Scalars["ID"]>;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type VirtualPurchaseInput = {
  purchaseType: VirtualPurchaseTypeEnum;
  quantity: Scalars["Int"];
};

export enum VirtualPurchaseTypeEnum {
  Wine = "WINE",
  FrdQuota = "FRD_QUOTA",
}

export type WallPost = {
  __typename?: "WallPost";
  wallPostId?: Maybe<Scalars["ID"]>;
  user?: Maybe<User>;
  content?: Maybe<Scalars["String"]>;
  permission?: Maybe<PermissionEnum>;
  isAdult?: Maybe<IsAdultEnum>;
  status?: Maybe<FileStatusEnum>;
  createDt: Scalars["String"];
  lastModifyDt: Scalars["String"];
};

export type WallPostInput = {
  wallPostId?: Maybe<Scalars["ID"]>;
  content?: Maybe<Scalars["String"]>;
  permission?: Maybe<PermissionEnum>;
  isAdult?: Maybe<IsAdultEnum>;
  status: FileStatusEnum;
};

export type WallPostPage = {
  __typename?: "WallPostPage";
  wallPosts: Array<WallPost>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export type WallPostSearchInput = {
  userId?: Maybe<Scalars["ID"]>;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type WineReturn = {
  __typename?: "WineReturn";
  id?: Maybe<Scalars["ID"]>;
  userId?: Maybe<Scalars["ID"]>;
  user?: Maybe<User>;
  emptyWine?: Maybe<Scalars["Int"]>;
  type?: Maybe<CryptocurrencyTypeEnum>;
  address?: Maybe<Scalars["String"]>;
  status?: Maybe<WineReturnStatusEnum>;
  createDt: Scalars["String"];
  lastModifyDt: Scalars["String"];
};

export type WineReturnInput = {
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type WineReturnPage = {
  __typename?: "WineReturnPage";
  wineReturns: Array<WineReturn>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  count: Scalars["Int"];
};

export enum WineReturnStatusEnum {
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
  Pending = "PENDING",
  Deleted = "DELETED",
}

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type StitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, TParent, TContext, TArgs>;
}

export type SubscriptionResolver<
  TResult,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionResolverObject<TResult, TParent, TContext, TArgs>)
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Query: {};
  String: Scalars["String"];
  FriendQueryInput: FriendQueryInput;
  ID: Scalars["ID"];
  UserFriendStatusEnum: UserFriendStatusEnum;
  OrderDirectionEnum: OrderDirectionEnum;
  Int: Scalars["Int"];
  UserFriendPage: UserFriendPage;
  UserFriend: UserFriend;
  User: User;
  UserAboutEconomyEnum: UserAboutEconomyEnum;
  UserAboutHeightEnum: UserAboutHeightEnum;
  UserAboutBodyEnum: UserAboutBodyEnum;
  UserAboutWaistEnum: UserAboutWaistEnum;
  UserAboutDrinkEnum: UserAboutDrinkEnum;
  UserAboutSmokeEnum: UserAboutSmokeEnum;
  UserDateExpenseEnum: UserDateExpenseEnum;
  UserContactPrivacyEnum: UserContactPrivacyEnum;
  UserRoleEnum: UserRoleEnum;
  UserVerifyEnum: UserVerifyEnum;
  UserStatusEnum: UserStatusEnum;
  UserRateLevelEnum: UserRateLevelEnum;
  RestrictedLevelEnum: RestrictedLevelEnum;
  Boolean: Scalars["Boolean"];
  RateSummary: RateSummary;
  Float: Scalars["Float"];
  UserFavStatusEnum: UserFavStatusEnum;
  FavQueryInput: FavQueryInput;
  UserFavPage: UserFavPage;
  UserFav: UserFav;
  FileSearchInput: FileSearchInput;
  AppNameEnum: AppNameEnum;
  FilePage: FilePage;
  File: File;
  PermissionEnum: PermissionEnum;
  IsAdultEnum: IsAdultEnum;
  FileStatusEnum: FileStatusEnum;
  AlbumSearchInput: AlbumSearchInput;
  AlbumPage: AlbumPage;
  Album: Album;
  PageInput: PageInput;
  WallPostSearchInput: WallPostSearchInput;
  WallPostPage: WallPostPage;
  WallPost: WallPost;
  MsgQueryInput: MsgQueryInput;
  MessagePage: MessagePage;
  Message: Message;
  MessageStatusEnum: MessageStatusEnum;
  UserObjectQueryInput: UserObjectQueryInput;
  UserObjectKeyEnum: UserObjectKeyEnum;
  UserObject: UserObject;
  DatatypeEnum: DatatypeEnum;
  PaymentQueryInput: PaymentQueryInput;
  PaymentPlanEnum: PaymentPlanEnum;
  PaymentTypeEnum: PaymentTypeEnum;
  LogTypeEnum: LogTypeEnum;
  PaymentPage: PaymentPage;
  PaymentLog: PaymentLog;
  SubscriptionQueryInput: SubscriptionQueryInput;
  SubscriptionStatusEnum: SubscriptionStatusEnum;
  SubscriptionPage: SubscriptionPage;
  Subscription: {};
  WineReturnInput: WineReturnInput;
  WineReturnPage: WineReturnPage;
  WineReturn: WineReturn;
  CryptocurrencyTypeEnum: CryptocurrencyTypeEnum;
  WineReturnStatusEnum: WineReturnStatusEnum;
  UserSetting: UserSetting;
  SettingKeyEnum: SettingKeyEnum;
  ReportSearchInput: ReportSearchInput;
  TicketStatusEnum: TicketStatusEnum;
  ReportPage: ReportPage;
  Report: Report;
  TicketSearchInput: TicketSearchInput;
  TicketPage: TicketPage;
  Ticket: Ticket;
  TicketTypeEnum: TicketTypeEnum;
  SingleUserInput: SingleUserInput;
  UserSearchInput: UserSearchInput;
  UserPage: UserPage;
  UserViewQueryInput: UserViewQueryInput;
  UserViewPage: UserViewPage;
  UserView: UserView;
  CreditLogSearchInput: CreditLogSearchInput;
  CreditLogPage: CreditLogPage;
  CreditLog: CreditLog;
  CreditActionEnum: CreditActionEnum;
  UserReferreeInput: UserReferreeInput;
  UserReferreePage: UserReferreePage;
  Mutation: {};
  AdminLoginInput: AdminLoginInput;
  Admin: Admin;
  AdminUpgradeUserInput: AdminUpgradeUserInput;
  AdminUserInput: AdminUserInput;
  MsgSentInput: MsgSentInput;
  UserMakeFriendRequest: UserMakeFriendRequest;
  UserRateFriendRequest: UserRateFriendRequest;
  UserAddFavouriteRequest: UserAddFavouriteRequest;
  FileInput: FileInput;
  FileInputTypeEnum: FileInputTypeEnum;
  AlbumInput: AlbumInput;
  WallPostInput: WallPostInput;
  UserObjectInput: UserObjectInput;
  PaymentInput: PaymentInput;
  SubscriptionInput: SubscriptionInput;
  VirtualPurchaseInput: VirtualPurchaseInput;
  VirtualPurchaseTypeEnum: VirtualPurchaseTypeEnum;
  CryptocurrencyPurchaseInput: CryptocurrencyPurchaseInput;
  UserSettingInput: UserSettingInput;
  ReportInput: ReportInput;
  TicketInput: TicketInput;
  UserFbInput: UserFbInput;
  UserAppleInput: UserAppleInput;
  UserLoginInput: UserLoginInput;
  DeviceTypeEnum: DeviceTypeEnum;
  UserLogoutInput: UserLogoutInput;
  UserRegisterInput: UserRegisterInput;
  UserVerifyInput: UserVerifyInput;
  UserPutProfileInput: UserPutProfileInput;
  UserPutAboutInput: UserPutAboutInput;
  UserForgetPasswordInput: UserForgetPasswordInput;
  UserResetPasswordInput: UserResetPasswordInput;
  UserPutVerifyInput: UserPutVerifyInput;
  CacheControlScope: CacheControlScope;
  CreditCurrencyEnum: CreditCurrencyEnum;
  Upload: Scalars["Upload"];
};

export type AdminResolvers<
  ContextType = any,
  ParentType = ResolversTypes["Admin"]
> = {
  idToken?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  adminId?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
  username?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  createDt?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  lastModifyDt?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  lastIpv4?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
};

export type AlbumResolvers<
  ContextType = any,
  ParentType = ResolversTypes["Album"]
> = {
  albumId?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
  files?: Resolver<
    Maybe<ResolversTypes["FilePage"]>,
    ParentType,
    ContextType,
    AlbumFilesArgs
  >;
  name?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  permission?: Resolver<
    ResolversTypes["PermissionEnum"],
    ParentType,
    ContextType
  >;
  isAdult?: Resolver<ResolversTypes["IsAdultEnum"], ParentType, ContextType>;
  status?: Resolver<ResolversTypes["FileStatusEnum"], ParentType, ContextType>;
  createDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  lastModifyDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
};

export type AlbumPageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["AlbumPage"]
> = {
  albums?: Resolver<Array<ResolversTypes["Album"]>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type CreditLogResolvers<
  ContextType = any,
  ParentType = ResolversTypes["CreditLog"]
> = {
  id?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes["ID"]>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  amount?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  action?: Resolver<
    Maybe<ResolversTypes["CreditActionEnum"]>,
    ParentType,
    ContextType
  >;
  user?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  createDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  lastModifyDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
};

export type CreditLogPageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["CreditLogPage"]
> = {
  creditLogs?: Resolver<
    Array<ResolversTypes["CreditLog"]>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type FileResolvers<
  ContextType = any,
  ParentType = ResolversTypes["File"]
> = {
  fileId?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
  albumId?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
  filename?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  mime?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  permission?: Resolver<
    ResolversTypes["PermissionEnum"],
    ParentType,
    ContextType
  >;
  isAdult?: Resolver<ResolversTypes["IsAdultEnum"], ParentType, ContextType>;
  status?: Resolver<ResolversTypes["FileStatusEnum"], ParentType, ContextType>;
  createDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  lastModifyDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
};

export type FilePageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["FilePage"]
> = {
  files?: Resolver<Array<ResolversTypes["File"]>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type MessageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["Message"]
> = {
  id?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes["User"], ParentType, ContextType>;
  recipant?: Resolver<ResolversTypes["User"], ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes["MessageStatusEnum"],
    ParentType,
    ContextType
  >;
  createDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  lastModifyDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
};

export type MessagePageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["MessagePage"]
> = {
  messages?: Resolver<
    Array<ResolversTypes["Message"]>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType = ResolversTypes["Mutation"]
> = {
  _?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  adminLogin?: Resolver<
    ResolversTypes["Admin"],
    ParentType,
    ContextType,
    MutationAdminLoginArgs
  >;
  adminUpgradeUser?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType,
    MutationAdminUpgradeUserArgs
  >;
  adminPutUser?: Resolver<
    Maybe<ResolversTypes["User"]>,
    ParentType,
    ContextType,
    MutationAdminPutUserArgs
  >;
  adminPutMessage?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType,
    MutationAdminPutMessageArgs
  >;
  makeFriend?: Resolver<
    ResolversTypes["UserFriend"],
    ParentType,
    ContextType,
    MutationMakeFriendArgs
  >;
  rateFriend?: Resolver<
    ResolversTypes["UserFriend"],
    ParentType,
    ContextType,
    MutationRateFriendArgs
  >;
  addFavourite?: Resolver<
    Maybe<ResolversTypes["UserFav"]>,
    ParentType,
    ContextType,
    MutationAddFavouriteArgs
  >;
  putFile?: Resolver<
    ResolversTypes["File"],
    ParentType,
    ContextType,
    MutationPutFileArgs
  >;
  putAlbum?: Resolver<
    ResolversTypes["Album"],
    ParentType,
    ContextType,
    MutationPutAlbumArgs
  >;
  putWallPost?: Resolver<
    ResolversTypes["WallPost"],
    ParentType,
    ContextType,
    MutationPutWallPostArgs
  >;
  sendMessage?: Resolver<
    Maybe<ResolversTypes["Message"]>,
    ParentType,
    ContextType,
    MutationSendMessageArgs
  >;
  putUserObject?: Resolver<
    ResolversTypes["UserObject"],
    ParentType,
    ContextType,
    MutationPutUserObjectArgs
  >;
  putPayment?: Resolver<
    ResolversTypes["String"],
    ParentType,
    ContextType,
    MutationPutPaymentArgs
  >;
  putSubscription?: Resolver<
    ResolversTypes["Subscription"],
    ParentType,
    ContextType,
    MutationPutSubscriptionArgs
  >;
  putVirtualPurchase?: Resolver<
    ResolversTypes["String"],
    ParentType,
    ContextType,
    MutationPutVirtualPurchaseArgs
  >;
  putCryptocurrencyPurchase?: Resolver<
    ResolversTypes["String"],
    ParentType,
    ContextType,
    MutationPutCryptocurrencyPurchaseArgs
  >;
  putUserSettings?: Resolver<
    Array<ResolversTypes["UserSetting"]>,
    ParentType,
    ContextType,
    MutationPutUserSettingsArgs
  >;
  putReport?: Resolver<
    ResolversTypes["Report"],
    ParentType,
    ContextType,
    MutationPutReportArgs
  >;
  putTicket?: Resolver<
    ResolversTypes["Ticket"],
    ParentType,
    ContextType,
    MutationPutTicketArgs
  >;
  userFbLogin?: Resolver<
    ResolversTypes["User"],
    ParentType,
    ContextType,
    MutationUserFbLoginArgs
  >;
  userAppleLogin?: Resolver<
    ResolversTypes["User"],
    ParentType,
    ContextType,
    MutationUserAppleLoginArgs
  >;
  userLogin?: Resolver<
    ResolversTypes["User"],
    ParentType,
    ContextType,
    MutationUserLoginArgs
  >;
  userLogout?: Resolver<
    ResolversTypes["String"],
    ParentType,
    ContextType,
    MutationUserLogoutArgs
  >;
  userRegister?: Resolver<
    ResolversTypes["User"],
    ParentType,
    ContextType,
    MutationUserRegisterArgs
  >;
  userVerify?: Resolver<
    ResolversTypes["User"],
    ParentType,
    ContextType,
    MutationUserVerifyArgs
  >;
  userPutProfile?: Resolver<
    ResolversTypes["User"],
    ParentType,
    ContextType,
    MutationUserPutProfileArgs
  >;
  userPutAbout?: Resolver<
    ResolversTypes["User"],
    ParentType,
    ContextType,
    MutationUserPutAboutArgs
  >;
  userForgetPassword?: Resolver<
    ResolversTypes["User"],
    ParentType,
    ContextType,
    MutationUserForgetPasswordArgs
  >;
  userResetPassword?: Resolver<
    ResolversTypes["User"],
    ParentType,
    ContextType,
    MutationUserResetPasswordArgs
  >;
  userPutVerify?: Resolver<
    ResolversTypes["User"],
    ParentType,
    ContextType,
    MutationUserPutVerifyArgs
  >;
  userDelete?: Resolver<ResolversTypes["User"], ParentType, ContextType>;
  userLastSeen?: Resolver<ResolversTypes["User"], ParentType, ContextType>;
};

export type PaymentLogResolvers<
  ContextType = any,
  ParentType = ResolversTypes["PaymentLog"]
> = {
  id?: Resolver<Maybe<ResolversTypes["ID"]>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  paymentType?: Resolver<
    ResolversTypes["PaymentTypeEnum"],
    ParentType,
    ContextType
  >;
  currency?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  logType?: Resolver<ResolversTypes["LogTypeEnum"], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  createDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  lastModifyDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
};

export type PaymentPageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["PaymentPage"]
> = {
  payments?: Resolver<
    Array<ResolversTypes["PaymentLog"]>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType = ResolversTypes["Query"]
> = {
  _?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  friends?: Resolver<
    ResolversTypes["UserFriendPage"],
    ParentType,
    ContextType,
    QueryFriendsArgs
  >;
  favourites?: Resolver<
    ResolversTypes["UserFavPage"],
    ParentType,
    ContextType,
    QueryFavouritesArgs
  >;
  files?: Resolver<
    ResolversTypes["FilePage"],
    ParentType,
    ContextType,
    QueryFilesArgs
  >;
  albums?: Resolver<
    ResolversTypes["AlbumPage"],
    ParentType,
    ContextType,
    QueryAlbumsArgs
  >;
  wallPosts?: Resolver<
    ResolversTypes["WallPostPage"],
    ParentType,
    ContextType,
    QueryWallPostsArgs
  >;
  messages?: Resolver<
    ResolversTypes["MessagePage"],
    ParentType,
    ContextType,
    QueryMessagesArgs
  >;
  userObject?: Resolver<
    Maybe<ResolversTypes["UserObject"]>,
    ParentType,
    ContextType,
    QueryUserObjectArgs
  >;
  payments?: Resolver<
    ResolversTypes["PaymentPage"],
    ParentType,
    ContextType,
    QueryPaymentsArgs
  >;
  subscriptions?: Resolver<
    ResolversTypes["SubscriptionPage"],
    ParentType,
    ContextType,
    QuerySubscriptionsArgs
  >;
  wineReturns?: Resolver<
    ResolversTypes["WineReturnPage"],
    ParentType,
    ContextType,
    QueryWineReturnsArgs
  >;
  userSettings?: Resolver<
    Array<ResolversTypes["UserSetting"]>,
    ParentType,
    ContextType
  >;
  reports?: Resolver<
    ResolversTypes["ReportPage"],
    ParentType,
    ContextType,
    QueryReportsArgs
  >;
  tickets?: Resolver<
    ResolversTypes["TicketPage"],
    ParentType,
    ContextType,
    QueryTicketsArgs
  >;
  user?: Resolver<
    ResolversTypes["User"],
    ParentType,
    ContextType,
    QueryUserArgs
  >;
  users?: Resolver<
    Array<Maybe<ResolversTypes["User"]>>,
    ParentType,
    ContextType,
    QueryUsersArgs
  >;
  searchUsers?: Resolver<
    ResolversTypes["UserPage"],
    ParentType,
    ContextType,
    QuerySearchUsersArgs
  >;
  views?: Resolver<
    ResolversTypes["UserViewPage"],
    ParentType,
    ContextType,
    QueryViewsArgs
  >;
  creditLogs?: Resolver<
    ResolversTypes["CreditLogPage"],
    ParentType,
    ContextType,
    QueryCreditLogsArgs
  >;
  userShare?: Resolver<
    ResolversTypes["UserReferreePage"],
    ParentType,
    ContextType,
    QueryUserShareArgs
  >;
};

export type RateSummaryResolvers<
  ContextType = any,
  ParentType = ResolversTypes["RateSummary"]
> = {
  rate?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type ReportResolvers<
  ContextType = any,
  ParentType = ResolversTypes["Report"]
> = {
  id?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
  reporter?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  file?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes["TicketStatusEnum"],
    ParentType,
    ContextType
  >;
  createDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  lastModifyDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
};

export type ReportPageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["ReportPage"]
> = {
  reports?: Resolver<Array<ResolversTypes["Report"]>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type SubscriptionResolvers<
  ContextType = any,
  ParentType = ResolversTypes["Subscription"]
> = {
  subscriptionId?: SubscriptionResolver<
    ResolversTypes["ID"],
    ParentType,
    ContextType
  >;
  user?: SubscriptionResolver<
    Maybe<ResolversTypes["User"]>,
    ParentType,
    ContextType
  >;
  paymentType?: SubscriptionResolver<
    ResolversTypes["PaymentTypeEnum"],
    ParentType,
    ContextType
  >;
  plan?: SubscriptionResolver<
    ResolversTypes["PaymentPlanEnum"],
    ParentType,
    ContextType
  >;
  currency?: SubscriptionResolver<
    ResolversTypes["String"],
    ParentType,
    ContextType
  >;
  amount?: SubscriptionResolver<ResolversTypes["Int"], ParentType, ContextType>;
  status?: SubscriptionResolver<
    ResolversTypes["SubscriptionStatusEnum"],
    ParentType,
    ContextType
  >;
  nextChargeDt?: SubscriptionResolver<
    ResolversTypes["String"],
    ParentType,
    ContextType
  >;
  createDt?: SubscriptionResolver<
    ResolversTypes["String"],
    ParentType,
    ContextType
  >;
  lastModifyDt?: SubscriptionResolver<
    ResolversTypes["String"],
    ParentType,
    ContextType
  >;
};

export type SubscriptionPageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["SubscriptionPage"]
> = {
  subscriptions?: Resolver<
    Array<ResolversTypes["Subscription"]>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type TicketResolvers<
  ContextType = any,
  ParentType = ResolversTypes["Ticket"]
> = {
  id?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes["TicketTypeEnum"], ParentType, ContextType>;
  description?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes["TicketStatusEnum"],
    ParentType,
    ContextType
  >;
  createDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  lastModifyDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
};

export type TicketPageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["TicketPage"]
> = {
  tickets?: Resolver<Array<ResolversTypes["Ticket"]>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type UserResolvers<
  ContextType = any,
  ParentType = ResolversTypes["User"]
> = {
  idToken?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  referrer?: Resolver<Maybe<ResolversTypes["ID"]>, ParentType, ContextType>;
  referralCode?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  dob?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  logoHalf?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  logoFull?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  ageMin?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  ageMax?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  whatsapp?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  wechat?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  telegram?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  line?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  aboutEconomy?: Resolver<
    Maybe<ResolversTypes["UserAboutEconomyEnum"]>,
    ParentType,
    ContextType
  >;
  aboutHeight?: Resolver<
    Maybe<ResolversTypes["UserAboutHeightEnum"]>,
    ParentType,
    ContextType
  >;
  aboutBody?: Resolver<
    Maybe<ResolversTypes["UserAboutBodyEnum"]>,
    ParentType,
    ContextType
  >;
  aboutWaist?: Resolver<
    Maybe<ResolversTypes["UserAboutWaistEnum"]>,
    ParentType,
    ContextType
  >;
  aboutStyle?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  aboutDrink?: Resolver<
    Maybe<ResolversTypes["UserAboutDrinkEnum"]>,
    ParentType,
    ContextType
  >;
  aboutSmoke?: Resolver<
    Maybe<ResolversTypes["UserAboutSmokeEnum"]>,
    ParentType,
    ContextType
  >;
  aboutMe?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  dateExpense?: Resolver<
    Maybe<ResolversTypes["UserDateExpenseEnum"]>,
    ParentType,
    ContextType
  >;
  dateOutline?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  contactPrivacy?: Resolver<
    Maybe<ResolversTypes["UserContactPrivacyEnum"]>,
    ParentType,
    ContextType
  >;
  lang?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  showAdult?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  credit?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  creditSpent?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  frdQuota?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  wine?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  emptyWine?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  msgQuota?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  silverEndDt?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  premiumEndDt?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  role?: Resolver<ResolversTypes["UserRoleEnum"], ParentType, ContextType>;
  voice?: Resolver<ResolversTypes["UserVerifyEnum"], ParentType, ContextType>;
  ageVerify?: Resolver<
    ResolversTypes["UserVerifyEnum"],
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes["UserStatusEnum"], ParentType, ContextType>;
  lastSeen?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  rateLevel?: Resolver<
    Maybe<ResolversTypes["UserRateLevelEnum"]>,
    ParentType,
    ContextType
  >;
  restrictedLevel?: Resolver<
    Maybe<ResolversTypes["RestrictedLevelEnum"]>,
    ParentType,
    ContextType
  >;
  vip?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  millionbb?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  princessDating?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  lastIpv4?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  rateSummary?: Resolver<
    Maybe<ResolversTypes["RateSummary"]>,
    ParentType,
    ContextType
  >;
  favourite?: Resolver<
    Maybe<ResolversTypes["UserFavStatusEnum"]>,
    ParentType,
    ContextType
  >;
  friendStatus?: Resolver<
    Maybe<ResolversTypes["UserFriendStatusEnum"]>,
    ParentType,
    ContextType
  >;
  createDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  lastModifyDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
};

export type UserFavResolvers<
  ContextType = any,
  ParentType = ResolversTypes["UserFav"]
> = {
  id?: Resolver<Maybe<ResolversTypes["ID"]>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  favouriteUser?: Resolver<
    Maybe<ResolversTypes["User"]>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    Maybe<ResolversTypes["UserFavStatusEnum"]>,
    ParentType,
    ContextType
  >;
  createDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  lastModifyDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
};

export type UserFavPageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["UserFavPage"]
> = {
  favs?: Resolver<Array<ResolversTypes["UserFav"]>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type UserFriendResolvers<
  ContextType = any,
  ParentType = ResolversTypes["UserFriend"]
> = {
  id?: Resolver<Maybe<ResolversTypes["ID"]>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  friendUser?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  status?: Resolver<
    Maybe<ResolversTypes["UserFriendStatusEnum"]>,
    ParentType,
    ContextType
  >;
  createDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  lastModifyDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
};

export type UserFriendPageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["UserFriendPage"]
> = {
  friends?: Resolver<
    Array<ResolversTypes["UserFriend"]>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type UserObjectResolvers<
  ContextType = any,
  ParentType = ResolversTypes["UserObject"]
> = {
  userId?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
  datatype?: Resolver<ResolversTypes["DatatypeEnum"], ParentType, ContextType>;
  key?: Resolver<ResolversTypes["UserObjectKeyEnum"], ParentType, ContextType>;
  data?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  createDt?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  lastModifyDt?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
};

export type UserPageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["UserPage"]
> = {
  users?: Resolver<Array<ResolversTypes["User"]>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type UserReferreePageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["UserReferreePage"]
> = {
  user?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["User"]>>>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type UserSettingResolvers<
  ContextType = any,
  ParentType = ResolversTypes["UserSetting"]
> = {
  id?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  datatype?: Resolver<ResolversTypes["DatatypeEnum"], ParentType, ContextType>;
  key?: Resolver<ResolversTypes["SettingKeyEnum"], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
};

export type UserViewResolvers<
  ContextType = any,
  ParentType = ResolversTypes["UserView"]
> = {
  id?: Resolver<Maybe<ResolversTypes["ID"]>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  target?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  createDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  lastModifyDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
};

export type UserViewPageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["UserViewPage"]
> = {
  views?: Resolver<Array<ResolversTypes["UserView"]>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type WallPostResolvers<
  ContextType = any,
  ParentType = ResolversTypes["WallPost"]
> = {
  wallPostId?: Resolver<Maybe<ResolversTypes["ID"]>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  permission?: Resolver<
    Maybe<ResolversTypes["PermissionEnum"]>,
    ParentType,
    ContextType
  >;
  isAdult?: Resolver<
    Maybe<ResolversTypes["IsAdultEnum"]>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    Maybe<ResolversTypes["FileStatusEnum"]>,
    ParentType,
    ContextType
  >;
  createDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  lastModifyDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
};

export type WallPostPageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["WallPostPage"]
> = {
  wallPosts?: Resolver<
    Array<ResolversTypes["WallPost"]>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type WineReturnResolvers<
  ContextType = any,
  ParentType = ResolversTypes["WineReturn"]
> = {
  id?: Resolver<Maybe<ResolversTypes["ID"]>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes["ID"]>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  emptyWine?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  type?: Resolver<
    Maybe<ResolversTypes["CryptocurrencyTypeEnum"]>,
    ParentType,
    ContextType
  >;
  address?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  status?: Resolver<
    Maybe<ResolversTypes["WineReturnStatusEnum"]>,
    ParentType,
    ContextType
  >;
  createDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  lastModifyDt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
};

export type WineReturnPageResolvers<
  ContextType = any,
  ParentType = ResolversTypes["WineReturnPage"]
> = {
  wineReturns?: Resolver<
    Array<ResolversTypes["WineReturn"]>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  pageSize?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  count?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Admin?: AdminResolvers<ContextType>;
  Album?: AlbumResolvers<ContextType>;
  AlbumPage?: AlbumPageResolvers<ContextType>;
  CreditLog?: CreditLogResolvers<ContextType>;
  CreditLogPage?: CreditLogPageResolvers<ContextType>;
  File?: FileResolvers<ContextType>;
  FilePage?: FilePageResolvers<ContextType>;
  Message?: MessageResolvers<ContextType>;
  MessagePage?: MessagePageResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  PaymentLog?: PaymentLogResolvers<ContextType>;
  PaymentPage?: PaymentPageResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RateSummary?: RateSummaryResolvers<ContextType>;
  Report?: ReportResolvers<ContextType>;
  ReportPage?: ReportPageResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SubscriptionPage?: SubscriptionPageResolvers<ContextType>;
  Ticket?: TicketResolvers<ContextType>;
  TicketPage?: TicketPageResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserFav?: UserFavResolvers<ContextType>;
  UserFavPage?: UserFavPageResolvers<ContextType>;
  UserFriend?: UserFriendResolvers<ContextType>;
  UserFriendPage?: UserFriendPageResolvers<ContextType>;
  UserObject?: UserObjectResolvers<ContextType>;
  UserPage?: UserPageResolvers<ContextType>;
  UserReferreePage?: UserReferreePageResolvers<ContextType>;
  UserSetting?: UserSettingResolvers<ContextType>;
  UserView?: UserViewResolvers<ContextType>;
  UserViewPage?: UserViewPageResolvers<ContextType>;
  WallPost?: WallPostResolvers<ContextType>;
  WallPostPage?: WallPostPageResolvers<ContextType>;
  WineReturn?: WineReturnResolvers<ContextType>;
  WineReturnPage?: WineReturnPageResolvers<ContextType>;
};

/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
