import gql from 'graphql-tag';

export const putAdminTicket = gql`
  mutation adminLogin(
    $inputUserId: ID
    $type: TicketTypeEnum
    $status: TicketStatusEnum!
    $description: String
  ) {
    putTicket(
      input: { inputUserId: $inputUserId, type: $type, description: $description, status: $status }
    ) {
      id
      user {
        nickname
      }
      type
      description
      status
      createDt
    }
  }
`;
export const changeTicketStatus = gql`
  mutation adminLogin($inputTicketId: ID, $status: TicketStatusEnum!) {
    putTicket(input: { id: $inputTicketId, status: $status }) {
      id
      user {
        nickname
      }
      type
      description
      status
      createDt
    }
  }
`;
