import gql from 'graphql-tag';
export const ticket = gql`
  query tickets($page: Int!, $pageSize: Int!) {
    tickets(input: { status: ACTIVE, page: $page, pageSize: $pageSize }) {
      tickets {
        id
        user {
          userId
          nickname
        }
        type
        status
        createDt
        lastModifyDt
      }
      page
      pageSize
      count
    }
  }
`;
export const ticketByUserId = gql`
  query tickets($page: Int!, $userId: ID!, $pageSize: Int!) {
    tickets(input: { userId: $userId, page: $page, pageSize: $pageSize, status: ACTIVE }) {
      tickets {
        id
        user {
          userId
          email
          phone
          nickname
          logo
        }
        type
        description
        status
        createDt
        lastModifyDt
      }
      page
      pageSize
      count
    }
  }
`;
