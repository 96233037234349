import React, { FC } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { findCity, getUserFolder } from "helpers/millionbb";
import { User } from "millionbb-common";

interface ILogoField {
  user: User;
  logo: string;
  onDelete: () => void;
}

const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1) },
}));

export const LogoField: FC<ILogoField> = ({ user, logo, onDelete }) => {
  const classes = useStyles({});
  return (
    <>
      {logo ? (
        <img
          src={`${getUserFolder(user.userId)}${logo}`}
          alt="user-avatar"
          style={{ width: 200, height: 200, objectFit: "cover" }}
        />
      ) : (
        "No Logo"
      )}

      <Button
        variant="contained"
        disabled={logo ? false : true}
        className={classes.button}
        onClick={() => onDelete()}
      >
        刪除頭像
      </Button>
    </>
  );
};
