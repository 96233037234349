import React, { FC, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { mutations, queries } from "ql";
import { Link } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { TicketStatusEnum } from "millionbb-common";
import TicketInput from "./TicketInput";

const useStyles = makeStyles((theme) => ({
  block: {
    display: "block",
  },
  button: {
    margin: theme.spacing(1),
  },
  chip: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(0.5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  inline: {
    display: "inline",
  },
  paper: { padding: theme.spacing(3) },
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "70vh",
    width: 500,
  },
}));

interface IConversationProps {
  userId?: number;
  updateStatus: boolean;
  setUpdateStatus: (bool: boolean) => void;
}

export const TicketConversation: FC<IConversationProps> = (props) => {
  const { t } = useTranslation("ticket");
  const classes = useStyles({});
  const pageSize = 1000;
  const [putTicket] = useMutation(mutations.changeTicketStatus);
  const { loading, error, data, refetch } = useQuery(queries.ticketByUserId, {
    variables: { userId: props.userId ? props.userId : -1, page: 1, pageSize },
  });
  const changeTicketStatus = async (ticketId: number, status: any) => {
    await putTicket({ variables: { inputTicketId: ticketId, status } });
  };

  useEffect(() => {
    if (!loading && !props.updateStatus) {
      data.tickets.tickets
        .slice()
        .reverse()
        .map(async (ticket: any) => {
          if (ticket.status === TicketStatusEnum.Active) {
            await changeTicketStatus(ticket.id, TicketStatusEnum.Proceeded);
          }
        });
      props.setUpdateStatus(true);
    }
  });
  if (loading) {
    return <div>Loading</div>;
  }
  if (error) {
    return <div>Error</div>;
  }
  if (data.tickets.count === 0) {
    return <></>;
  }

  const userInfo = data.tickets.tickets[0].user;
  const userTickets = data.tickets.tickets.slice().reverse();

  return (
    <Paper className={classes.paper}>
      <List className={classes.root}>
        <ListItem alignItems="flex-start">
          <Link to={`/admin/users/${userInfo.userId}/details`} target="_blank">
            <ListItemText
              primary={`${t("user")}: ${userInfo.nickname} (ID: ${
                userInfo.userId
              })`}
              secondary={
                <>
                  <Typography
                    className={classes.block}
                    variant="body2"
                    color="textSecondary"
                  >
                    {`${t("email")}: ${
                      userInfo.email ? userInfo.email : "N/A"
                    }`}
                  </Typography>
                  <Typography
                    className={classes.block}
                    variant="body2"
                    color="textSecondary"
                  >
                    {`${t("phone")}: ${
                      userInfo.phone ? userInfo.phone : "N/A"
                    }`}
                  </Typography>
                </>
              }
            />
          </Link>
        </ListItem>
        <Divider light={true} variant="inset" component="li" />
        <div style={{ overflowY: "scroll", height: "70vh" }}>
          {userTickets.reverse().map((ticket: any) => {
            const ticketInfo = ticket;
            return (
              <div key={"ticket" + ticketInfo.id}>
                <Chip
                  size="small"
                  label={new Date(
                    parseInt(ticketInfo.createDt, 10)
                  ).toLocaleString()}
                  className={classes.chip}
                />
                <FormControl className={classes.formControl}>
                  <Select
                    native={true}
                    variant="outlined"
                    value={ticketInfo.status}
                    input={
                      <OutlinedInput labelWidth={0} id="outlined-age-simple" />
                    }
                    onChange={(e) =>
                      changeTicketStatus(ticketInfo.id, e.target.value)
                    }
                  >
                    <option
                      style={{ backgroundColor: "green" }}
                      value={TicketStatusEnum.Active}
                    >
                      ACTIVE
                    </option>
                    <option value={TicketStatusEnum.Proceeded}>
                      PROCEEDED
                    </option>
                    <option value={TicketStatusEnum.Deleted}>DELETED</option>
                  </Select>
                </FormControl>

                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      ticketInfo.type === "USER"
                        ? `${userInfo.nickname}: (${t("ticket")}: ${
                            ticketInfo.id
                          })`
                        : `Admin:  (Ticket ID: ${ticketInfo.id})`
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          className={classes.block}
                          variant="body2"
                          color="textPrimary"
                        >
                          {ticketInfo.description}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </div>
            );
          })}
        </div>
        <TicketInput userId={userInfo.userId} refetchConversation={refetch} />
      </List>
    </Paper>
  );
};

export default TicketConversation;
