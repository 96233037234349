import { Typography, Grid } from '@material-ui/core';
import React, { FC, useEffect, useMemo } from 'react';
import { usePayments } from 'ql/hooks';
import { UserChart, PaymentChart, DuplicatedPaymentRecord } from './Dashboard.components';

export const Dashboard: FC<{}> = () => {
  const { data, loading, error, fetchMore, variables } = usePayments({
    variables: { paymentQueryInput: { page: 1, pageSize: 100 } },
  });

  useEffect(() => {
    if (data && data.payments && data.payments.payments && data.payments.payments.length > 0) {
      // console.info('last payment', data.payments.payments[data.payments.payments.length - 1]);
      if (
        data.payments.payments[data.payments.payments.length - 1].createDt >
          (Date.now() - 30 * 86400000).toString() &&
        data.payments.payments.length < data.payments.count
      ) {
        // console.info('to perform fetchMore');
        const { pageSize } = variables.paymentQueryInput;

        fetchMore({
          variables: { paymentQueryInput: { page: data.payments.page + 1, pageSize } },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            // console.info(previousResult, fetchMoreResult);
            const { payments: prevPayments } = previousResult.payments;
            const { payments } = fetchMoreResult.payments;

            // console.info('new payments', payments);
            const r = {
              payments: { ...fetchMoreResult.payments, payments: [...prevPayments, ...payments] },
            };
            // console.info(`update query`, r);
            return r;
          },
        })
          .then()
          .catch();
      }
    }
  }, [data, fetchMore, variables]);

  const payments = useMemo(() => {
    if (((data || { payments: null }).payments || { payments: null }).payments) {
      return data.payments.payments;
    }
  }, [data]);
  // console.info(variables);

  return (
    <>
      <Typography variant="h2" gutterBottom={true}>
        Dashboard Page
      </Typography>
      {/* <Typography>Impl in the later stages</Typography> */}
      <Grid container>
        <UserChart />
        {payments && <PaymentChart payments={payments} />}
      </Grid>
      {payments && <DuplicatedPaymentRecord payments={payments} />}
    </>
  );
};

export default Dashboard;
