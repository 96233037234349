import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Admin = {
  __typename?: 'Admin';
  idToken?: Maybe<Scalars['String']>;
  adminId: Scalars['ID'];
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  createDt?: Maybe<Scalars['String']>;
  lastModifyDt?: Maybe<Scalars['String']>;
  lastIpv4?: Maybe<Scalars['String']>;
};

export type AdminLoginInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type AdminUpgradeUserInput = {
  userId: Scalars['ID'];
  type?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  frdQuota?: Maybe<Scalars['Int']>;
  credit?: Maybe<Scalars['Int']>;
  creditSpent?: Maybe<Scalars['Int']>;
  msgQuota?: Maybe<Scalars['Int']>;
  wine?: Maybe<Scalars['Int']>;
  emptyWine?: Maybe<Scalars['Int']>;
};

export type AdminUserInput = {
  userId: Scalars['ID'];
  gender?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  aboutMe?: Maybe<Scalars['String']>;
  dateOutline?: Maybe<Scalars['String']>;
  frdQuota?: Maybe<Scalars['Int']>;
  telegram?: Maybe<Scalars['String']>;
  wechat?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatusEnum>;
  restrictedLevel?: Maybe<RestrictedLevelEnum>;
  reason?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  logoHalf?: Maybe<Scalars['String']>;
  logoFull?: Maybe<Scalars['String']>;
  millionbb?: Maybe<Scalars['Boolean']>;
  princessDating?: Maybe<Scalars['Boolean']>;
};

export type Album = {
  __typename?: 'Album';
  albumId: Scalars['ID'];
  user?: Maybe<User>;
  userId: Scalars['ID'];
  files?: Maybe<FilePage>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  permission: PermissionEnum;
  isAdult: IsAdultEnum;
  status: FileStatusEnum;
  createDt: Scalars['String'];
  lastModifyDt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};


export type AlbumFilesArgs = {
  input: PageInput;
};

export type AlbumInput = {
  albumId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  permission?: Maybe<PermissionEnum>;
  isAdult?: Maybe<IsAdultEnum>;
  status: FileStatusEnum;
};

export type AlbumPage = {
  __typename?: 'AlbumPage';
  albums: Array<Album>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export type AlbumSearchInput = {
  userId?: Maybe<Scalars['ID']>;
  appName?: Maybe<AppNameEnum>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export enum AppNameEnum {
  Millionbb = 'MILLIONBB',
  PricessDating = 'PRICESS_DATING'
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export enum CreditActionEnum {
  MakeFriendDirectly = 'MAKE_FRIEND_DIRECTLY',
  FriendRequestSent = 'FRIEND_REQUEST_SENT',
  FriendRequestAccept = 'FRIEND_REQUEST_ACCEPT',
  FriendRequestReject = 'FRIEND_REQUEST_REJECT',
  FriendRequestCancel = 'FRIEND_REQUEST_CANCEL',
  PrivateMsg = 'PRIVATE_MSG',
  PurchasePremium = 'PURCHASE_PREMIUM',
  PurchaseCredit = 'PURCHASE_CREDIT',
  AdminUpgrade = 'ADMIN_UPGRADE',
  AdminEdit = 'ADMIN_EDIT',
  SystemDistribute = 'SYSTEM_DISTRIBUTE',
  RefundPremium = 'REFUND_PREMIUM',
  RefundCredit = 'REFUND_CREDIT',
  PurchaseFrdQuota = 'PURCHASE_FRD_QUOTA',
  PurchaseWine = 'PURCHASE_WINE',
  ReturnWine = 'RETURN_WINE',
  BonusGrasVerify = 'BONUS_GRAS_VERIFY',
  BonusGrasRate = 'BONUS_GRAS_RATE'
}

export enum CreditCurrencyEnum {
  Cred = 'CRED',
  Ucre = 'UCRE',
  Frdq = 'FRDQ',
  Msgq = 'MSGQ',
  Wine = 'WINE',
  Gras = 'GRAS'
}

export type CreditLog = {
  __typename?: 'CreditLog';
  id: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  currency?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  action?: Maybe<CreditActionEnum>;
  user?: Maybe<User>;
  createDt: Scalars['String'];
  lastModifyDt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type CreditLogPage = {
  __typename?: 'CreditLogPage';
  creditLogs: Array<CreditLog>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export type CreditLogSearchInput = {
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type CryptocurrencyPurchaseInput = {
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  status: WineReturnStatusEnum;
  cryptocurrencyType: CryptocurrencyTypeEnum;
  quantity: Scalars['Int'];
  accountNumber: Scalars['String'];
};

export enum CryptocurrencyTypeEnum {
  Eth = 'ETH',
  Bitcoin = 'BITCOIN',
  Usdt = 'USDT'
}

export enum DatatypeEnum {
  Base64 = 'BASE64',
  Text = 'TEXT'
}


export enum DeviceTypeEnum {
  Ios = 'IOS',
  Android = 'ANDROID',
  Firebase = 'FIREBASE'
}

export type FavQueryInput = {
  userId?: Maybe<Scalars['ID']>;
  favouriteUserId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type File = {
  __typename?: 'File';
  fileId: Scalars['ID'];
  user?: Maybe<User>;
  userId: Scalars['ID'];
  albumId: Scalars['ID'];
  filename: Scalars['String'];
  mime: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  permission: PermissionEnum;
  isAdult: IsAdultEnum;
  status: FileStatusEnum;
  createDt: Scalars['String'];
  lastModifyDt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type FileInput = {
  fileId?: Maybe<Scalars['ID']>;
  /** 0: Profile Picture; 1: Normal File; */
  userId?: Maybe<Scalars['ID']>;
  type?: Maybe<FileInputTypeEnum>;
  albumId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  mime?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  permission?: Maybe<PermissionEnum>;
  isAdult?: Maybe<IsAdultEnum>;
  status?: Maybe<FileStatusEnum>;
};

export enum FileInputTypeEnum {
  ProfilePic = 'PROFILE_PIC',
  ProfilePicHalf = 'PROFILE_PIC_HALF',
  ProfilePicFull = 'PROFILE_PIC_FULL',
  AlbumImg = 'ALBUM_IMG',
  VoiceIntro = 'VOICE_INTRO'
}

export type FilePage = {
  __typename?: 'FilePage';
  files: Array<File>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export type FileSearchInput = {
  albumId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  username?: Maybe<Scalars['String']>;
  appName?: Maybe<AppNameEnum>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export enum FileStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Banned = 'BANNED'
}

export type FriendQueryInput = {
  userId?: Maybe<Scalars['ID']>;
  friendUserId?: Maybe<Scalars['ID']>;
  status?: Maybe<UserFriendStatusEnum>;
  nickname?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirectionEnum>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export enum GenderEnum {
  M = 'M',
  F = 'F'
}

export enum IsAdultEnum {
  Family = 'FAMILY',
  Adult = 'ADULT'
}

export enum LogTypeEnum {
  Undefined = 'UNDEFINED',
  PayRequest = 'PAY_REQUEST',
  PayResponse = 'PAY_RESPONSE',
  SubscribeRequest = 'SUBSCRIBE_REQUEST',
  SubscribeInitial = 'SUBSCRIBE_INITIAL',
  SubscribeRebill = 'SUBSCRIBE_REBILL',
  SubscribeCancel = 'SUBSCRIBE_CANCEL',
  SubscribeExtend = 'SUBSCRIBE_EXTEND',
  SubscribeExpiry = 'SUBSCRIBE_EXPIRY',
  ChargeBack = 'CHARGE_BACK'
}

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  sender: User;
  recipant: User;
  filename?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: MessageStatusEnum;
  createDt: Scalars['String'];
  lastModifyDt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type MessagePage = {
  __typename?: 'MessagePage';
  messages: Array<Message>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export enum MessageStatusEnum {
  Sent = 'SENT',
  Read = 'READ',
  Deleted = 'DELETED',
  Request = 'REQUEST',
  Accept = 'ACCEPT',
  Reject = 'REJECT',
  Cancelled = 'CANCELLED'
}

export type MsgQueryInput = {
  userId?: Maybe<Scalars['ID']>;
  /** query message after ts */
  unread?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type MsgSentInput = {
  recipant: Scalars['ID'];
  file?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['String']>;
  adminLogin: Admin;
  adminUpgradeUser?: Maybe<Scalars['Int']>;
  adminPutUser?: Maybe<User>;
  adminPutMessage?: Maybe<Scalars['String']>;
  makeFriend: UserFriend;
  cancelFriend: UserFriend;
  rateFriend: UserFriend;
  addFavourite?: Maybe<UserFav>;
  putFile: File;
  putAlbum: Album;
  putWallPost: WallPost;
  sendMessage?: Maybe<Message>;
  putUserObject: UserObject;
  putPayment: Scalars['String'];
  putVirtualPurchase: Scalars['String'];
  putCryptocurrencyPurchase: Scalars['String'];
  putUserSettings: Array<UserSetting>;
  putTicket: Ticket;
  putReport?: Maybe<Report>;
  userFbLogin: User;
  userAppleLogin: User;
  userLogin: User;
  userPhoneLogin: Scalars['Boolean'];
  userLogout: Scalars['String'];
  userRegister: User;
  userVerify: User;
  userPutProfile: User;
  userPutAbout: User;
  userForgetPassword: User;
  userResetPassword: User;
  /** api for age verify and voice intro verify */
  userPutVerify: User;
  userDelete: User;
  userLastSeen: User;
  createSubscription: Subscription;
  cancelSubscription: Subscription;
  /** @deprecrated */
  putSubscription: Subscription;
};


export type MutationAdminLoginArgs = {
  input: AdminLoginInput;
};


export type MutationAdminUpgradeUserArgs = {
  input: AdminUpgradeUserInput;
};


export type MutationAdminPutUserArgs = {
  input: AdminUserInput;
};


export type MutationAdminPutMessageArgs = {
  input: MsgSentInput;
};


export type MutationMakeFriendArgs = {
  input: UserMakeFriendRequest;
};


export type MutationCancelFriendArgs = {
  input: UserCancelFriendRequest;
};


export type MutationRateFriendArgs = {
  input: UserRateFriendRequest;
};


export type MutationAddFavouriteArgs = {
  input: UserAddFavouriteRequest;
};


export type MutationPutFileArgs = {
  input: FileInput;
};


export type MutationPutAlbumArgs = {
  input: AlbumInput;
};


export type MutationPutWallPostArgs = {
  input: WallPostInput;
};


export type MutationSendMessageArgs = {
  input: MsgSentInput;
};


export type MutationPutUserObjectArgs = {
  input: UserObjectInput;
};


export type MutationPutPaymentArgs = {
  input: PaymentInput;
};


export type MutationPutVirtualPurchaseArgs = {
  input: VirtualPurchaseInput;
};


export type MutationPutCryptocurrencyPurchaseArgs = {
  input: CryptocurrencyPurchaseInput;
};


export type MutationPutUserSettingsArgs = {
  inputs: Array<UserSettingInput>;
};


export type MutationPutTicketArgs = {
  input: TicketInput;
};


export type MutationPutReportArgs = {
  input: ReportInput;
};


export type MutationUserFbLoginArgs = {
  input: UserFbInput;
};


export type MutationUserAppleLoginArgs = {
  input: UserAppleInput;
};


export type MutationUserLoginArgs = {
  input: UserLoginInput;
};


export type MutationUserPhoneLoginArgs = {
  input: UserPhoneLoginInput;
};


export type MutationUserLogoutArgs = {
  input: UserLogoutInput;
};


export type MutationUserRegisterArgs = {
  input: UserRegisterInput;
};


export type MutationUserVerifyArgs = {
  input: UserVerifyInput;
};


export type MutationUserPutProfileArgs = {
  input: UserPutProfileInput;
};


export type MutationUserPutAboutArgs = {
  input: UserPutAboutInput;
};


export type MutationUserForgetPasswordArgs = {
  input: UserForgetPasswordInput;
};


export type MutationUserResetPasswordArgs = {
  input: UserResetPasswordInput;
};


export type MutationUserPutVerifyArgs = {
  input: UserPutVerifyInput;
};


export type MutationCreateSubscriptionArgs = {
  input: SubscriptionCreateInput;
};


export type MutationCancelSubscriptionArgs = {
  id: Scalars['ID'];
};


export type MutationPutSubscriptionArgs = {
  input: SubscriptionInput;
};

export enum OrderDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PageInput = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PaymentInput = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  paymentType: PaymentTypeEnum;
  plan: PaymentPlanEnum;
  currency: Scalars['String'];
  amount: Scalars['Int'];
  logType: LogTypeEnum;
  token?: Maybe<Scalars['String']>;
};

export type PaymentLog = {
  __typename?: 'PaymentLog';
  id?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  paymentType: PaymentTypeEnum;
  currency: Scalars['String'];
  amount: Scalars['Int'];
  logType: LogTypeEnum;
  data?: Maybe<Scalars['String']>;
  createDt: Scalars['String'];
  lastModifyDt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type PaymentPage = {
  __typename?: 'PaymentPage';
  payments: Array<PaymentLog>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export enum PaymentPlanEnum {
  HqMember = 'HQ_MEMBER',
  HLvMember = 'H_LV_MEMBER',
  Premium = 'PREMIUM',
  HLvMember_2021 = 'H_LV_MEMBER_2021',
  Premium_2021 = 'PREMIUM_2021',
  HLvMember_90D = 'H_LV_MEMBER_90D',
  Premium_90D = 'PREMIUM_90D',
  Credit_10 = 'CREDIT_10',
  Credit_50 = 'CREDIT_50',
  Credit_100 = 'CREDIT_100'
}

export type PaymentQueryInput = {
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  nickname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  plan?: Maybe<PaymentPlanEnum>;
  paymentType?: Maybe<PaymentTypeEnum>;
  logType?: Maybe<LogTypeEnum>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export enum PaymentTypeEnum {
  Verotel = 'VEROTEL',
  Stripe = 'STRIPE',
  StripeNew = 'STRIPE_NEW'
}

export enum PermissionEnum {
  AllowAll = 'ALLOW_ALL',
  OnlyFriend = 'ONLY_FRIEND'
}

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['String']>;
  friends: UserFriendPage;
  favourites: UserFavPage;
  files: FilePage;
  albums: AlbumPage;
  wallPosts: WallPostPage;
  messages: MessagePage;
  userObject?: Maybe<UserObject>;
  payments: PaymentPage;
  wineReturns: WineReturnPage;
  userSettings: Array<UserSetting>;
  tickets: TicketPage;
  reports: ReportPage;
  user: User;
  users: Array<Maybe<User>>;
  searchUsers: UserPage;
  views: UserViewPage;
  creditLogs: CreditLogPage;
  userShare: UserReferreePage;
  subscriptions: SubscriptionPage;
  activeSubscription?: Maybe<Subscription>;
};


export type QueryFriendsArgs = {
  input: FriendQueryInput;
};


export type QueryFavouritesArgs = {
  input: FavQueryInput;
};


export type QueryFilesArgs = {
  input: FileSearchInput;
};


export type QueryAlbumsArgs = {
  input: AlbumSearchInput;
};


export type QueryWallPostsArgs = {
  input: WallPostSearchInput;
};


export type QueryMessagesArgs = {
  input: MsgQueryInput;
};


export type QueryUserObjectArgs = {
  input: UserObjectQueryInput;
};


export type QueryPaymentsArgs = {
  input: PaymentQueryInput;
};


export type QueryWineReturnsArgs = {
  input?: Maybe<WineReturnInput>;
};


export type QueryTicketsArgs = {
  input?: Maybe<TicketSearchInput>;
};


export type QueryReportsArgs = {
  input?: Maybe<ReportSearchInput>;
};


export type QueryUserArgs = {
  input?: Maybe<SingleUserInput>;
};


export type QueryUsersArgs = {
  input: UserSearchInput;
};


export type QuerySearchUsersArgs = {
  input: UserSearchInput;
};


export type QueryViewsArgs = {
  input?: Maybe<UserViewQueryInput>;
};


export type QueryCreditLogsArgs = {
  input: CreditLogSearchInput;
};


export type QueryUserShareArgs = {
  input?: Maybe<UserReferreeInput>;
};


export type QuerySubscriptionsArgs = {
  input: SubscriptionQueryInput;
};

export type RateSummary = {
  __typename?: 'RateSummary';
  rate: Scalars['Float'];
  count: Scalars['Int'];
};

export type Report = {
  __typename?: 'Report';
  id: Scalars['ID'];
  reporter?: Maybe<User>;
  user?: Maybe<User>;
  file?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  status: ReportStatusEnum;
  createDt: Scalars['String'];
  lastModifyDt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type ReportInput = {
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  file?: Maybe<Scalars['Upload']>;
  description?: Maybe<Scalars['String']>;
  status: ReportStatusEnum;
};

export type ReportPage = {
  __typename?: 'ReportPage';
  reports: Array<Report>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export type ReportSearchInput = {
  status: ReportStatusEnum;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export enum ReportStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Proceeded = 'PROCEEDED'
}

export enum RestrictedLevelEnum {
  Normal = 'NORMAL',
  MillionbbOnly = 'MILLIONBB_ONLY',
  MillionbbOnlyPending = 'MILLIONBB_ONLY_PENDING',
  Banned = 'BANNED',
  BannedPending = 'BANNED_PENDING'
}

export enum SettingKeyEnum {
  DisableNotifyUserView = 'DISABLE_NOTIFY_USER_VIEW'
}

export type SingleUserInput = {
  userId?: Maybe<Scalars['ID']>;
  referralCode?: Maybe<Scalars['String']>;
  princessDating?: Maybe<Scalars['Boolean']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  id: Scalars['ID'];
  subscriptionId: Scalars['ID'];
  user?: Maybe<User>;
  paymentType: PaymentTypeEnum;
  plan: SubscriptionPlanEnum;
  currency: Scalars['String'];
  amount: Scalars['Int'];
  status: SubscriptionStatusEnum;
  /** @deprecated Use nextChargeAt */
  nextChargeDt: Scalars['String'];
  nextChargeAt: Scalars['DateTime'];
  /** @deprecated Use createdAt */
  createDt: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** @deprecated Use updatedAt */
  lastModifyDt: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SubscriptionCreateInput = {
  email?: Maybe<Scalars['String']>;
  paymentType: PaymentTypeEnum;
  plan: SubscriptionPlanEnum;
  token?: Maybe<Scalars['String']>;
};

/** @deprecated */
export type SubscriptionInput = {
  id: Scalars['ID'];
  status: SubscriptionStatusEnum;
};

export type SubscriptionPage = {
  __typename?: 'SubscriptionPage';
  subscriptions: Array<Subscription>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export enum SubscriptionPlanEnum {
  HLvAug2021 = 'H_LV_AUG2021',
  PremiumAug2021 = 'PREMIUM_AUG2021',
  HLvMember_60D = 'H_LV_MEMBER_60D',
  Premium_60D = 'PREMIUM_60D',
  HLvMember_90D = 'H_LV_MEMBER_90D',
  Premium_90D = 'PREMIUM_90D',
  HLvMember_2021 = 'H_LV_MEMBER_2021',
  Premium_2021 = 'PREMIUM_2021'
}

export type SubscriptionQueryInput = {
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  nickname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  plan?: Maybe<PaymentPlanEnum>;
  status?: Maybe<SubscriptionStatusEnum>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export enum SubscriptionStatusEnum {
  Active = 'ACTIVE',
  InactiveByUser = 'INACTIVE_BY_USER',
  InactiveBySystem = 'INACTIVE_BY_SYSTEM',
  InactiveByAdmin = 'INACTIVE_BY_ADMIN'
}

export type Ticket = {
  __typename?: 'Ticket';
  id: Scalars['ID'];
  user?: Maybe<User>;
  type: TicketTypeEnum;
  description: Scalars['String'];
  status: TicketStatusEnum;
  createDt: Scalars['String'];
  lastModifyDt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type TicketInput = {
  id?: Maybe<Scalars['ID']>;
  inputUserId?: Maybe<Scalars['ID']>;
  type?: Maybe<TicketTypeEnum>;
  description?: Maybe<Scalars['String']>;
  status: TicketStatusEnum;
};

export type TicketPage = {
  __typename?: 'TicketPage';
  tickets: Array<Ticket>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export type TicketSearchInput = {
  userId?: Maybe<Scalars['ID']>;
  status: TicketStatusEnum;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export enum TicketStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Proceeded = 'PROCEEDED'
}

export enum TicketTypeEnum {
  User = 'USER',
  Admin = 'ADMIN'
}


export type User = {
  __typename?: 'User';
  idToken?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
  /** M / F */
  gender: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['ID']>;
  referralCode?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  logoHalf?: Maybe<Scalars['String']>;
  logoFull?: Maybe<Scalars['String']>;
  ageMin?: Maybe<Scalars['Int']>;
  ageMax?: Maybe<Scalars['Int']>;
  whatsapp?: Maybe<Scalars['String']>;
  wechat?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  aboutEconomy?: Maybe<UserAboutEconomyEnum>;
  aboutHeight?: Maybe<UserAboutHeightEnum>;
  aboutBody?: Maybe<UserAboutBodyEnum>;
  aboutWaist?: Maybe<UserAboutWaistEnum>;
  aboutStyle?: Maybe<Scalars['Int']>;
  aboutDrink?: Maybe<UserAboutDrinkEnum>;
  aboutSmoke?: Maybe<UserAboutSmokeEnum>;
  aboutMe?: Maybe<Scalars['String']>;
  dateExpense?: Maybe<UserDateExpenseEnum>;
  dateOutline?: Maybe<Scalars['String']>;
  contactPrivacy?: Maybe<UserContactPrivacyEnum>;
  lang?: Maybe<Scalars['String']>;
  showAdult?: Maybe<Scalars['Int']>;
  credit?: Maybe<Scalars['Int']>;
  creditSpent?: Maybe<Scalars['Int']>;
  frdQuota?: Maybe<Scalars['Int']>;
  wine?: Maybe<Scalars['Int']>;
  emptyWine?: Maybe<Scalars['Int']>;
  msgQuota?: Maybe<Scalars['Int']>;
  silverEndDt?: Maybe<Scalars['String']>;
  premiumEndDt?: Maybe<Scalars['String']>;
  /** 1: normal |  2: silver |  3: premium */
  role: UserRoleEnum;
  voice: UserVerifyEnum;
  ageVerify: UserVerifyEnum;
  /** 0: created | 1: verified | 2: detail filled */
  status: UserStatusEnum;
  lastSeen?: Maybe<Scalars['String']>;
  rateLevel?: Maybe<UserRateLevelEnum>;
  restrictedLevel?: Maybe<RestrictedLevelEnum>;
  vip?: Maybe<Scalars['Int']>;
  millionbb?: Maybe<Scalars['Boolean']>;
  princessDating?: Maybe<Scalars['Boolean']>;
  /** below are admin only */
  lastIpv4?: Maybe<Scalars['String']>;
  rateSummary?: Maybe<RateSummary>;
  favourite?: Maybe<UserFavStatusEnum>;
  friendStatus?: Maybe<UserFriendStatusEnum>;
  createDt: Scalars['String'];
  lastModifyDt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum UserAboutBodyEnum {
  Athletic = 'ATHLETIC',
  Normal = 'NORMAL',
  Other = 'OTHER',
  Stout = 'STOUT',
  Superfluous = 'SUPERFLUOUS',
  Thin = 'THIN'
}

export enum UserAboutDrinkEnum {
  Never = 'NEVER',
  Sometime = 'SOMETIME',
  Usually = 'USUALLY'
}

export enum UserAboutEconomyEnum {
  Urgent = 'URGENT',
  NotEnough = 'NOT_ENOUGH',
  SometimeNo = 'SOMETIME_NO',
  Sufficient = 'SUFFICIENT',
  SomeSpare = 'SOME_SPARE',
  LotSpare = 'LOT_SPARE',
  TooMuch = 'TOO_MUCH'
}

export enum UserAboutHeightEnum {
  Cm150 = 'CM150',
  Cm151 = 'CM151',
  Cm156 = 'CM156',
  Cm161 = 'CM161',
  Cm166 = 'CM166',
  Cm171 = 'CM171',
  Cm181 = 'CM181',
  Cm191 = 'CM191',
  Cm200 = 'CM200'
}

export enum UserAboutSmokeEnum {
  Never = 'NEVER',
  Sometime = 'SOMETIME',
  Always = 'ALWAYS'
}

export enum UserAboutWaistEnum {
  In19 = 'IN19',
  In20 = 'IN20',
  In23 = 'IN23',
  In26 = 'IN26',
  In29 = 'IN29',
  In32 = 'IN32',
  In36 = 'IN36',
  In41 = 'IN41',
  In51 = 'IN51',
  In60 = 'IN60'
}

export type UserAddFavouriteRequest = {
  userId: Scalars['ID'];
  status?: Maybe<UserFavStatusEnum>;
};

export type UserAppleInput = {
  gender: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authorizationCode: Scalars['String'];
  user: Scalars['String'];
};

export type UserCancelFriendRequest = {
  userId: Scalars['ID'];
};

export enum UserContactPrivacyEnum {
  AllPaid = 'ALL_PAID',
  OnlyPremium = 'ONLY_PREMIUM',
  NoOne = 'NO_ONE'
}

export enum UserDateExpenseEnum {
  AssumeAll = 'ASSUME_ALL',
  Split = 'SPLIT',
  ExpectOther = 'EXPECT_OTHER',
  DoesntMatter = 'DOESNT_MATTER'
}

export type UserFav = {
  __typename?: 'UserFav';
  id?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  favouriteUser?: Maybe<User>;
  status?: Maybe<UserFavStatusEnum>;
  createDt: Scalars['String'];
  lastModifyDt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type UserFavPage = {
  __typename?: 'UserFavPage';
  favs: Array<UserFav>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export enum UserFavStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type UserFbInput = {
  referrer?: Maybe<Scalars['ID']>;
  gender: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
  fbToken: Scalars['String'];
  email: Scalars['String'];
  princessDating?: Maybe<Scalars['Boolean']>;
};

export type UserForgetPasswordInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  princessDating?: Maybe<Scalars['Boolean']>;
  lang: Scalars['String'];
};

export type UserFriend = {
  __typename?: 'UserFriend';
  id?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  friendUser?: Maybe<User>;
  rate?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  status?: Maybe<UserFriendStatusEnum>;
  createDt: Scalars['String'];
  lastModifyDt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type UserFriendPage = {
  __typename?: 'UserFriendPage';
  friends: Array<UserFriend>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export enum UserFriendStatusEnum {
  None = 'NONE',
  Unrespond = 'UNRESPOND',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED',
  Cancelled = 'CANCELLED',
  ByQuota = 'BY_QUOTA'
}

export type UserLoginInput = {
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  princessDating?: Maybe<Scalars['Boolean']>;
  deviceType?: Maybe<DeviceTypeEnum>;
  deviceToken?: Maybe<Scalars['String']>;
};

export type UserLogoutInput = {
  deviceType?: Maybe<DeviceTypeEnum>;
  deviceToken?: Maybe<Scalars['String']>;
};

export type UserMakeFriendRequest = {
  userId: Scalars['ID'];
  wine?: Maybe<Scalars['Int']>;
  status: UserFriendStatusEnum;
};

export type UserObject = {
  __typename?: 'UserObject';
  userId: Scalars['ID'];
  datatype: DatatypeEnum;
  key: UserObjectKeyEnum;
  data: Scalars['String'];
  createDt?: Maybe<Scalars['String']>;
  lastModifyDt?: Maybe<Scalars['String']>;
};

export type UserObjectInput = {
  key: UserObjectKeyEnum;
  data: Scalars['String'];
};

export enum UserObjectKeyEnum {
  AgeVerify = 'AGE_VERIFY',
  AgeVerifyRejectReason = 'AGE_VERIFY_REJECT_REASON',
  VoiceIntroRejectReason = 'VOICE_INTRO_REJECT_REASON',
  IosLogin = 'IOS_LOGIN',
  IosLogout = 'IOS_LOGOUT',
  AndroidLogin = 'ANDROID_LOGIN',
  AndroidLogout = 'ANDROID_LOGOUT',
  FirebaseLogin = 'FIREBASE_LOGIN',
  FirebaseLogout = 'FIREBASE_LOGOUT'
}

export type UserObjectQueryInput = {
  userId?: Maybe<Scalars['ID']>;
  key: UserObjectKeyEnum;
};

export type UserPage = {
  __typename?: 'UserPage';
  users: Array<User>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export type UserPhoneLoginInput = {
  phone: Scalars['String'];
  princessDating?: Maybe<Scalars['Boolean']>;
};

export type UserPutAboutInput = {
  userId?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  ageMin?: Maybe<Scalars['Int']>;
  ageMax?: Maybe<Scalars['Int']>;
  aboutEconomy?: Maybe<UserAboutEconomyEnum>;
  aboutHeight?: Maybe<UserAboutHeightEnum>;
  aboutBody?: Maybe<UserAboutBodyEnum>;
  aboutWaist?: Maybe<UserAboutWaistEnum>;
  aboutStyle?: Maybe<Scalars['Int']>;
  aboutDrink?: Maybe<UserAboutDrinkEnum>;
  aboutSmoke?: Maybe<UserAboutSmokeEnum>;
  aboutMe?: Maybe<Scalars['String']>;
  dateExpense?: Maybe<UserDateExpenseEnum>;
  dateOutline?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  wechat?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  contactPrivacy?: Maybe<UserContactPrivacyEnum>;
};

export type UserPutProfileInput = {
  gender?: Maybe<Scalars['String']>;
  nickname: Scalars['String'];
  dob: Scalars['String'];
  city: Scalars['String'];
  whatsapp?: Maybe<Scalars['String']>;
  wechat?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
};

export type UserPutVerifyInput = {
  userId?: Maybe<Scalars['ID']>;
  voice?: Maybe<UserVerifyEnum>;
  ageVerify?: Maybe<UserVerifyEnum>;
  reason?: Maybe<Scalars['String']>;
};

export type UserRateFriendRequest = {
  userId: Scalars['ID'];
  rate: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};

export enum UserRateLevelEnum {
  Normal = 'NORMAL',
  Verified = 'VERIFIED',
  Premium = 'PREMIUM'
}

export type UserReferreeInput = {
  userId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type UserReferreePage = {
  __typename?: 'UserReferreePage';
  user?: Maybe<Array<Maybe<User>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export type UserRegisterInput = {
  referrer?: Maybe<Scalars['ID']>;
  gender: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  princessDating?: Maybe<Scalars['Boolean']>;
};

export type UserResetPasswordInput = {
  verifyCode: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  password: Scalars['String'];
};

export enum UserRoleEnum {
  Normal = 'NORMAL',
  Silver = 'SILVER',
  HLvMember = 'H_LV_MEMBER',
  Premium = 'PREMIUM'
}

export type UserSearchInput = {
  userId?: Maybe<Scalars['ID']>;
  /** admin only */
  nickname?: Maybe<Scalars['String']>;
  /** admin only */
  phone?: Maybe<Scalars['String']>;
  /** admin only */
  email?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  ageMin?: Maybe<Scalars['Int']>;
  ageMax?: Maybe<Scalars['Int']>;
  ageVerify?: Maybe<Scalars['String']>;
  voice?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatusEnum>;
  rateLevel?: Maybe<UserRateLevelEnum>;
  role?: Maybe<UserRoleEnum>;
  order?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirectionEnum>;
  princessDating?: Maybe<Scalars['Boolean']>;
  lastSeenBefore?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  id: Scalars['ID'];
  user?: Maybe<User>;
  datatype: DatatypeEnum;
  key: SettingKeyEnum;
  value?: Maybe<Scalars['String']>;
};

export type UserSettingInput = {
  id?: Maybe<Scalars['ID']>;
  datatype?: Maybe<DatatypeEnum>;
  key: SettingKeyEnum;
  value?: Maybe<Scalars['String']>;
};

export enum UserStatusEnum {
  NotVerify = 'NOT_VERIFY',
  Verified = 'VERIFIED',
  ProfileCompleted = 'PROFILE_COMPLETED',
  Deleted = 'DELETED',
  Banned = 'BANNED'
}

export enum UserVerifyEnum {
  Empty = 'EMPTY',
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  RemovedByUser = 'REMOVED_BY_USER',
  RemovedByAdmin = 'REMOVED_BY_ADMIN'
}

export type UserVerifyInput = {
  verifyCode: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  deviceType?: Maybe<DeviceTypeEnum>;
  deviceToken?: Maybe<Scalars['String']>;
};

export type UserView = {
  __typename?: 'UserView';
  id?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  target?: Maybe<User>;
  createDt: Scalars['String'];
  lastModifyDt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type UserViewPage = {
  __typename?: 'UserViewPage';
  views: Array<UserView>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export type UserViewQueryInput = {
  userId?: Maybe<Scalars['ID']>;
  targetId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type VirtualPurchaseInput = {
  purchaseType: VirtualPurchaseTypeEnum;
  quantity: Scalars['Int'];
};

export enum VirtualPurchaseTypeEnum {
  Wine = 'WINE',
  FrdQuota = 'FRD_QUOTA'
}

export type WallPost = {
  __typename?: 'WallPost';
  wallPostId?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  content?: Maybe<Scalars['String']>;
  permission?: Maybe<PermissionEnum>;
  isAdult?: Maybe<IsAdultEnum>;
  status?: Maybe<FileStatusEnum>;
  createDt: Scalars['String'];
  lastModifyDt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type WallPostInput = {
  wallPostId?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  permission?: Maybe<PermissionEnum>;
  isAdult?: Maybe<IsAdultEnum>;
  status: FileStatusEnum;
};

export type WallPostPage = {
  __typename?: 'WallPostPage';
  wallPosts: Array<WallPost>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export type WallPostSearchInput = {
  userId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type WineReturn = {
  __typename?: 'WineReturn';
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  emptyWine?: Maybe<Scalars['Int']>;
  type?: Maybe<CryptocurrencyTypeEnum>;
  address?: Maybe<Scalars['String']>;
  status?: Maybe<WineReturnStatusEnum>;
  createDt: Scalars['String'];
  lastModifyDt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type WineReturnInput = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type WineReturnPage = {
  __typename?: 'WineReturnPage';
  wineReturns: Array<WineReturn>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};

export enum WineReturnStatusEnum {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Pending = 'PENDING',
  Deleted = 'DELETED'
}

export type CancelSubscriptionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CancelSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { cancelSubscription: (
    { __typename?: 'Subscription' }
    & Pick<Subscription, 'subscriptionId'>
  ) }
);

export type SubscriptionsQueryVariables = Exact<{
  input: SubscriptionQueryInput;
}>;


export type SubscriptionsQuery = (
  { __typename?: 'Query' }
  & { subscriptions: (
    { __typename?: 'SubscriptionPage' }
    & Pick<SubscriptionPage, 'page' | 'pageSize' | 'count'>
    & { subscriptions: Array<(
      { __typename?: 'Subscription' }
      & Pick<Subscription, 'subscriptionId' | 'paymentType' | 'plan' | 'currency' | 'amount' | 'status' | 'nextChargeDt' | 'createDt'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'userId' | 'nickname' | 'email'>
      )> }
    )> }
  ) }
);

export type UserFullFragment = (
  { __typename?: 'User' }
  & Pick<User, 'idToken' | 'userId' | 'gender' | 'email' | 'phone' | 'nickname' | 'dob' | 'city' | 'logo' | 'ageMin' | 'ageMax' | 'whatsapp' | 'wechat' | 'telegram' | 'line' | 'aboutEconomy' | 'aboutHeight' | 'aboutBody' | 'aboutWaist' | 'aboutStyle' | 'aboutDrink' | 'aboutSmoke' | 'aboutMe' | 'dateExpense' | 'dateOutline' | 'contactPrivacy' | 'lang' | 'showAdult' | 'credit' | 'silverEndDt' | 'premiumEndDt' | 'role' | 'status' | 'millionbb' | 'princessDating' | 'lastSeen' | 'createDt' | 'lastModifyDt' | 'voice' | 'ageVerify' | 'restrictedLevel'>
);

export type SearchUsersQueryVariables = Exact<{
  userSearchInput: UserSearchInput;
}>;


export type SearchUsersQuery = (
  { __typename?: 'Query' }
  & { searchUsers: (
    { __typename?: 'UserPage' }
    & Pick<UserPage, 'page' | 'pageSize' | 'count'>
    & { users: Array<(
      { __typename?: 'User' }
      & UserFullFragment
    )> }
  ) }
);

export type UserQueryVariables = Exact<{
  singleUserInput: SingleUserInput;
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'idToken' | 'userId' | 'gender' | 'email' | 'phone' | 'nickname' | 'dob' | 'city' | 'logo' | 'logoHalf' | 'logoFull' | 'ageMin' | 'ageMax' | 'whatsapp' | 'wechat' | 'telegram' | 'line' | 'aboutEconomy' | 'aboutHeight' | 'aboutBody' | 'aboutWaist' | 'aboutStyle' | 'aboutDrink' | 'aboutSmoke' | 'aboutMe' | 'dateExpense' | 'dateOutline' | 'contactPrivacy' | 'lang' | 'showAdult' | 'credit' | 'creditSpent' | 'silverEndDt' | 'premiumEndDt' | 'role' | 'frdQuota' | 'msgQuota' | 'status' | 'millionbb' | 'princessDating' | 'lastSeen' | 'voice' | 'ageVerify' | 'referrer' | 'referralCode' | 'wine' | 'emptyWine' | 'rateLevel' | 'restrictedLevel' | 'vip'>
    & { rateSummary?: Maybe<(
      { __typename?: 'RateSummary' }
      & Pick<RateSummary, 'rate' | 'count'>
    )> }
  ) }
);

export const UserFullFragmentDoc = gql`
    fragment UserFull on User {
  idToken
  userId
  gender
  email
  phone
  nickname
  dob
  city
  logo
  ageMin
  ageMax
  whatsapp
  wechat
  telegram
  line
  aboutEconomy
  aboutHeight
  aboutBody
  aboutWaist
  aboutStyle
  aboutDrink
  aboutSmoke
  aboutMe
  dateExpense
  dateOutline
  contactPrivacy
  lang
  showAdult
  credit
  silverEndDt
  premiumEndDt
  role
  status
  millionbb
  princessDating
  lastSeen
  createDt
  lastModifyDt
  voice
  ageVerify
  restrictedLevel
}
    `;
export const CancelSubscriptionDocument = gql`
    mutation cancelSubscription($id: ID!) {
  cancelSubscription(id: $id) {
    subscriptionId
  }
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const SubscriptionsDocument = gql`
    query subscriptions($input: SubscriptionQueryInput!) {
  subscriptions(input: $input) {
    subscriptions {
      subscriptionId
      user {
        userId
        nickname
        email
      }
      paymentType
      plan
      currency
      amount
      status
      nextChargeDt
      createDt
    }
    page
    pageSize
    count
  }
}
    `;

/**
 * __useSubscriptionsQuery__
 *
 * To run a query within a React component, call `useSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
      }
export function useSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
        }
export type SubscriptionsQueryHookResult = ReturnType<typeof useSubscriptionsQuery>;
export type SubscriptionsLazyQueryHookResult = ReturnType<typeof useSubscriptionsLazyQuery>;
export type SubscriptionsQueryResult = Apollo.QueryResult<SubscriptionsQuery, SubscriptionsQueryVariables>;
export const SearchUsersDocument = gql`
    query searchUsers($userSearchInput: UserSearchInput!) {
  searchUsers(input: $userSearchInput) {
    users {
      ...UserFull
    }
    page
    pageSize
    count
  }
}
    ${UserFullFragmentDoc}`;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      userSearchInput: // value for 'userSearchInput'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const UserDocument = gql`
    query user($singleUserInput: SingleUserInput!) {
  user(input: $singleUserInput) {
    idToken
    userId
    gender
    email
    phone
    nickname
    dob
    city
    logo
    logoHalf
    logoFull
    ageMin
    ageMax
    whatsapp
    wechat
    telegram
    line
    aboutEconomy
    aboutHeight
    aboutBody
    aboutWaist
    aboutStyle
    aboutDrink
    aboutSmoke
    aboutMe
    dateExpense
    dateOutline
    contactPrivacy
    lang
    showAdult
    credit
    creditSpent
    silverEndDt
    premiumEndDt
    role
    frdQuota
    msgQuota
    status
    millionbb
    princessDating
    lastSeen
    voice
    ageVerify
    referrer
    referralCode
    wine
    emptyWine
    rateLevel
    restrictedLevel
    vip
    rateSummary {
      rate
      count
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      singleUserInput: // value for 'singleUserInput'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;