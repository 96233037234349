import gql from 'graphql-tag';

export const payments = gql`
  query payments($paymentQueryInput: PaymentQueryInput!) {
    payments(input: $paymentQueryInput) {
      payments {
        id
        user {
          userId
          nickname
        }
        paymentType
        currency
        amount
        logType
        data
        createDt
      }
      page
      pageSize
      count
    }
  }
`;
export const wineReturns = gql`
  query wineReturns($page: Int, $pageSize: Int) {
    wineReturns(input: { page: $page, pageSize: $pageSize }) {
      wineReturns {
        id
        userId
        user {
          nickname
          userId
        }
        emptyWine
        type
        address
        status
        createDt
      }
      page
      pageSize
      count
    }
  }
`;
