import { ThemeProvider } from '@material-ui/styles';
import React from 'react';

import { theme } from 'conf/app';
import { UserProvider } from 'contexts/user';
import { ApolloProvider, client } from 'ql';
import 'helpers/i18next';
import Router from 'components/Router';

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <Router />
        </UserProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
