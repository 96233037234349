import gql from 'graphql-tag';

export const adminPutUser = gql`
  mutation adminPutUser($input: AdminUserInput!) {
    adminPutUser(input: $input) {
      userId
    }
  }
`;
export const adminPutUserData = gql`
  mutation adminPutUser(
    $userId: ID!
    $gender: String
    $dob: String
    $aboutMe: String
    $dateOutline: String
    $frdQuota: Int
    $password: String
    $telegram: String
    $whatsapp: String
    $wechat: String
    $line: String
    $status: UserStatusEnum
    $restrictedLevel: RestrictedLevelEnum
    $reason: String
    $logo: String
    $logoHalf: String
    $logoFull: String
    $millionbb: Boolean
    $princessDating: Boolean
  ) {
    adminPutUser(
      input: {
        userId: $userId
        gender: $gender
        dob: $dob
        aboutMe: $aboutMe
        dateOutline: $dateOutline
        frdQuota: $frdQuota
        password: $password
        telegram: $telegram
        line: $line
        whatsapp: $whatsapp
        wechat: $wechat
        status: $status
        restrictedLevel: $restrictedLevel
        reason: $reason
        logo: $logo
        logoHalf: $logoHalf
        logoFull: $logoFull
        millionbb: $millionbb
        princessDating: $princessDating
      }
    ) {
      userId
      gender
      lastModifyDt
      aboutMe
      dateOutline
      frdQuota
      logo
      telegram
      line
      whatsapp
      wechat
      status
    }
  }
`;
