import gql from 'graphql-tag';
export const getFriends = gql`
  query friends(
    $page: Int!
    $pageSize: Int!
    $nickname: String
    $status: UserFriendStatusEnum
    $orderDirection: OrderDirectionEnum
  ) {
    friends(
      input: {
        page: $page
        pageSize: $pageSize
        nickname: $nickname
        status: $status
        orderDirection: $orderDirection
      }
    ) {
      friends {
        id
        user {
          userId
          nickname
        }
        friendUser {
          userId
          nickname
        }
        status
        createDt
      }
      page
      pageSize
      count
    }
  }
`;
