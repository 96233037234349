import { PaymentPage, PaymentQueryInput } from "millionbb-common";
import { QueryHookOptions, useQuery } from "@apollo/client";
import * as Queries from "../queries";

export * from "./admin";
export * from "./user";

export const usePayments = (
  options: QueryHookOptions<
    { payments: PaymentPage },
    { paymentQueryInput: PaymentQueryInput }
  >
) => {
  return useQuery(Queries.payments, options);
};
