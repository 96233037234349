import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, ListItem, ListItemText } from '@material-ui/core';

import { ResponsiveDialog } from './ResponsiveDialog';
import { useAdminUserUpgrade } from 'ql/hooks';

interface UserUpgradeDialogProps {
  userId?: number;
  onClose: () => void;
}

const plans = [
  {
    key: 'h_lv_member_3mo',
    role: 'h_lv_member',
    price: 120,
    days: 90,
    type: 4,
    frdQuota: 8,
    msgQuota: 150,
  },
  {
    key: 'h_lv_member_6mo',
    role: 'h_lv_member',
    price: 240,
    days: 180,
    type: 4,
    frdQuota: 15,
    msgQuota: 300,
  },
  {
    key: 'premium_6mo',
    role: 'premium',
    price: 594,
    days: 180,
    type: 3,
    frdQuota: 36,
  },
  {
    key: 'premium_1yr',
    role: 'premium',
    price: 1188,
    days: 365,
    type: 3,
    frdQuota: 72,
  },
];

export const UserUpgradeDialog: FC<UserUpgradeDialogProps> = ({ userId, onClose }) => {
  const { t } = useTranslation('user');
  const [adminUserUpgrade] = useAdminUserUpgrade();

  return (
    <ResponsiveDialog open={userId !== undefined} onClose={onClose} maxWidth="sm">
      <Typography variant="h4" color="primary">
        {t('upgrade.title')}
      </Typography>

      {plans.map((plan) => (
        <ListItem
          button={true}
          style={{ color: 'black' }}
          onClick={async () => {
            if (
              window.confirm(
                t('common:action.confirm_action', {
                  action: t('upgrade.title'),
                })
              )
            ) {
              const adminUpgradeUserInput = {
                userId,
                type: plan.type,
                day: plan.days,
                frdQuota: plan.frdQuota,
                msgQuota: plan.msgQuota,
              };
              await adminUserUpgrade({
                variables: { adminUpgradeUserInput },
              });
              alert(t('common:msg.action_success', { action: t('upgrade.title') }));
              onClose();
            }
          }}
        >
          <ListItemText
            primary={t('upgrade.upgrade_to', { lv: t(`role.${plan.role}`) })}
            secondary={t('upgrade.desc', {
              days: plan.days,
              lv: t(`role.${plan.role}`),
              frdQuota: plan.frdQuota,
              msgQuota: plan.msgQuota ? plan.msgQuota : t('upgrade.unlimited'),
            })}
          />
        </ListItem>
      ))}
    </ResponsiveDialog>
  );
};
