interface KeyValueString {
  [key: string]: string;
}

export const Country: KeyValueString = { hk: 'Hong Kong', mo: 'Macao', tw: 'Taiwan' };

export const Subdivision: KeyValueString = {
  hk_hcw: 'Central and Western District',
  hk_hea: 'Eastern',
  hk_hso: 'Southern',
  hk_hwc: 'Wan Chai',
  hk_kkc: 'Kowloon City',
  hk_kkt: 'Kwun Tong',
  hk_kss: 'Sham Shui Po',
  hk_kwt: 'Wong Tai Sin',
  hk_kyt: 'Yau Tsim Mong',
  hk_nis: 'Islands District',
  hk_nkt: 'Kwai Tsing',
  hk_nno: 'North',
  hk_nsk: 'Sai Kung District',
  hk_nst: 'Sha Tin',
  hk_ntm: 'Tuen Mun',
  hk_ntp: 'Tai Po District',
  hk_ntw: 'Tsuen Wan District',
  hk_nyl: 'Yuen Long District',
  tw_cha: 'Changhua',
  tw_cyi: 'Chiayi',
  tw_cyq: 'Chiayi County',
  tw_hsq: 'Hsinchu',
  tw_hsz: 'Hsinchu County',
  tw_hua: 'Hualien',
  tw_ila: 'Yilan',
  tw_kee: 'Keelung',
  tw_khh: 'Kaohsiung',
  tw_kin: 'Kinmen County',
  tw_lie: 'Lienchiang',
  tw_mia: 'Miaoli',
  tw_nan: 'Nantou',
  tw_nwt: 'New Taipei',
  tw_pen: 'Penghu County',
  tw_pif: 'Pingtung',
  tw_tao: 'Taoyuan',
  tw_tnn: 'Tainan',
  tw_tpe: 'Taipei City',
  tw_ttt: 'Taitung',
  tw_txg: 'Taichung City',
  tw_yun: 'Yunlin',
};

export const City: KeyValueString = {
  hk__fu_tei_wan: 'Fu Tei Wan',
  hk__pak_kok_san_tsuen: 'Pak Kok San Tsuen',
  hk__pak_shek_kok: 'Pak Shek Kok',
  hk_hcw_admiralty: 'Admiralty',
  hk_hcw_central: 'Central',
  hk_hcw_kennedy_town: 'Kennedy Town',
  hk_hcw_mid_levels: 'Mid Levels',
  hk_hcw_sai_ying_pun: 'Sai Ying Pun',
  hk_hcw_shek_tong_tsui: 'Shek Tong Tsui',
  hk_hcw_sheung_wan: 'Sheung Wan',
  hk_hcw_tai_hang: 'Tai Hang',
  hk_hcw_tai_ping_shan: 'Tai Ping Shan',
  hk_hea_causeway_bay: 'Causeway Bay',
  hk_hea_chai_wan: 'Chai Wan',
  hk_hea_fortress_hill: 'Fortress Hill',
  hk_hea_north_point: 'North Point',
  hk_hea_quarry_bay: 'Quarry Bay',
  hk_hea_sai_wan_ho: 'Sai Wan Ho',
  hk_hea_shau_kei_wan: 'Shau Kei Wan',
  hk_hea_tai_koo: 'Tai Koo',
  hk_hso_aberdeen: 'Aberdeen',
  hk_hso_chek_chue: 'Chek Chue',
  hk_hso_deep_water_bay: 'Deep Water Bay',
  hk_hso_repulse_bay: 'Repulse Bay',
  hk_hso_sai_wan: 'Sai Wan',
  hk_hso_tai_tam: 'Tai Tam',
  hk_hso_tin_wan: 'Tin Wan',
  hk_hso_wong_chuk_hang: 'Wong Chuk Hang',
  hk_hwc_happy_valley: 'Happy Valley',
  hk_hwc_jardines_lookout: 'Jardines Lookout',
  hk_hwc_so_kon_po: 'So Kon Po',
  hk_hwc_wanchai: 'Wanchai',
  hk_kkc_ho_man_tin: 'Ho Man Tin',
  hk_kkc_hung_hom: 'Hung Hom',
  hk_kkc_jordon: 'Jordon',
  hk_kkc_kowloon: 'Kowloon',
  hk_kkc_kowloon_city: 'Kowloon City',
  hk_kkc_to_kwa_wan: 'To Kwa Wan',
  hk_kkc_tsimshatsui: 'Tsimshatsui',
  hk_kkt_cha_kwo_ling: 'Cha Kwo Ling',
  hk_kkt_kowloon_bay: 'Kowloon Bay',
  hk_kkt_lam_tin: 'Lam Tin',
  hk_kkt_kwun_tong: 'Kwun Tong',
  hk_kkt_ngau_tau_kok: 'Ngau Tau Kok',
  hk_kss_cheung_sha_wan: 'Cheung Sha Wan',
  hk_kss_lai_chi_kok: 'Lai Chi Kwok',
  hk_kss_sham_shui_po: 'Sham Shui Po',
  hk_kss_shek_kip_mei: 'Shek Kip Mei',
  hk_kwt_choi_hung: 'Choi Hung',
  hk_kwt_ngau_chi_wan: 'Ngau Chi Wan',
  hk_kwt_san_po_kong: 'San Po Kong',
  hk_kwt_tsz_wan_shan: 'Tsz Wan Shan',
  hk_kwt_wong_tai_sin: 'Wong Tai Sin',
  hk_kyt_mong_kok: 'Mong Kok',
  hk_kyt_prince_edward: 'Prince Edward',
  hk_kyt_tai_kok_tsui: 'Tai Kok Tsui',
  hk_kyt_tsim_sha_tsui: 'Tsim Sha Tsui',
  hk_kyt_yau_ma_tei: 'Yau Ma Tei',
  hk_nis_cheung_chau: 'Cheung Chau',
  hk_nis_discovery_bay: 'Discovery Bay',
  hk_nis_ma_wan: 'Ma Wan',
  hk_nis_pak_ngan_heung: 'Pak Ngan Heung',
  hk_nis_tai_o: 'Tai O',
  hk_nis_tung_chung: 'Tung Chung',
  hk_nkt_kwai_chung: 'Kwai Chung',
  hk_nkt_tai_wo: 'Tai Wo',
  hk_nkt_tai_wo_hau_estate: 'Tai Wo Hau Estate',
  hk_nkt_tsing_yi_town: 'Tsing Yi Town',
  hk_nno_fanling: 'Fanling',
  hk_nno_sha_tau_kok: 'Sha Tau Kok',
  hk_nno_sheung_shui: 'Sheung Shui',
  hk_nsk_hang_hau: 'Hang Hau',
  hk_nsk_mau_ping: 'Mau Ping',
  hk_nsk_sai_kung: 'Sai Kung',
  hk_nsk_tiu_keng_leng: 'Tiu Keng Leng',
  hk_nsk_tseung_kwan_o: 'Tseung Kwan O',
  hk_nsk_yau_yue_wan: 'Yau Yue Wan',
  hk_nst_fo_tan: 'Fo Tan',
  hk_nst_ma_liu_shui: 'Ma Liu Shui',
  hk_nst_ma_on_shan_tsuen: 'Ma On Shan Tsuen',
  hk_nst_sha_tin: 'Shatin',
  hk_nst_sha_tin_wai: 'Sha Tin Wai',
  hk_nst_siu_lek_yuen: 'Siu Lek Yuen',
  hk_nst_tai_wai: 'Tai Wai',
  hk_ntm_so_kwun_wat: 'So Kwun Wat',
  hk_ntm_tuen_mun: 'Tuen Mun',
  hk_ntm_tuen_mun_san_hui: 'Tuen Mun San Hui',
  hk_ntp_hong_lok_yuen: 'Hong Lok Yuen',
  hk_ntp_pun_shan_chau: 'Pun Shan Chau',
  hk_ntp_tai_po: 'Tai Po',
  hk_ntp_tai_po_kau: 'Tai Po Kau',
  hk_ntw_kwai_chung: 'Kwai Chung',
  hk_ntw_ma_yau_tong: 'Ma Yau Tong',
  hk_ntw_sham_tseng: 'Sham Tseng',
  hk_ntw_tai_lin_pai: 'Tai Lin Pai',
  hk_ntw_ting_kau: 'Ting Kau',
  hk_ntw_tsing_lung_tau: 'Tsing Lung Tau',
  hk_ntw_tsuen_wan: 'Tsuen Wan',
  hk_nyl_lam_tei: 'Lam Tei',
  hk_nyl_san_tin: 'San Tin',
  hk_nyl_tin_shui_wai: 'Tin Shui Wai',
  hk_nyl_yuen_long: 'Yuen Long',
  mo__luhuan: 'Luhuan',
  mo__macao: 'Macao',
  mo__taipa: 'Taipa',
  'tw__wan-hua': 'Wan-hua',
  tw_cha_andong: 'Andong',
  tw_cha_baitang: 'Baitang',
  tw_cha_bajian: 'Bajian',
  tw_cha_caogang: 'Caogang',
  'tw_cha_chang-hua': 'Chang-hua',
  tw_cha_chaoyang: 'Chaoyang',
  tw_cha_chushui: 'Chushui',
  tw_cha_dacheng: 'Dacheng',
  tw_cha_fangyuan: 'Fangyuan',
  "tw_cha_tai'an": "Tai'an",
  tw_cha_tounan: 'Tounan',
  tw_cha_yuanlin: 'Yuanlin',
  tw_cyi_chiayi_city: 'Chiayi City',
  tw_cyq_ailiao: 'Ailiao',
  tw_cyq_aogu: 'Aogu',
  tw_cyq_beidou: 'Beidou',
  tw_cyq_budai: 'Budai',
  tw_cyq_cuxi: 'Cuxi',
  tw_hsq_hsinchu: 'Hsinchu',
  tw_hsz_aikou: 'Aikou',
  tw_hsz_baoshan: 'Baoshan',
  tw_hsz_buhe: 'Buhe',
  tw_hsz_dadu: 'Dadu',
  tw_hsz_qingquan: 'Qingquan',
  tw_hsz_zhubei: 'Zhubei',
  tw_hua_chinan: 'Chinan',
  tw_hua_dali: 'Dali',
  tw_hua_dexing: 'Dexing',
  tw_hua_hualien_city: 'Hualien City',
  tw_hua_hualong: 'Hualong',
  tw_hua_taichang: 'Taichang',
  tw_hua_yuli: 'Yuli',
  tw_ila_aiding: 'Aiding',
  tw_ila_baili: 'Baili',
  tw_ila_bailing: 'Bailing',
  tw_ila_baxian: 'Baxian',
  tw_ila_beiguan: 'Beiguan',
  tw_ila_bitou: 'Bitou',
  tw_ila_daxi: 'Daxi',
  tw_ila_jiaosi: 'Jiaosi',
  tw_ila_wujie: 'Wujie',
  tw_ila_xindian: 'Xindian',
  tw_ila_yilan: 'Yilan',
  tw_kee_baifu: 'Baifu',
  tw_kee_keelung: 'Keelung',
  tw_khh_alian: 'Alian',
  tw_khh_anzhao: 'Anzhao',
  tw_khh_baidong: 'Baidong',
  tw_khh_baoshan: 'Baoshan',
  tw_khh_caoya: 'Caoya',
  tw_khh_chaoliao: 'Chaoliao',
  tw_khh_chenggong: 'Chenggong',
  tw_khh_chongde: 'Chongde',
  tw_khh_dagang: 'Dagang',
  tw_khh_dahua: 'Dahua',
  tw_khh_dakeng: 'Dakeng',
  tw_khh_dashe: 'Dashe',
  tw_khh_dexing: 'Dexing',
  tw_khh_fuxingcun: 'Fuxingcun',
  tw_khh_kaohsiung_city: 'Kaohsiung City',
  tw_khh_linnei: 'Linnei',
  tw_khh_luzhu: 'Luzhu',
  tw_khh_xinyuan: 'Xinyuan',
  tw_kin_beishan: 'Beishan',
  tw_kin_dongshan: 'Dongshan',
  tw_kin_jincheng: 'Jincheng',
  tw_lie_changtanwo: 'Changtanwo',
  tw_lie_lehuacun: 'Lehuacun',
  tw_lie_mazucun: 'Mazucun',
  tw_mia_beihe: 'Beihe',
  tw_mia_beimiao: 'Beimiao',
  tw_mia_beishi: 'Beishi',
  tw_mia_beixing: 'Beixing',
  tw_mia_beiying: 'Beiying',
  tw_mia_dake: 'Dake',
  tw_mia_miaoli: 'Miaoli',
  tw_mia_niufenping: 'Niufenping',
  tw_mia_sanyi: 'Sanyi',
  'tw_mia_shih-lin_inspection_station': 'Shih-lin Inspection Station',
  tw_mia_toufen_township: 'Toufen Township',
  tw_nan_buzhong: 'Buzhong',
  tw_nan_caotun: 'Caotun',
  tw_nan_chuxiang: 'Chuxiang',
  tw_nan_guoxing: 'Guoxing',
  tw_nan_nantou_city: 'Nantou City',
  tw_nan_puli: 'Puli',
  tw_nan_qingshui: 'Qingshui',
  tw_nwt_bajia: 'Bajia',
  tw_nwt_banqiao: 'Banqiao',
  tw_nwt_bitan: 'Bitan',
  tw_nwt_danshui: 'Danshui',
  tw_nwt_daping: 'Daping',
  tw_nwt_dingfu: 'Dingfu',
  tw_nwt_linkou_district: 'Linkou District',
  tw_nwt_neihu: 'Neihu',
  tw_nwt_shulin_district: 'Shulin District',
  tw_nwt_tucheng: 'Tucheng',
  tw_nwt_xizhi_district: 'Xizhi District',
  tw_pen_chengqian: 'Chengqian',
  tw_pen_chidong: 'Chidong',
  tw_pen_magong: 'Magong',
  "tw_pen_penghu'ercun": "Penghu'ercun",
  tw_pif_changlong: 'Changlong',
  tw_pif_chaocuo: 'Chaocuo',
  tw_pif_chashan: 'Chashan',
  tw_pif_dafu: 'Dafu',
  tw_pif_dalin: 'Dalin',
  tw_pif_datie: 'Datie',
  tw_pif_donggang: 'Donggang',
  tw_pif_gangzi: 'Gangzi',
  tw_pif_gaolang: 'Gaolang',
  tw_pif_ligang: 'Ligang',
  tw_pif_pingtung_city: 'Pingtung City',
  tw_pif_zhonghe: 'Zhonghe',
  tw_pif_zhunan: 'Zhunan',
  tw_tao_bade_district: 'Bade District',
  tw_tao_baiyu: 'Baiyu',
  tw_tao_buxin: 'Buxin',
  tw_tao_cailiao: 'Cailiao',
  tw_tao_dabu: 'Dabu',
  tw_tao_daxi: 'Daxi',
  tw_tao_gaoyi: 'Gaoyi',
  tw_tao_longtan_district: 'Longtan District',
  tw_tao_neihu: 'Neihu',
  tw_tao_pingzhen_district: 'Pingzhen District',
  tw_tao_taoyuan_city: 'Taoyuan City',
  tw_tao_taoyuan_district: 'Taoyuan District',
  tw_tao_yangmei_district: 'Yangmei District',
  tw_tao_zhongli_district: 'Zhongli District',
  tw_tnn_annei: 'Annei',
  tw_tnn_anping_district: 'Anping District',
  tw_tnn_anshun: 'Anshun',
  tw_tnn_beihua: 'Beihua',
  tw_tnn_beimen: 'Beimen',
  tw_tnn_danei: 'Danei',
  tw_tnn_douliu: 'Douliu',
  tw_tnn_tainan_city: 'Tainan City',
  "tw_tnn_tong'anliao": "Tong'anliao",
  tw_tpe_daling: 'Daling',
  tw_tpe_nangang: 'Nangang',
  tw_tpe_neishuangxi: 'Neishuangxi',
  tw_tpe_taipei: 'Taipei',
  tw_tpe_tanmei: 'Tanmei',
  tw_tpe_zhouwei: 'Zhouwei',
  tw_ttt_baisha: 'Baisha',
  tw_ttt_dazhu: 'Dazhu',
  tw_ttt_decheng: 'Decheng',
  tw_ttt_fengtian: 'Fengtian',
  tw_ttt_taitung_city: 'Taitung City',
  tw_ttt_xiaogang: 'Xiaogang',
  tw_ttt_zhonghe: 'Zhonghe',
  tw_txg_ankeng: 'Ankeng',
  tw_txg_anli: 'Anli',
  tw_txg_anshan: 'Anshan',
  tw_txg_bailu: 'Bailu',
  tw_txg_beiliu: 'Beiliu',
  tw_txg_fengyuan: 'Fengyuan',
  tw_txg_houli: 'Houli',
  tw_txg_nantun: 'Nantun',
  tw_txg_shalu: 'Shalu',
  tw_txg_taichung: 'Taichung',
  tw_txg_wufeng_district: 'Wufeng District',
  tw_yun_adan: 'Adan',
  tw_yun_annan: 'Annan',
  tw_yun_aquan: 'Aquan',
  tw_yun_beigang: 'Beigang',
  tw_yun_bunan: 'Bunan',
  tw_yun_dounan: 'Dounan',
  tw_yun_huwei: 'Huwei',
};

export const getKeyByCityName = (city: string) => {
  return Object.keys(City).find(key => City[key] === city);
};
