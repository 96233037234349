import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputAdornment,
  TableCell,
  TextField,
  TableRow,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import moment from 'moment';
import { client, queries } from 'ql';
import { MBATable, Query } from 'components/table/MBATable';
import { UserNickname } from 'components/common/UserNickname';
import { UserFriendStatusEnum } from 'millionbb-common';

export const Friend: FC = () => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    filter: '',
    value: null,
  });
  const { t } = useTranslation('friend');
  let temp: any = '';
  return (
    <MBATable
      title={t('menu:friend')}
      options={{ filtering: true }}
      isLoading={loading}
      columns={[
        { title: t('field.id'), field: 'id', filtering: false, sorting: false },
        {
          title: t('field.user'),
          field: 'username',
          filtering: true,
          sorting: false,
          render: ({ user }: any, FilterRow) => <UserNickname {...user} />,
        },
        {
          title: t('field.friend'),
          field: 'friend',
          filtering: false,
          sorting: false,
          render: ({ friendUser }: any, FilterRow) => <UserNickname {...friendUser} />,
        },
        { title: t('field.status'), field: 'status', filtering: false, sorting: false },
        // { title: t('field.action'), field: 'action', filtering: true },
        {
          title: t('field.date'),
          field: 'createDt',
          sorting: true,
          render: (user: any) => moment.unix(user.createDt / 1000).format('YYYY-MM-DD'),
          filtering: false,
        },
      ]}
      data={async ({ page, pageSize, orderDirection }: Query<any>) => {
        setLoading(true);

        const { data } = await client.query({
          query: queries.getFriends,
          variables: {
            ...(filter.filter !== '' ? { [filter.filter]: filter.value } : {}),
            page: page + 1,
            pageSize,
          },
        });

        setLoading(false);
        return {
          data: data.friends.friends,
          page,
          totalCount: data.friends.count,
        };
      }}
      components={{
        FilterRow: ({ columns, onFilterChanged }: any) => {
          return (
            <>
              <TableRow>
                <TableCell />
                <TableCell key={1}>
                  <TextField
                    variant="outlined"
                    onChange={(e: any) => {
                      temp = e.target.value;
                    }}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter') {
                        setFilter({
                          filter: 'nickname',
                          value: temp,
                        });
                        onFilterChanged(1, temp);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell />
                <TableCell key={3}>
                  <Select
                    // className={classes.formControl}
                    value={filter.filter === 'status' ? filter.value : 'NONE'}
                    variant="outlined"
                    onChange={e => {
                      temp = e.target.value;
                      console.log(e.target.value);
                      setFilter({
                        filter: 'status',
                        value: temp as UserFriendStatusEnum,
                      });
                      onFilterChanged(3, temp);
                    }}
                    inputProps={{ name: 'role', id: 'outlined-role-simple' }}>
                    {Object.values(UserFriendStatusEnum).map(k => (
                      <MenuItem value={k} style={{ color: '#000' }}>
                        {k}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            </>
          );
        },
      }}
    />
  );
};

export default Friend;
