import { onError } from "@apollo/client/link/error";

import { AUTH__HEADER_KEY } from "conf/app";
import i18n from "../helpers/i18next";

export default onError((error) => {
  const { networkError, graphQLErrors } = error;

  if (networkError) {
    alert("無法連接伺服器，請重試");
    console.info(networkError);
  }

  if (graphQLErrors && Array.isArray(graphQLErrors)) {
    graphQLErrors.forEach(async (err) => {
      const {
        extensions: { code, exception },
        message,
      } = err;

      // console.info(`code`, code, `msg`, message);

      const format: { [key: string]: string } = {};
      if (exception) {
        const { stacktrace, ...obj } = exception;
        Object.keys(obj).forEach((k) => (format[k] = i18n.t(obj[k])));
      }

      if (code === "UNAUTHENTICATED" || message === "FORB__LOGIN_REQUIRED") {
        window.localStorage.removeItem(AUTH__HEADER_KEY);
        window.location.reload();
        return;
      }

      if (message) {
        alert(i18n.t(`msg:gql_err.${message.toLowerCase()}`));
      } else {
        alert(i18n.t(`msg:err.unknown`));
      }
    });
  }
});
