import { useMutation } from "@apollo/client";
import * as Mutations from "../mutations";
import {
  MutationAdminPutUserArgs,
  MutationAdminPutMessageArgs,
} from "millionbb-common";

export const useAdminLogin = () => useMutation(Mutations.adminLogin);

export const useAdminPutUser = () =>
  useMutation<any, MutationAdminPutUserArgs>(Mutations.adminPutUser);

export const useAdminUserUpgrade = () =>
  useMutation(Mutations.adminUpgradeUser);

export const useAdminPutMessage = () =>
  useMutation<any, MutationAdminPutMessageArgs>(Mutations.adminPutMessage);
