import React, { FC, useState } from "react";
import { queries } from "ql";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";

import TicketConversation from "./Ticket/TicketConversation";
import TicketList from "./Ticket/TicketList";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    width: "100%",
  },
}));

export const Ticket: FC = () => {
  const classes = useStyles({});
  const [currentConversation, setCurrentConveration] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(false);
  const { loading, error, data, refetch, fetchMore } = useQuery(
    queries.ticket,
    {
      variables: { page: 1, pageSize: 2000 },
    }
  );

  const loadConversation = (userId: number) => {
    setUpdateStatus(false);
    setCurrentConveration(userId);
  };

  return (
    <div className={classes.root}>
      <TicketList
        loadConversation={loadConversation}
        loading={loading}
        error={error}
        data={data}
        refetch={refetch}
        fetchMore={fetchMore}
      />
      <TicketConversation
        userId={currentConversation}
        updateStatus={updateStatus}
        setUpdateStatus={setUpdateStatus}
      />
    </div>
  );
};

export default Ticket;
