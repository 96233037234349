export default {
  common: {
    term: { action: '行動', reason: '原因', reject: '拒絕' },
    action: {
      confirm: '確認',
      confirm_action: '確認{{action}}',
      modify: '修改為',
    },
    msg: {
      action_success: '{{action}}成功',
      action_fail: '{{action}}失敗',
    },
  },
  creditlog: {
    field: {
      id: 'ID',
      user: '用戶',
      currency: '單位',
      amount: '數量',
      action: '動作',
      data: '資料',
      date: '日期',
    },
  },
  menu: {
    home: '主頁',
    user: '用戶管理',
    payment: '付款記錄',
    ticket: '用戶請求',
    uploads: '上傳檔案',
    logout: '登出',
    creditlog: '交易紀錄',
    winereturn: '貨幣兌換',
    friend: '朋友管理',
    subscription: '訂閱',
  },

  login: { username: '用戶名', password: '密碼', login: '登入' },

  ticket: {
    page: '頁',
    user: '用戶名',
    email: '電郵地址',
    phone: '聯絡電話',
    ticket: '用戶請求ID',
    next: '下一頁',
    previous: '上一頁',
    end: '頁尾',
    send: '送出',
  },

  payment: {
    field: {
      id: 'ID',
      user: '用戶',
      type: '方式',
      currency: '貨幣',
      amount: '金額',
      log_type: '行動',
      data: '參考值',
      create_dt: '時間',
    },
    type: {
      pay_request: '付款請求',
      pay_response: '付款完成',
      subscribe_request: '訂閱請求',
      subscribe_initial: '訂閱完成',
      subscribe_rebill: '訂閱續期',
      undefined: '未定義行動',
    },
  },
  uploads: {
    field: {
      file: '檔案',
      user: '用戶名稱',
      userId: '用戶編號',
      albumId: '相簿編號',
      date: '上傳日期',
      status: '狀態',
      manage: '操作',
      adult: '成人內容',
    },
    manage: {
      delete: '刪除',
      manage: '管理',
    },
  },
  wine: {
    field: {
      date: '申請日期',
      status: '狀態',
      user: '用戶名稱',
      quantity: '數量',
      type: '貨幣',
      manage: '管理',
    },
  },
  user: {
    field: {
      title: '會員資料',
      id: '序號',
      gender: '性別',
      nickname: '匿稱',
      email: '電郵',
      phone: '電話',
      role: '會藉',
      credit: 'Credit',
      create_dt: '注冊時間',
      status: '狀態',
      voice: '聲音認證',
      age: '年齡認證',
      details: '更多',
      dob: '生日',
      city: '地區',
      line: 'Line',
      telegram: 'Telegram',
      wechat: 'WeChat',
      whatsapp: 'Whatsapp',
      approve: '接受',
      reject: '拒絕',
      remove: '移除',
      confirm_dialog_title: '',
      confirm_dialog_content_text: '確定？',
      cancel: '取消',
      confirm: '確定',
      aboutEconomy: '經濟狀態',
      aboutHeight: '身高',
      aboutBody: '體型',
      aboutWaist: '腰圍',
      aboutStyle: '風格',
      aboutMe: '關於我',
      voiceIntro: '聲音簡介',
      ageVerify: '年齡認證',
      aboutDrink: '飲酒',
      aboutSmoke: '吸煙',
      dateOutline: '約會描述',
      dateExpense: '約會費用',
      contactMethod: '聯絡方法',
      contactPrivacy: '我的聯絡隱私',
      referrer: '介紹人ID',
      referralCode: '介紹碼',
      emptyWine: '空酒杯數量',
      wine: '紅酒數量',
      rateLevel: '認證等級',
      ageMin: '尋找年齡由',
      ageMax: '尋找年齡至',
      lastSeen: '最後登入',
      premiumEndDt: '富豪會員完結',
      silverEndDt: '優質會員完結',
      creditSpent: '已使用Credit',
      frdQuota: '交友Quota',
      msgQuota: '短訊Quota',
      vip: 'VIP等級',
      restricted: '審批等級',
      is_millionbb: 'Millionbb會員',
      is_princess_dating: 'Princess Dating會員',
      album: '相簿',
      send: '傳送',
      message: '向用戶傳送訊息',
      logo: '頭像',
      logo_half: '半身照',
      logo_full: '全身照',
    },
    role: {
      normal: '免費會員',
      silver: '試用會員',
      h_lv_member: '高級會員',
      premium: '富豪會員',
    },
    status: {
      not_verify: '未驗證',
      verified: '未填寫資料',
      profile_completed: '活躍',
      banned: '封鎖',
      deleted: '已刪除',
      activated: '已成為會員',
      not_activated: '未申請',
    },

    upgrade: {
      title: '升級會員',
      upgrade_to: '升級{{lv}}',
      desc:
        '{{days}}天{{lv}}，{{frdQuota}}個交換聯絡方式，{{msgQuota}}個免費訊息',
      unlimited: '無限',
    },
    about: {
      urgent: '江湖告急',
      not_enough: '長期缺錢',
      sometime_no: '有點缺錢',
      sufficient: '自給自足',
      some_spare: '有點閒錢',
      lot_spare: '太多閒錢',
      too_much: '用之不盡',

      cm150: '< 150cm(5ft)',
      cm151: '151cm-155cm(5ft-5,1ft)',
      cm156: '156cm-160cm(5,1ft-5,3ft)',
      cm161: '161cm-165cm(5,3ft-5,7ft)',
      cm166: '166cm-170cm(5,7ft-5,10ft)',
      cm171: '171cm-180cm(5,7ft-6ft)',
      cm181: '181cm-190cm(6ft-6,3ft)',
      cm191: '191cm-200cm(6,3ft-6,7ft)',
      cm200: '> 200cm(6ft,7)',

      athletic: '健壯',
      normal: '正常',
      other: '其他',
      stout: '肥胖',
      superfluous: '過剩',
      thin: '苗條',

      in19: '< 20英寸(51cm)',
      in20: '20英寸(51cm)-22英寸(56cm)',
      in23: '23英寸(58cm)-25英寸(64cm)',
      in26: '26英寸(66cm)-28英寸(71cm)',
      in29: '29英寸(74cm)-31英寸(79cm)',
      in32: '32英寸(81cm)-35英寸(89cm)',
      in36: '36英寸(91cm)-40英寸(102cm)',
      in41: '41英寸(104cm)-50英寸(127cm)',
      in51: '51英寸(130cm)-60英寸(152cm)',
      in60: '> 60英寸(152cm)',
      never: '從不',
      sometime: '偶爾因社交需要',
      usually: '經常',
      all_paid: '所有優質會員可以取得我的聯絡',
      only_premium: '只有富豪會員可獲得我的聯絡',
      no_one: '沒有人可以獲得我的聯絡',
      assume_all: '我承擔全部',
      split: '各自承擔',
      expect_other: '期望對方承擔',
      doesnt_matter: '沒所謂',
    },
    rate_level: {
      normal: '一般會員',
      verified: '認可女會員',
      premium: '認可優質女會員',
    },
    restricted: {
      normal: '公開',
      banned: '不顯示',
      millionbb_only: '只於MILLIONBB顯示',
      millionbb_only_pending: '只於MILLIONBB顯示 - 等候審批',
      banned_pending: '不顯示 - 等候審批',
    },
  },
  friend: {
    field: {
      id: 'ID',
      user: '用戶名',
      friend: '朋友用戶名',
      status: '狀態',
      date: '日期',
    },
  },
};
