import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Refresh } from '@material-ui/icons';
import { TicketStatusEnum, TicketTypeEnum } from 'millionbb-common';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  inline: {
    display: 'inline-block',
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: '80vh',
    minWidth: 360,
    overflowY: 'scroll',
    width: '100%',
  },
  paper: { padding: theme.spacing(3, 2) },
  pointer: {
    cursor: 'pointer',
  },
}));

interface ITicketListProps {
  loadConversation: (userId: number) => void;
  loading: any;
  error: any;
  data: any;
  fetchMore: any;
  refetch: () => void;
}

export const TicketList: FC<ITicketListProps> = props => {
  const { t } = useTranslation('ticket');
  const classes = useStyles({});
  const [currentPage, setCurrentPage] = useState(1);
  if (props.loading) {
    return <div />;
  }
  if (props.error) {
    return <div />;
  }
  const ticketsData = props.data.tickets.tickets;

  const userList: any[] = [];
  const sortedTicket = [
    ...ticketsData.filter(
      (ticketObject: any) =>
        ticketObject.status === TicketStatusEnum.Active && ticketObject.type === TicketTypeEnum.User
    ),
    ...ticketsData.filter(
      (ticketObject: any) =>
        ticketObject.status === TicketStatusEnum.Proceeded &&
        ticketObject.type === TicketTypeEnum.User
    ),
  ].filter(ticketObject => {
    let bool = false;
    if (userList.indexOf(ticketObject.user.userId) === -1) {
      userList.push(ticketObject.user.userId);
      bool = true;
    }
    return bool;
  });

  return (
    <Paper className={classes.paper}>
      <Typography>
        {t('menu:ticket')}
        <Refresh style={{ cursor: 'pointer', float: 'right' }} onClick={() => props.refetch()} />
      </Typography>
      <div style={{ margin: 5, display: 'inline-flex', alignItems: 'center' }}>
        <div
          style={{ backgroundColor: 'orange', borderRadius: 10, height: 10, margin: 2, width: 10 }}
        />
        Active
      </div>
      <div style={{ margin: 5, display: 'inline-flex', alignItems: 'center' }}>
        <div
          style={{ backgroundColor: 'grey', borderRadius: 10, height: 10, margin: 2, width: 10 }}
        />
        Proceeded
      </div>
      <List className={classes.list}>
        {sortedTicket.map((userObject, i) =>
          i >= currentPage * 10 - 10 && i < currentPage * 10 ? (
            <div key={userObject.user.userId}>
              <ListItem
                key={userObject.user.userId}
                className={classes.pointer}
                onClick={() => {
                  props.loadConversation(userObject.user.userId);
                }}>
                <ListItemAvatar>
                  <div
                    style={{
                      backgroundColor:
                        userObject.status === TicketStatusEnum.Active ? 'orange' : 'grey',
                      borderRadius: 10,
                      height: 10,
                      width: 10,
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={userObject.user.nickname}
                  secondary={new Date(parseInt(userObject.createDt, 10)).toLocaleString()}
                />
              </ListItem>
              <Divider light={true} />
            </div>
          ) : (
            <></>
          )
        )}
        {sortedTicket.length <= currentPage * 10 ? (
          <ListItem>
            <ListItemText style={{ textAlign: 'center' }} secondary={t('end')} />
          </ListItem>
        ) : (
          ''
        )}
      </List>
      <div style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}>
          {t('previous')}
        </Button>
        <Typography className={classes.inline}>{`${t('page')}: ${currentPage}`}</Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={sortedTicket.length <= currentPage * 10}
          onClick={() => {
            setCurrentPage(currentPage + 1);
            // if (sortedTicket.length <= currentPage * 10) @todo fetchMore
          }}>
          {t('next')}
        </Button>
      </div>
    </Paper>
  );
};

export default TicketList;
