import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Assignment,
  Lock,
  LockOpen,
  Search,
  TrendingUp,
  CreditCard,
  Refresh
} from "@material-ui/icons";
import { UserUpgradeDialog } from "components/dialogs/UserUpgradeDialog";
import { MBATable, Query } from "components/table/MBATable";
import {
  UserStatusEnum,
  UserVerifyEnum,
  OrderDirectionEnum,
  UserRoleEnum,
  RestrictedLevelEnum,
} from "millionbb-common";
import moment from "moment";
import { client, queries } from "ql";
import { useAdminPutUser } from "ql/hooks";
import React, { FC, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  action: {
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export const UserPage: FC = () => {
  const classes = useStyles({});
  const { t } = useTranslation("user");
  const [loading, setLoading] = useState(false);
  const [upgradeUserId, setUpgradeUserId] = useState<number | undefined>(
    undefined
  );
  const [filter, setFilter] = useState<{ [key: string]: any }>({
    nickname: "",
    email: "",
    phone: "",
    ageVerify: "",
    voice: "",
    role: null,
    status: null,
  });
  const [adminPutUser] = useAdminPutUser();
  let nickname = "";
  let email = "";
  let phone = "";
  const tableRef: any = useRef(null);

  return (
    <>
      <MBATable
        title={t("menu:user")}
        isLoading={loading}
        tableRef={tableRef}
        options={{ filtering: true, sorting: true }}
        actions={[
          {
            isFreeAction: true,
            icon: () => <Refresh />,
            onClick: () => tableRef.current.onQueryChange(),
          },
        ]}
        components={{
          FilterRow: ({ onFilterChanged }: any) => {
            return (
              <TableRow>
                <TableCell colSpan={3}>
                  <TextField
                    label={t("field.nickname")}
                    variant="outlined"
                    onChange={(e) => (nickname = e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        setFilter({ nickname });
                        onFilterChanged(1, nickname);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setFilter({ nickname });
                              onFilterChanged(1, nickname);
                            }}
                          >
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell colSpan={3}>
                  <TextField
                    label={t("field.email")}
                    variant="outlined"
                    onChange={(e) => {
                      email = e.target.value;
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        setFilter({ email });
                        onFilterChanged(2, email);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setFilter({ email });
                              onFilterChanged(2, email);
                            }}
                          >
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell colSpan={2}>
                  <TextField
                    label={t("field.phone")}
                    variant="outlined"
                    onChange={(e) => {
                      phone = e.target.value;
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        setFilter({ phone });
                        onFilterChanged(3, phone);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setFilter({ phone });
                              onFilterChanged(3, phone);
                            }}
                          >
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell colSpan={3}>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-voice-simple">
                      {t("field.role")}
                    </InputLabel>
                    <Select
                      className={classes.formControl}
                      value={filter.role}
                      onChange={(e) => {
                        const temp =
                          (e && e.target && e.target.value && e.target.value) ||
                          null;
                        setFilter({ role: temp });
                        onFilterChanged(3, filter.role);
                      }}
                      inputProps={{ name: "role", id: "outlined-role-simple" }}
                    >
                      <MenuItem value={null} style={{ color: "#000" }}>
                        <em>None</em>
                      </MenuItem>
                      {Object.values(UserRoleEnum).map((k) => (
                        <MenuItem value={k} style={{ color: "#000" }}>
                          {t(`role.${k.toLowerCase()}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell colSpan={1}>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-voice-simple">
                      {t("field.status")}
                    </InputLabel>
                    <Select
                      className={classes.formControl}
                      value={filter.status}
                      onChange={(e) => {
                        const temp =
                          (e && e.target && e.target.value && e.target.value) ||
                          null;
                        setFilter({ status: temp });
                        onFilterChanged(3, filter.status);
                      }}
                      inputProps={{
                        name: "status",
                        id: "outlined-role-simple",
                      }}
                    >
                      <MenuItem value={null} style={{ color: "#000" }}>
                        <em>None</em>
                      </MenuItem>
                      {Object.values(UserStatusEnum).map((k) => (
                        <MenuItem value={k} style={{ color: "#000" }}>
                          {t(`status.${k.toLowerCase()}`)}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value={UserStatusEnum.Banned}>
                        {UserStatusEnum.Banned.toString()}
                      </MenuItem>
                      <MenuItem value={UserStatusEnum.ProfileCompleted}>
                        {UserStatusEnum.ProfileCompleted.toString()}
                      </MenuItem> */}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell colSpan={1}>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-voice-simple">
                      {t("field.voice")}
                    </InputLabel>
                    <Select
                      className={classes.formControl}
                      value={filter.voice}
                      onChange={(e) => {
                        const temp =
                          (e && e.target && e.target.value && e.target.value) ||
                          "";
                        setFilter({ voice: temp });
                        onFilterChanged(3, filter.voice);
                      }}
                      inputProps={{
                        name: "voice",
                        id: "outlined-voice-simple",
                      }}
                    >
                      <MenuItem value={""} style={{ color: "#000" }}>
                        <em>None</em>
                      </MenuItem>
                      {Object.values(UserVerifyEnum).map((k) => (
                        <MenuItem value={k} style={{ color: "#000" }}>
                          {k.toString()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell colSpan={2}>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-age-simple">
                      {t("field.age")}
                    </InputLabel>
                    <Select
                      className={classes.formControl}
                      value={filter.ageVerify}
                      onChange={(e) => {
                        const temp =
                          (e && e.target && e.target.value && e.target.value) ||
                          "";
                        setFilter({ ageVerify: temp });
                        onFilterChanged(3, filter.ageVerify);
                      }}
                      inputProps={{
                        name: "ageVerify",
                        id: "outlined-age-simple",
                      }}
                    >
                      <MenuItem value={""} style={{ color: "#000" }}>
                        <em>None</em>
                      </MenuItem>
                      {Object.values(UserVerifyEnum).map((k) => (
                        <MenuItem value={k} style={{ color: "#000" }}>
                          {k.toString()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell />
              </TableRow>
            );
          },
        }}
        columns={[
          {
            title: "BAN",
            cellStyle: { wordBreak: "keep-all" },
            sorting: false,
            render: (user: any) =>
              user.status === UserStatusEnum.ProfileCompleted ? (
                <Lock
                  className={classes.action}
                  onClick={async () => {
                    if (window.confirm("確認封鎖用戶？")) {
                      await adminPutUser({
                        variables: {
                          input: {
                            userId: user.userId,
                            status: UserStatusEnum.Banned,
                          },
                        },
                        refetchQueries: [`searchUsers`],
                      });
                      alert("已封鎖用戶");
                      tableRef.current.onQueryChange();
                    }
                  }}
                ></Lock>
              ) : user.status === UserStatusEnum.Banned ? (
                <LockOpen
                  className={classes.action}
                  onClick={async () => {
                    if (window.confirm("確認解除封鎖用戶？")) {
                      await adminPutUser({
                        variables: {
                          input: {
                            userId: user.userId,
                            status: UserStatusEnum.ProfileCompleted,
                          },
                        },
                        refetchQueries: [`searchUsers`],
                      });
                      alert("已解封用戶");
                      tableRef.current.onQueryChange();
                    }
                  }}
                ></LockOpen>
              ) : null,
          },
          {
            title: <span style={{ wordBreak: "keep-all" }}>升級</span>,
            sorting: false,
            render: (user: any) =>
              user.status === UserStatusEnum.ProfileCompleted && (
                <TrendingUp
                  className={classes.action}
                  onClick={() => setUpgradeUserId(user.userId)}
                />
              ),
          },
          {
            title: (
              <span style={{ wordBreak: "keep-all" }}>
                {t("field.details")}
              </span>
            ),
            sorting: false,
            render: (user: any) => (
              <Link
                style={{ color: "black" }}
                to={`/admin/users/${user.userId}/details`}
              >
                <Assignment className={classes.action} />
              </Link>
            ),
          },
          {
            title: <span style={{ wordBreak: "keep-all" }}>付款記錄</span>,
            sorting: false,
            render: (user: any) => (
              <CreditCard
                className={classes.action}
                onClick={() =>
                  window.open(`/admin/payments/userId/${user.userId}`, "_blank")
                }
              />
            ),
          },

          {
            title: (
              <span style={{ wordBreak: "keep-all" }}>{t("field.id")}</span>
            ),
            field: "userId",
          },
          {
            title: (
              <span style={{ wordBreak: "keep-all" }}>
                {t("field.nickname")}
              </span>
            ),
            field: "nickname",
            render: ({ userId, nickname: n }: any) => (
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    `https://www.millionbb.com/users/${userId}/profile`
                  )
                }
              >
                {n}
              </span>
            ),
          },
          {
            title: (
              <span style={{ wordBreak: "keep-all" }}>{t("field.gender")}</span>
            ),
            field: "gender",
            render: (user: any) => user.gender.toUpperCase(),
          },
          {
            title: (
              <span style={{ wordBreak: "keep-all" }}>{t("field.email")}</span>
            ),
            field: "email",
            filtering: true,
          },
          {
            title: (
              <span style={{ wordBreak: "keep-all" }}>{t("field.phone")}</span>
            ),
            field: "phone",
            filtering: true,
          },
          {
            title: (
              <span style={{ wordBreak: "keep-all" }}>{t("field.role")}</span>
            ),
            field: "role",
            render: (user: any) => t(`role.${user.role.toLowerCase()}`),
          },
          {
            title: (
              <span style={{ wordBreak: "keep-all" }}>{t("field.credit")}</span>
            ),
            field: "credit",
            filtering: false,
          },
          {
            title: (
              <span style={{ wordBreak: "keep-all" }}>
                {t("field.create_dt")}
              </span>
            ),
            field: "create_dt",
            render: (user: any) =>
              moment.unix(user.createDt / 1000).format("YYYY-MM-DD"),
          },
          {
            title: (
              <span style={{ wordBreak: "keep-all" }}>{t("field.status")}</span>
            ),
            field: "status",
            sorting: false,
            render: (user: any) => t(`status.${user.status.toLowerCase()}`),
          },
          {
            title: (
              <span style={{ wordBreak: "keep-all" }}>{t("field.voice")}</span>
            ),
            field: "voice",
            sorting: false,
            render: (user: any) => {
              switch (user.voice) {
                case UserVerifyEnum.Empty:
                  return "-";
                case UserVerifyEnum.Accepted:
                  return (
                    <div style={{ color: "green" }}>
                      {UserVerifyEnum.Accepted}
                    </div>
                  );
                case UserVerifyEnum.Pending:
                  return (
                    <div style={{ color: "orange" }}>
                      {UserVerifyEnum.Pending}
                    </div>
                  );
                default:
                  return <div style={{ color: "red" }}>{user.voice}</div>;
              }
            },
          },
          {
            title: (
              <span style={{ wordBreak: "keep-all" }}>{t("field.age")}</span>
            ),
            field: "age_verify",
            sorting: false,
            render: (user: any) => {
              switch (user.ageVerify) {
                case UserVerifyEnum.Empty:
                  return "-";
                case UserVerifyEnum.Accepted:
                  return (
                    <div style={{ color: "green" }}>
                      {UserVerifyEnum.Accepted}
                    </div>
                  );
                case UserVerifyEnum.Pending:
                  return (
                    <div style={{ color: "orange" }}>
                      {UserVerifyEnum.Pending}
                    </div>
                  );
                default:
                  return <div style={{ color: "red" }}>{user.ageVerify}</div>;
              }
            },
          },
        ]}
        data={async ({
          page,
          pageSize,
          orderBy,
          orderDirection,
        }: Query<any>) => {
          setLoading(true);

          try {
            const { data, errors } = await client.query({
              query: queries.searchUsers,
              variables: {
                userSearchInput: {
                  ...filter,
                  page: page + 1,
                  pageSize,
                  order: orderBy ? orderBy.field : "verify",
                  orderDirection: orderDirection
                    ? orderDirection === "asc"
                      ? OrderDirectionEnum.Asc
                      : OrderDirectionEnum.Desc
                    : null,
                },
              },
            });

            setLoading(false);
            if (errors) {
              errors.find(
                (error) => error.extensions.code === "UNAUTHENTICATED"
              );
            }

            return {
              data: data.searchUsers && data.searchUsers.users,
              // data:
              //   data.searchUsers &&
              //   data.searchUsers.users.filter(
              //     ({ status }: any) =>
              //       status === UserStatusEnum.ProfileCompleted || status === UserStatusEnum.Banned
              //   ),
              page,
              totalCount: data.searchUsers && data.searchUsers.count,
            };
          } catch (error) {
            console.info(error);
          }

          return {
            data: [],
            page,
            totalCount: 0,
          };
        }}
      />

      <UserUpgradeDialog
        userId={upgradeUserId}
        onClose={() => {
          setUpgradeUserId(undefined);
          tableRef.current.onQueryChange();
        }}
      />
    </>
  );
};

export default UserPage;
