import React, { FC, useState, useRef } from "react";
import { queries, mutations } from "ql";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { MBATable, Query } from "components/table/MBATable";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";

import ConfirmDialogue from "./Upload/ConfirmDialogue";
import { WineReturnInput, WineReturnStatusEnum } from "millionbb-common";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  uploadThumbnail: {
    width: 100,
    height: 100,
    objectFit: "cover",
  },
}));

export const WineReturn: FC = () => {
  const tableRef: any = useRef(null);
  const classes = useStyles({});
  const { t } = useTranslation("wine");
  const [isDialogue, setDialogue] = useState(false);
  const [confirm, setConfirm] = useState<any>();
  const [action, setAction] = useState<any>();
  const [putWineReturns] = useMutation(mutations.adminPutWineReturn);
  const { data, loading, error, refetch } = useQuery(queries.wineReturns, {
    variables: { page: 1, pageSize: 20 } as WineReturnInput,
  });

  const handleCancel = () => {
    setDialogue(false);
  };
  if (loading || error) return null;
  return (
    <>
      <ConfirmDialogue
        isDialogue={isDialogue}
        setDialogue={setDialogue}
        confirm={confirm}
        handleCancel={handleCancel}
        action={action}
      />
      <MBATable
        title={t("menu:winereturn")}
        options={{ filtering: true }}
        tableRef={tableRef}
        columns={[
          { title: t("field.user"), field: "user.nickname", filtering: false },
          { title: t("field.quantity"), field: "emptyWine", filtering: false },
          { title: t("field.type"), field: "type", filtering: false },
          {
            title: t("field.date"),
            render: (wineReturns: any) => (
              <Typography>
                {new Date(parseInt(wineReturns.createDt, 10)).toLocaleString()}
              </Typography>
            ),
          },
          {
            title: t("field.status"),
            render: (wineReturns: any) => (
              <Typography
                style={{
                  color:
                    wineReturns.status === WineReturnStatusEnum.Accepted
                      ? "green"
                      : wineReturns.status === WineReturnStatusEnum.Rejected
                      ? "red"
                      : wineReturns.status === WineReturnStatusEnum.Pending
                      ? "orange"
                      : null,
                }}
              >
                {wineReturns.status}
              </Typography>
            ),
          },
          {
            title: t("field.manage"),
            render: (wineReturns: any) => (
              <>
                {wineReturns.status !== WineReturnStatusEnum.Accepted ? (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={async () => {
                      setDialogue(true);
                      setAction("Accept the transaction");
                      setConfirm({
                        handle: async () => {
                          const r = await putWineReturns({
                            variables: {
                              cryptocurrencyPurchaseInput: {
                                id: wineReturns.id,
                                userId: wineReturns.userId,
                                status: WineReturnStatusEnum.Accepted,
                                cryptocurrencyType: wineReturns.type,
                                quantity: wineReturns.emptyWine,
                                accountNumber: wineReturns.address,
                              },
                            },
                          });
                          if (r.data.putCryptocurrencyPurchase === "success") {
                            alert("sucess");
                            await refetch();
                            tableRef.current.onQueryChange();
                          } else {
                            alert("error");
                          }
                        },
                      });
                    }}
                  >
                    Accept
                  </Button>
                ) : null}
                {wineReturns.status !== WineReturnStatusEnum.Rejected ? (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={async () => {
                      setDialogue(true);
                      setAction("Reject the transaction");
                      setConfirm({
                        handle: async () => {
                          const r = await putWineReturns({
                            variables: {
                              cryptocurrencyPurchaseInput: {
                                id: wineReturns.id,
                                userId: wineReturns.userId,
                                status: WineReturnStatusEnum.Rejected,
                                cryptocurrencyType: wineReturns.type,
                                quantity: wineReturns.emptyWine,
                                accountNumber: wineReturns.address,
                              },
                            },
                          });
                          if (r.data.putCryptocurrencyPurchase === "success") {
                            alert("sucess");
                            await refetch();
                            tableRef.current.onQueryChange();
                          } else {
                            alert("error");
                          }
                        },
                      });
                    }}
                  >
                    Reject
                  </Button>
                ) : null}
              </>
            ),
          },
        ]}
        data={async ({ page, pageSize }: Query<any>) => {
          return {
            data: data.wineReturns.wineReturns,
            page: data.wineReturns.page - 1,
            totalCount: data.wineReturns.count,
          };
        }}
      />
    </>
  );
};
export default WineReturn;
