import gql from 'graphql-tag';

export const putSubscription = gql`
  mutation putSubscription($input: SubscriptionInput!) {
    putSubscription(input: $input) {
      subscriptionId
      user {
        userId
        nickname
        email
      }
      paymentType
      plan
      currency
      amount
      status
      nextChargeDt
      createDt
    }
  }
`;
