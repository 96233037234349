import gql from 'graphql-tag';

export const updateFile = gql`
  mutation fileUploads(
    $permission: PermissionEnum
    $isAdult: IsAdultEnum
    $status: FileStatusEnum!
    $userId: ID
    $fileId: ID!
  ) {
    putFile(
      input: {
        fileId: $fileId
        status: $status
        permission: $permission
        isAdult: $isAdult
        userId: $userId
      }
    ) {
      fileId
      filename
      status
      permission
      isAdult
    }
  }
`;
