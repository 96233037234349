import React, { FC, useState } from "react";
import { mutations } from "ql";

import { useMutation } from "@apollo/client";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import { TicketStatusEnum, TicketTypeEnum } from "millionbb-common";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1),
    width: "70%",
  },
}));
interface ITicketInputProps {
  userId: string;
  refetchConversation: () => void;
}
export const TicketInput: FC<ITicketInputProps> = (props) => {
  const classes = useStyles({});
  const [inputReply, setInputReply] = useState("");
  const [createNewTicket] = useMutation(mutations.putAdminTicket);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <TextField
        id="ticketReplyInput"
        className={classes.textField}
        margin="dense"
        hiddenLabel={true}
        variant="filled"
        placeholder="Reply To User"
        value={inputReply}
        onChange={(e) => setInputReply(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={false}
        onClick={async () => {
          await createNewTicket({
            variables: {
              description: inputReply,
              inputUserId: props.userId,
              status: TicketStatusEnum.Active,
              type: TicketTypeEnum.Admin,
            },
          });
          props.refetchConversation();
        }}
      >
        Send
      </Button>
    </div>
  );
};
export default TicketInput;
