import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  TableRow,
  TableCell,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import moment from "moment";

import { client, queries } from "ql";
import { RouteParams } from "conf/routes";
import { MBATable, Query } from "components/table/MBATable";
import { UserNickname } from "components/common/UserNickname";

export const PaymentPage = withRouter(
  ({ match, history }: RouteComponentProps<RouteParams>) => {
    const { t } = useTranslation("payment");
    const [loading, setLoading] = useState(false);

    let id = "";
    let nickname = "";
    let email = "";

    useEffect(() => {
      const { field, value } = match.params;

      if (field && !value) history.push("/admin/payments");
      if (field && !["id", "userId", "nickname", "email"].includes(field))
        history.push("/admin/payments");
    }, [history, match.params.field, match.params.value, match.params]);

    return (
      <>
        <MBATable
          title={t("menu:payment")}
          isLoading={loading}
          options={{ filtering: true }}
          columns={[
            { title: t("field.id"), field: "id", filtering: true },
            {
              title: t("field.user"),
              field: "username",
              render: ({ user }: any, FilterRow) => <UserNickname {...user} />,
              filtering: true,
            },
            {
              title: (
                <span style={{ wordBreak: "keep-all" }}>{t("field.type")}</span>
              ),
              field: "paymentType",
            },
            {
              title: (
                <span style={{ wordBreak: "keep-all" }}>
                  {t("field.currency")}
                </span>
              ),
              field: "currency",
            },
            {
              title: (
                <span style={{ wordBreak: "keep-all" }}>
                  {t("field.amount")}
                </span>
              ),
              field: "amount",
            },
            {
              title: (
                <span style={{ wordBreak: "keep-all" }}>
                  {t("field.log_type")}
                </span>
              ),
              render: (p: any) => t(`type.${p.logType.toLowerCase()}`),
            },
            {
              title: (
                <span style={{ wordBreak: "keep-all" }}>{t("field.data")}</span>
              ),
              field: "data",
              cellStyle: { overflow: "scroll", maxWidth: 200 },
            },
            {
              title: (
                <span style={{ wordBreak: "keep-all" }}>
                  {t("field.create_dt")}
                </span>
              ),
              render: (user: any) =>
                moment.unix(user.createDt / 1000).format("YYYY-MM-DD HH:mm"),
            },
            // { title: t('field.status'), render: user => t(`status.${user.status.toLowerCase()}`) },
          ]}
          data={async ({ page, pageSize }: Query<any>) => {
            setLoading(true);
            const { field, value } = match.params;

            const { data } = await client.query({
              query: queries.payments,
              variables: {
                paymentQueryInput: {
                  page: page + 1,
                  ...(!field ? {} : { [field]: value }),
                },
              },
            });

            setLoading(false);

            return {
              data: data && data.payments && data.payments.payments,
              page,
              totalCount: data && data.payments && data.payments.count,
            };
          }}
          components={{
            FilterRow: ({ columns, onFilterChanged }: any) => {
              return (
                <TableRow>
                  <TableCell colSpan={2}>
                    <TextField
                      variant="outlined"
                      label="id"
                      onChange={(e) => (id = e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && id) {
                          history.push(`/admin/payments/id/${id}`);
                          window.location.reload();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            onClick={() => {
                              id && history.push(`/admin/payments/id/${id}`);
                              window.location.reload();
                            }}
                          >
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField
                      variant="outlined"
                      label="匿稱"
                      onChange={(e) => (nickname = e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && nickname) {
                          history.push(`/admin/payments/nickname/${nickname}`);
                          window.location.reload();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            onClick={() => {
                              nickname &&
                                history.push(
                                  `/admin/payments/nickname/${nickname}`
                                );
                              window.location.reload();
                            }}
                          >
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField
                      variant="outlined"
                      label="電郵"
                      onChange={(e) => (email = e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && email) {
                          history.push(`/admin/payments/email/${email}`);
                          window.location.reload();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            onClick={() => {
                              email &&
                                history.push(`/admin/payments/email/${email}`);
                              window.location.reload();
                            }}
                          >
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                  </TableCell>
                  <TableCell colSpan={2}></TableCell>
                </TableRow>
              );
            },
          }}
        />
      </>
    );
  }
);
