import React, { createContext, useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Maybe } from 'millionbb-common';

import { RouteEnum } from 'conf/routes';

interface RouteContext extends RouteComponentProps {
  redirect: (path: RouteEnum) => void;
}

const RouterContext = createContext<Maybe<RouteContext>>(null);

export const RouterConsumer = RouterContext.Consumer;

export const RouterProvider = withRouter((props: RouteComponentProps) => {
  const { history, location, match, staticContext, ...rest } = props;
  const redirect = (path: RouteEnum) => history.push(path);

  return (
    <RouterContext.Provider
      value={{ history, location, match, staticContext, redirect }}
      {...rest}
    />
  );
});

export const useRouterContext = () => useContext<Maybe<RouteContext>>(RouterContext);
