import gql from 'graphql-tag';

export const UserFull = gql`
  fragment UserFull on User {
    idToken
    userId
    gender
    email
    phone
    nickname
    dob
    city
    logo
    ageMin
    ageMax
    whatsapp
    wechat
    telegram
    line
    aboutEconomy
    aboutHeight
    aboutBody
    aboutWaist
    aboutStyle
    aboutDrink
    aboutSmoke
    aboutMe
    dateExpense
    dateOutline
    contactPrivacy
    lang
    showAdult
    credit
    silverEndDt
    premiumEndDt
    role
    status
    millionbb
    princessDating
    lastSeen
    createDt
    lastModifyDt
    voice
    ageVerify
    restrictedLevel
  }
`;

export const UserShort = gql`
  fragment UserShort on User {
    idToken
    userId
    gender
    email
    phone
    nickname
    dob
    city
    logo
  }
`;
