import { Button } from "@material-ui/core";
import { UserNickname } from "components/common/UserNickname";
import { MBATable, Query } from "components/table/MBATable";
import {
  SubscriptionStatusEnum,
  useCancelSubscriptionMutation,
} from "features/generated-hooks";
import { client, queries } from "ql";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

export const Subscription = () => {
  const { t } = useTranslation("payment");
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [cancelSubscription] = useCancelSubscriptionMutation();

  return (
    <MBATable
      tableRef={tableRef}
      title="訂閱"
      isLoading={loading}
      columns={[
        { title: "id", field: "subscriptionId" },
        {
          title: t("field.user"),
          render: ({ user }: any, FilterRow) => <UserNickname {...user} />,
        },
        { title: "金額", field: "amount" },
        { title: "狀態", field: "status" },
        {
          title: "下次收費日期",
          render: (m: any) =>
            format(new Date(parseInt(m.nextChargeDt)), "yyyy-MM-dd"),
        },
        {
          title: "創建日期",
          render: (m: any) =>
            format(new Date(parseInt(m.nextChargeDt)), "yyyy-MM-dd"),
        },
        {
          title: "行動",
          render: ({ subscriptionId, status }) =>
            status === SubscriptionStatusEnum.Active && (
              <Button
                onClick={async () => {
                  if (window.confirm("確認取消訂閱")) {
                    const resp = await cancelSubscription({
                      variables: { id: subscriptionId },
                      refetchQueries: ["subscriptions"],
                    });

                    tableRef.current.onQueryChange();
                    window.alert("成功取消訂閱");
                  }
                }}
              >
                取消訂閱
              </Button>
            ),
        },
      ]}
      data={async ({ page, pageSize }: Query<any>) => {
        setLoading(true);
        const { data } = await client.query({
          query: queries.subscriptions,
          variables: { input: { page, pageSize } },
        });

        setLoading(false);
        return {
          data: data.subscriptions.subscriptions,
          page,
          totalCount: data.subscriptions.count,
        };
      }}
    />
  );
};
