import {
  Home,
  Payment,
  Person,
  AssignmentLate,
  FileCopy,
  ShoppingCart,
  AttachMoney,
  GroupRounded,
  Subscriptions,
} from "@material-ui/icons";

import * as Page from "components/pages";
import { IAnyRoute } from "types/props.d";

export enum RouteEnum {
  Home = "/",
  Login = "/admin/login",
  User = "/admin/users",
  Payment = "/admin/payments",
  PaymentFilter = "/admin/payments/:field/:value",
  Dummy = "/admin/dummy",
  Ticket = "/admin/ticket",
  Uploads = "/admin/uploads",
  UploadsFilter = "/admin/uploads/:field/:value",
  CreditLog = "/admin/creditlog",
  UserDetails = "/admin/users/:userId/details",
  WineReturn = "/admin/winereturn",
  Friend = "/admin/friend",
  Subscription = "/admin/subscriptions",
}
export interface RouteParams {
  userId?: string;
  field?: string;
  value?: string;
}
export const routes: IAnyRoute[] = [
  {
    path: RouteEnum.Home,
    inMenu: true,
    i18nKey: "home",
    Icon: Home,
    Component: Page.Dashboard,
  },
  {
    path: RouteEnum.User,
    inMenu: true,
    i18nKey: "user",
    Icon: Person,
    Component: Page.UserPage,
  },
  { path: RouteEnum.UserDetails, inMenu: false, Component: Page.UserDetails },
  {
    path: RouteEnum.Payment,
    inMenu: true,
    i18nKey: "payment",
    Icon: Payment,
    Component: Page.PaymentPage,
  },
  { path: RouteEnum.PaymentFilter, inMenu: false, Component: Page.PaymentPage },
  {
    path: RouteEnum.Ticket,
    inMenu: true,
    i18nKey: "ticket",
    Icon: AssignmentLate,
    Component: Page.Ticket,
  },
  {
    path: RouteEnum.Uploads,
    inMenu: true,
    i18nKey: "uploads",
    Icon: FileCopy,
    Component: Page.Uploads,
  },
  {
    path: RouteEnum.UploadsFilter,
    inMenu: false,
    Component: Page.Uploads,
  },
  {
    path: RouteEnum.CreditLog,
    inMenu: true,
    i18nKey: "creditlog",
    Icon: ShoppingCart,
    Component: Page.CreditLog,
  },
  {
    path: RouteEnum.WineReturn,
    inMenu: true,
    i18nKey: "winereturn",
    Icon: AttachMoney,
    Component: Page.WineReturn,
  },
  {
    path: RouteEnum.Friend,
    inMenu: true,
    i18nKey: "friend",
    Icon: GroupRounded,
    Component: Page.Friend,
  },
  {
    path: RouteEnum.Subscription,
    inMenu: true,
    i18nKey: "subscription",
    Icon: Subscriptions,
    Component: Page.Subscription,
  },
  // { path: RouteEnum.Dummy, inMenu: true, i18nKey: 'dummy', Icon: Remove, Component: Page.NotFound },
];

export default routes;
