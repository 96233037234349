import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Drawer, IconButton, List, Theme } from "@material-ui/core";
import { ChevronLeft, ChevronRight, Home, Input } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";

import { IMenuProps } from "types/props.d";
import { useUserContext } from "contexts/user";
import { MenuItem } from "./Menu.components";
import { useStyles } from "./Menu.styles";

export const Menu: FC<IMenuProps> = ({ routes }) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles({});
  const theme = useTheme<Theme>();

  const { t } = useTranslation("menu");

  const { onLogoutSuccess } = useUserContext();
  const onToggleClick = () => setOpen(!open);

  return (
    <Drawer
      open={open}
      variant="permanent"
      className={`${open ? classes.drawerOpen : classes.drawerClose}`}
      classes={{ paper: open ? classes.drawerOpen : classes.drawerClose }}
    >
      <div>
        {/* Menu Header */}
        <div
          className={classes.header}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {/* Menu Toggle Button */}
          {open ? (
            <>
              <img
                id="logo-large"
                alt="logo-large"
                src={`${require("assets/image/logo_white.png")}`}
                style={{ margin: 10, marginLeft: 0, maxHeight: 64 }}
              />
              <IconButton
                onClick={onToggleClick}
                style={{ position: "absolute", right: -10, top: 15 }}
              >
                <ChevronLeft
                  style={{ color: theme.palette.primary.contrastText }}
                />
              </IconButton>
            </>
          ) : (
            <>
              <img
                id="logo-small"
                alt="logo-small"
                src={`${require("assets/image/logo_white_s.png")}`}
                style={{ margin: 10, marginLeft: 10, maxHeight: 48 }}
              />
              <IconButton onClick={onToggleClick}>
                <ChevronRight
                  style={{ color: theme.palette.primary.contrastText }}
                />
              </IconButton>
            </>
          )}
        </div>
        <Divider style={{ background: theme.palette.primary.contrastText }} />
        {/* Menu Body */}
        <List>
          {routes
            .filter(({ inMenu }) => inMenu)
            .map(({ path, i18nKey = "", Icon = Home }) => (
              <MenuItem
                key={i18nKey}
                label={t(i18nKey)}
                route={path}
                icon={<Icon />}
              />
            ))}
        </List>
        <Divider style={{ background: theme.palette.primary.contrastText }} />
        <MenuItem
          label={t("logout")}
          icon={<Input />}
          onClick={() => {
            onLogoutSuccess();
          }}
        />
      </div>
    </Drawer>
  );
};

export default Menu;
