import React, { FC, useEffect, useMemo } from "react";
import * as Mui from "@material-ui/core";
import { VictoryChart, VictoryLine } from "victory";
import { PaymentLog, LogTypeEnum, User } from "millionbb-common";
import moment from "moment";
import { useSearchUsers } from "ql/hooks";
import { MBATable } from "components/table/MBATable";
import { UserNickname } from "components/common/UserNickname";

export const UserChart: FC = () => {
  const { data, fetchMore, variables } = useSearchUsers({
    variables: { userSearchInput: { page: 1, pageSize: 1000 } },
  });

  const chartData = useMemo(() => {
    const all: Record<number, number> = [],
      males: Record<number, number> = [],
      females: Record<number, number> = [];

    if (((data || {}).searchUsers || {}).users) {
      const now = moment().endOf("day");
      data.searchUsers.users.forEach((user: User) => {
        const { gender, createDt } = user;
        const dt = moment.unix(parseInt(createDt) / 1000);
        const days = now.diff(dt, "days");
        const i = 30 - days;

        if (i < 0) return;
        if (gender === "M") {
          all[i] = all[i] || 0 + 1;
          males[i] = males[i] || 0 + 1;
        } else if (gender === "F") {
          all[i] = all[i] || 0 + 1;
          females[i] = females[i] || 0 + 1;
        }
      });
    }

    const buildData = (ys: Record<number, number>) => {
      return new Array(30).fill(0).map((a, x) => ({ x, y: ys[x] || 0 }));
    };

    return {
      all: buildData(all),
      males: buildData(males),
      females: buildData(females),
    };
  }, [((data || {}).searchUsers || {}).users]);

  useEffect(() => {
    if (data) {
      // console.info('last user', data.searchUsers.users[data.searchUsers.users.length - 1]);
      if (
        data.searchUsers.users[data.searchUsers.users.length - 1].createDt >
          (Date.now() - 30 * 86400000).toString() &&
        data.searchUsers.users.length < data.searchUsers.count
      ) {
        const { pageSize } = variables.userSearchInput;
        fetchMore({
          variables: {
            userSearchInput: { page: data.searchUsers.page + 1, pageSize },
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const { users: prevUsers } = previousResult.searchUsers;
            const { users } = fetchMoreResult.searchUsers;

            const r = {
              searchUsers: {
                ...fetchMoreResult.searchUsers,
                users: [...prevUsers, ...users],
              },
            };

            return r;
          },
        })
          .then()
          .catch();
      }
    }
  }, [data, variables]);

  const getX = useMemo(() => {
    return (d: { x: number; y: number }) => {
      if (d.x % 2 !== 0) return " ".padEnd(d.x, " ");
      const dt = moment().add(d.x - 30, "d");
      if (dt.date() !== 1) return dt.date().toString();
      return dt.format("MMM\nD");
    };
  }, []);

  // console.info((data || {}).searchUsers, chartData);

  return (
    <Mui.Grid item xs={12} md={6}>
      <Mui.Typography variant="h5">新增用戶統計</Mui.Typography>
      <VictoryChart>
        {chartData && (
          <VictoryLine
            data={chartData.all}
            style={{ data: { stroke: "#000", strokeWidth: 2 } }}
            x={getX}
          />
        )}
        {chartData && (
          <VictoryLine
            data={chartData.males}
            style={{ data: { stroke: "blue", strokeWidth: 1 } }}
            x={getX}
          />
        )}
        {chartData && (
          <VictoryLine
            data={chartData.females}
            style={{ data: { stroke: "pink", strokeWidth: 1 } }}
            x={getX}
          />
        )}
      </VictoryChart>
    </Mui.Grid>
  );
};

export const PaymentChart: FC<{ payments: PaymentLog[] }> = ({
  payments = [],
}) => {
  const data = useMemo(() => {
    const all: Record<number, number> = [],
      init: Record<number, number> = [],
      rebill: Record<number, number> = [],
      credit: Record<number, number> = [],
      refund: Record<number, number> = [];

    const now = moment().endOf("day");

    payments.forEach((d) => {
      const { logType, amount, createDt } = d;
      const dt = moment.unix(parseInt(createDt) / 1000);
      const days = now.diff(dt, "days");
      const i = 30 - days;

      if (i < 0) return;
      if (logType === LogTypeEnum.SubscribeInitial) {
        all[i] = all[i] || 0 + amount;
        init[i] = init[i] || 0 + amount;
      } else if (logType === LogTypeEnum.SubscribeRebill) {
        all[i] = all[i] || 0 + amount;
        rebill[i] = rebill[i] || 0 + amount;
      } else if (logType === LogTypeEnum.PayResponse) {
        all[i] = all[i] || 0 + amount;
        credit[i] = credit[i] || 0 + amount;
      } else if (logType === LogTypeEnum.ChargeBack) {
        refund[i] = refund[i] || 0 + amount;
      }
    });

    // console.info('chart', all, rebill);
    const buildData = (ys: Record<number, number>) => {
      return new Array(30).fill(0).map((a, x) => ({ x, y: ys[x] || 0 }));
    };

    return {
      all: buildData(all),
      init: buildData(init),
      rebill: buildData(rebill),
      credit: buildData(credit),
      refund: buildData(refund),
    };
  }, [payments]);

  const getX = useMemo(() => {
    return (d: { x: number; y: number }) => {
      if (d.x % 2 !== 0) return " ".padEnd(d.x, " ");
      const dt = moment().add(d.x - 30, "d");
      if (dt.date() !== 1) return dt.date().toString();
      return dt.format("MMM\nD");
    };
  }, []);

  return (
    <Mui.Grid item xs={12} md={6}>
      <Mui.Typography variant="h5">付款記錄</Mui.Typography>
      {!data && <Mui.CircularProgress />}
      {data && (
        <VictoryChart>
          <VictoryLine
            data={data.all}
            style={{ data: { stroke: "#c43a31", strokeWidth: 2 } }}
            x={getX}
          />
          <VictoryLine
            data={data.init}
            style={{ data: { stroke: "#00F", strokeWidth: 1 } }}
            x={getX}
          />
          <VictoryLine
            data={data.rebill}
            style={{ data: { stroke: "#000", strokeWidth: 1 } }}
            x={getX}
          />
          <VictoryLine
            data={data.credit}
            style={{ data: { stroke: "#800", strokeWidth: 1 } }}
            x={getX}
          />
        </VictoryChart>
      )}
    </Mui.Grid>
  );
};

export const DuplicatedPaymentRecord: FC<{ payments: PaymentLog[] }> = ({
  payments = [],
}) => {
  const duplicated = useMemo(() => {
    const users: { [user: string]: PaymentLog } = {};
    const results: PaymentLog[] = [];

    const now = moment();

    payments.forEach((payment) => {
      const dt = moment.unix(parseInt(payment.createDt) / 1000);
      const days = now.diff(dt, "days");

      if (days > 30) return;
      if (users[payment.user.userId]) {
        results.push(users[payment.user.userId]);
        results.push(payment);
      }
    });

    return results;
  }, [payments]);

  return (
    <MBATable
      title="同月多次付款記錄"
      columns={[
        { title: "序號", field: "id", filtering: false, sorting: false },
        {
          title: "用戶",
          field: "username",
          render: ({ user }: any, FilterRow) => <UserNickname {...user} />,
          filtering: true,
        },
        { title: "金額", field: "amount" },
      ]}
      data={duplicated}
    />
  );
};
