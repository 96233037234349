import React, { forwardRef } from 'react';
import MaterialTable, { MaterialTableProps } from 'material-table';
import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  ChevronRight,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronLeft,
  Search,
  ArrowUpward,
  Remove,
  ViewColumn,
} from '@material-ui/icons';

const pageSize = 20;
const pageSizeOptions = [10, 20, 50];

export * from 'material-table';

export const MBATable = ({ icons, options = {}, ...p }: MaterialTableProps<any>) => {
  const tableIcons = {
    Add: forwardRef<any>((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef<any>((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef<any>((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef<any>((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef<any>((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef<any>((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef<any>((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef<any>((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef<any>((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef<any>((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef<any>((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef<any>((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef<any>((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef<any>((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef<any>((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef<any>((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef<any>((props, ref) => <ViewColumn {...props} ref={ref} />),
    ...icons,
  };

  return (
    <MaterialTable
      icons={tableIcons}
      options={{ pageSize, pageSizeOptions, search: false, ...options }}
      {...p}
    />
  );
};
