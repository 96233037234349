import gql from 'graphql-tag';

export const object = gql`
    query userObject ($userObjectQueryInput: UserObjectQueryInput!) {
        userObject(input: $userObjectQueryInput) {
            key
            data
        }
    }
`;
