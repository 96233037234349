import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { AUTH__HEADER_KEY } from "conf/app";
import { GRAPHQL_URL as uri } from "conf/env";
import errorLink from "./errorLink";

const authLink = setContext(() => {
  const authorization = window.localStorage.getItem(AUTH__HEADER_KEY);
  if (authorization) return { headers: { authorization } };
});

export default new ApolloClient<any>({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    errorLink.concat(authLink.concat(new HttpLink({ uri }))),
  ]),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});
