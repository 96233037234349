import React, { createContext, useContext, useState, FC } from 'react';
import { Maybe, Admin } from 'millionbb-common';

import { AUTH__HEADER_KEY } from 'conf/app';

export interface MBUserContext<T> {
  user: Maybe<T>;
  isLogin: boolean;
  loading: boolean;
  onLoginSuccess: (user: T) => void;
  onLogoutSuccess: () => void;
  onUserUpdate: (user: T) => void;
}

const UserContext = createContext<Maybe<MBUserContext<Admin>>>(null);

export const UserConsumer = UserContext.Consumer;

export const UserProvider: FC = props => {
  // const [user, setUser] = useState<Maybe<Admin>>(null);
  const [isLogin, setIsLogin] = useState(localStorage.getItem(AUTH__HEADER_KEY) ? true : false);
  const [loading] = useState(false);

  const onLoginSuccess = (loginResp: Admin) => {
    const idToken = loginResp.idToken;

    window.localStorage.setItem(AUTH__HEADER_KEY, idToken);
    setIsLogin(true);
  };

  const onLogoutSuccess = () => {
    window.localStorage.setItem(AUTH__HEADER_KEY, '');
    setIsLogin(false);
  };

  const onUserUpdate = (userResp: Admin) => {
    console.info(`user update`);
  };

  return (
    <UserContext.Provider
      value={{ user: null, isLogin, loading, onLoginSuccess, onLogoutSuccess, onUserUpdate }}
      {...props}
    />
  );
};

export const useUserContext = () => useContext<Maybe<MBUserContext<Admin>>>(UserContext);
