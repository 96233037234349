import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { ComponentType, FC } from 'react';
import { BrowserRouter, Redirect, Route, RouteProps, Switch } from 'react-router-dom';

import { routes, RouteEnum as R } from 'conf/routes';
// import { useAuthContext } from 'contexts/AuthContext';
import { useUserContext } from 'contexts/user';
import { RouterProvider } from 'contexts/router';

import Menu from 'components/Menu';
import Login from 'components/pages/Login';
import NotFound from 'components/pages/NotFound';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  root: {
    display: 'flex',
  },
}));

const ContainerComponent: FC<{}> = () => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Menu routes={routes} />
      <div className={classes.content}>
        {routes.map(({ path, Component }, i) => (
          <Route key={i} exact={true} path={path} component={Component} />
        ))}
      </div>
    </div>
  );
};

interface IAuthRouteProps extends RouteProps {
  component: ComponentType;
}

const AuthRoute: FC<IAuthRouteProps> = ({ component: Component, ...rest }) => {
  const { isLogin, loading } = useUserContext();

  const renderAuthRoute = () =>
    isLogin ? (
      <ContainerComponent>
        <Component {...rest} />
      </ContainerComponent>
    ) : (
      <Redirect to={R.Login} />
    );

  return loading === true ? null : <Route {...rest} render={renderAuthRoute} />;
};

const Router: FC<{}> = () => (
  <BrowserRouter>
    <RouterProvider>
      <Switch>
        <Route exact={true} path={R.Login} component={Login} />

        {routes.map(({ path, Component }, i) => (
          <AuthRoute key={i} exact={true} path={path} component={Component} />
        ))}

        <Route component={NotFound} />
      </Switch>
    </RouterProvider>
  </BrowserRouter>
);

export default Router;
