import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { RouteEnum } from 'conf/routes';

export const NotFound: FC<{}> = () => (
  <>
    <Typography variant="h1">Where am I?</Typography>
    <Link to={RouteEnum.Home}>
      <Typography>Back to right track</Typography>
    </Link>
  </>
);

export default NotFound;
