import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    // Affecting color of Drawer (components/Menu.tsx)
    // Check if two different instances of @material-ui/styles exist if not overriding
    // If yes reinstall with @material-ui/styles first then @material-ui/core
    MuiDivider: { root: { backgroundColor: '#fff' } },
    MuiDrawer: { paper: { background: 'rgb(234, 84, 58)', whiteSpace: 'nowrap', height: '100%' } },
    MuiInput: { root: { background: 'rgba(0,0,0,0.1)' }, underline: { borderBottom: 0 } },
    MuiListItem: { button: { color: '#fff' } },
    MuiListItemIcon: { root: { color: '#fff' } },
    //
  },
  palette: {
    // primary: deepOrange,
    primary: { light: '#fff', main: 'rgb(234, 84, 58)', contrastText: '#fff' },
    secondary: { light: '#fff', main: 'rgb(249, 221, 215)', contrastText: 'rgb(234, 84, 58)' },
    // background: { paper: '#F88', default: 'rgb(234, 84, 58)' },
  },
  typography: {
    // subheading: { color: '#fff' },
    // useNextVariants: true,
  },
});

// console.info(theme);
export default theme;
