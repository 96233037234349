import { ListItem, ListItemIcon, ListItemText, Theme } from '@material-ui/core';
import { ListItemProps } from '@material-ui/core/ListItem';
import { useTheme } from '@material-ui/styles';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { useRouterContext } from 'contexts/router';
import { IMenuItemProps } from 'types/props.d';

export const MenuItem: FC<IMenuItemProps & ListItemProps> = ({ label, icon, route, onClick }) => {
  const theme = useTheme<Theme>();
  const { location } = useRouterContext();

  // active color
  const color = theme.palette.secondary.contrastText;

  // active background color
  const backgroundColor = theme.palette.secondary.main;

  return route ? (
    <Link to={route}>
      <ListItem button={true} style={location.pathname === route ? { backgroundColor } : {}}>
        <ListItemIcon style={location.pathname === route ? { color } : {}}>{icon}</ListItemIcon>
        <ListItemText disableTypography={true} primary={label} />
      </ListItem>
    </Link>
  ) : (
    <ListItem onClick={onClick} style={location.pathname === route ? { backgroundColor } : {}}>
      <ListItemIcon style={location.pathname === route ? { color } : {}}>{icon}</ListItemIcon>
      <ListItemText disableTypography={true} primary={label} style={{ color: 'white' }} />
    </ListItem>
  );
};
