import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  IconButton,
  Button,
  Dialog,
  Grid,
  List,
  ListItem,
  makeStyles,
  Paper,
  TextField,
  Typography,
  TextareaAutosize,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CreditCard, PhotoAlbum } from "@material-ui/icons";
import {
  UserObjectKeyEnum,
  UserVerifyEnum,
  UserStatusEnum,
  RestrictedLevelEnum,
} from "millionbb-common";
import { RouteParams } from "conf/routes";
import { findCity, getUserFolder } from "helpers/millionbb";
import { queries, useQuery, useMutation, mutations } from "ql";
import { useAdminUserUpgrade, useAdminPutMessage } from "ql/hooks";
import { LogoField } from "./UserDetails/UserDetails.fields";

const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1) },
  field: { display: "flex", alignItems: "center" },
  inline: { display: "inline-block" },
  list: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: "80vh",
    minWidth: 360,
    overflowY: "scroll",
    width: "100%",
  },
  paper: { padding: theme.spacing(3, 2) },
  pointer: { cursor: "pointer" },
  formControl: { margin: theme.spacing(1), minWidth: 120 },
}));

export const UserDetails = withRouter(
  ({ match, location }: RouteComponentProps<RouteParams>) => {
    const userId = match.params.userId;
    const { t } = useTranslation("user");
    const classes = useStyles({});
    const [isDialogue, setDialogue] = useState(false);
    const [confirm, setConfirm] = useState({
      handle: () => {
        return;
      },
    });
    const [message, setMessage] = useState("");
    const [reason, setReason] = useState("");
    const [userData, setUserData] = useState<{ [key: string]: any }>({});

    const [rejectReason, setRejectReason] = useState({ voice: "", age: "" });

    const [userPutVerify] = useMutation(mutations.userPutVerify);
    const [adminPutUserData] = useMutation(mutations.adminPutUserData);
    const [adminUserUpgrade] = useAdminUserUpgrade();
    const [adminPutMessage] = useAdminPutMessage();

    const { loading, error, data, refetch } = useQuery(queries.user, {
      variables: { singleUserInput: { userId: userId } },
    });

    const {
      loading: loadingOfAgeImage,
      error: errorOfAgeImage,
      data: dataOfAgeImage,
      refetch: refetchOfAgeImage,
    } = useQuery(queries.object, {
      variables: {
        userObjectQueryInput: {
          userId: userId,
          key: UserObjectKeyEnum.AgeVerify,
        },
      },
    });

    const handleClose = async (input: any) => {
      const result = await userPutVerify({
        variables: { userPutVerifyInput: input },
      });
      if (result.errors) {
        alert("Failed");
      } else {
        alert("Success");
      }

      await refetch();
      await refetchOfAgeImage();
      setDialogue(false);
    };

    const handleAdminUserUpgrade = async (input: {
      userId: string;
      frdQuota?: number;
      msgQuota?: number;
      wine?: number;
      emptyWine?: number;
      credit?: number;
      creditSpent?: number;
    }) => {
      const result = await adminUserUpgrade({
        variables: { adminUpgradeUserInput: input },
      });
      if (result.errors) {
        alert("Failed");
      } else {
        alert("Success");
      }
      setDialogue(false);
      await refetch();
    };

    const handleAdminPutMessage = async () => {
      if (message && message !== "") {
        try {
          const r = await adminPutMessage({
            variables: {
              input: {
                message,
                recipant: userId,
              },
            },
          });

          if (r) {
            setMessage("");
            alert("Message sent");
          }
        } catch (err) {
          alert(err);
        }
      } else return "Please enter message content";
    };

    const handleAdminPutData = async (input: {
      userId: string;
      dob?: string;
      aboutMe?: string;
      dateOutline?: string;
      gender?: string;
      password?: string;
      telegram?: string;
      line?: string;
      whatsapp?: string;
      wechat?: string;
      status?: UserStatusEnum;
      restrictedLevel?: RestrictedLevelEnum;
      reason?: string;
      logo?: string;
      logoHalf?: string;
      logoFull?: string;
      millionbb?: boolean;
      princessDating?: boolean;
    }) => {
      const result = await adminPutUserData({
        variables: input,
      });
      if (result.errors) {
        alert("Failed");
      } else {
        alert("Success");
      }
      setDialogue(false);
      await refetch();
    };

    if (loading || loadingOfAgeImage) {
      return <div>Loading</div>;
    }

    if (error) {
      return <div>Error</div>;
    }

    const listItem = [
      { name: t("field.nickname"), value: data.user.nickname },
      {
        name: t("field.album"),
        value: (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                window.open(`/admin/uploads/userId/${userId}`, "_blank")
              }
            >
              <PhotoAlbum />
              {t("field.album")}
            </Button>
          </div>
        ),
      },
      {
        name: t("field.message"),
        value: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              placeholder={t("field.message")}
              variant="outlined"
              value={message}
              style={{ minWidth: 300, fontSize: 15 }}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ margin: 4 }}
              onClick={() => handleAdminPutMessage()}
            >
              {t("field.send")}
            </Button>
          </div>
        ),
      },
      {
        name: t("field.role"),
        value: data.user.role ? t(`role.${data.user.role.toLowerCase()}`) : "-",
      },
      {
        name: t("field.voice"),
        value: (
          <div style={{ display: "inline-flex", flexDirection: "column" }}>
            {t("field.status")}:{data.user.voice}
            <br />
            {data.user.voice === "PENDING" ||
            data.user.voice === "REJECTED" ||
            data.user.voice === "ACCEPTED" ? (
              <>
                <br />
                <audio controls className="player" preload="false">
                  <source
                    src={`${getUserFolder(data.user.userId)}voice_pending.wav`}
                  />
                </audio>
              </>
            ) : (
              <></>
            )}
            <div>
              {data.user.voice === "PENDING" ||
              data.user.voice === "REJECTED" ? (
                <>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={async () => {
                      setConfirm({
                        handle: () =>
                          handleClose({
                            userId: userId,
                            voice: UserVerifyEnum.Accepted,
                            ageVerify: null,
                            reason: "",
                          }),
                      });
                      setDialogue(true);
                    }}
                  >
                    {t("field.approve")}
                  </Button>
                </>
              ) : (
                <></>
              )}
              {data.user.voice === "PENDING" ? (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={async () => {
                      if (
                        rejectReason.voice === "" ||
                        rejectReason.voice === null
                      ) {
                        alert("Please provide a reason for rejection");
                        return;
                      }
                      setConfirm({
                        handle: () =>
                          handleClose({
                            userId: userId,
                            voice: UserVerifyEnum.Rejected,
                            ageVerify: null,
                            reason: rejectReason.voice,
                          }),
                      });
                      setDialogue(true);
                    }}
                  >
                    {t("field.reject")}
                  </Button>
                  <TextField
                    placeholder={
                      t("common:term.reject") + t("common:term.reason")
                    }
                    variant="outlined"
                    style={{ minWidth: 100, fontSize: 15 }}
                    onChange={(e) => {
                      setRejectReason({
                        ...rejectReason,
                        voice: e.target.value,
                      });
                    }}
                  />
                </>
              ) : (
                <></>
              )}
              {data.user.voice === "ACCEPTED" ? (
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={async () => {
                    setConfirm({
                      handle: () =>
                        handleClose({
                          userId: userId,
                          voice: UserVerifyEnum.RemovedByAdmin,
                          ageVerify: null,
                          reason: "",
                        }),
                    });
                    setDialogue(true);
                  }}
                >
                  {t("field.remove")}
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
        ),
      },
      {
        name: t("field.age"),
        value: (
          <div style={{ display: "inline-flex", flexDirection: "column" }}>
            {t("field.status")}:{data.user.ageVerify}
            <br />
            {!errorOfAgeImage &&
            dataOfAgeImage &&
            dataOfAgeImage.userObject &&
            dataOfAgeImage.userObject.data &&
            (data.user.ageVerify === "PENDING" ||
              data.user.ageVerify === "REJECTED" ||
              data.user.ageVerify === "ACCEPTED") ? (
              <>
                <br />
                <img
                  src={dataOfAgeImage.userObject.data}
                  alt="age-verify"
                  onClick={() => {
                    const image = new Image();
                    image.src = dataOfAgeImage.userObject.data;
                    const w = window.open("");
                    w.document.write(image.outerHTML);
                  }}
                  style={{
                    width: 200,
                    height: 200,
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                ></img>
              </>
            ) : (
              <></>
            )}
            <div>
              {data.user.ageVerify !== UserVerifyEnum.Accepted ? (
                <>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={async () => {
                      setConfirm({
                        handle: () =>
                          handleClose({
                            userId: userId,
                            voice: null,
                            ageVerify: UserVerifyEnum.Accepted,
                            reason: "",
                          }),
                      });
                      setDialogue(true);
                    }}
                  >
                    {t("field.approve")}
                  </Button>
                </>
              ) : (
                <></>
              )}
              {!(
                data.user.ageVerify === UserVerifyEnum.Accepted ||
                data.user.ageVerify === UserVerifyEnum.Rejected
              ) ? (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={async () => {
                      if (
                        rejectReason.age === "" ||
                        rejectReason.age === null
                      ) {
                        alert("Please provide a reason for rejection");
                        return;
                      }
                      setConfirm({
                        handle: () =>
                          handleClose({
                            userId: userId,
                            voice: null,
                            ageVerify: UserVerifyEnum.Rejected,
                            reason: rejectReason.age,
                          }),
                      });
                      setDialogue(true);
                    }}
                  >
                    {t("field.reject")}
                  </Button>
                  <TextField
                    placeholder={
                      t("common:term.reject") + t("common:term.reason")
                    }
                    variant="outlined"
                    style={{ minWidth: 100, fontSize: 15 }}
                    onChange={(e) => {
                      setRejectReason({
                        ...rejectReason,
                        age: e.target.value,
                      });
                    }}
                  />
                </>
              ) : (
                <></>
              )}
              {data.user.ageVerify === "ACCEPTED" ? (
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={async () => {
                    setConfirm({
                      handle: () =>
                        handleClose({
                          userId: userId,
                          voice: null,
                          ageVerify: UserVerifyEnum.RemovedByAdmin,
                          reason: "",
                        }),
                    });
                    setDialogue(true);
                  }}
                >
                  {t("field.remove")}
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
        ),
      },
      {
        name: t("field.logo"),
        value: (
          <LogoField
            user={data.user}
            logo={data.user.logo}
            onDelete={() => {
              setConfirm({
                handle: async () =>
                  handleAdminPutData({
                    userId: userId,
                    logo: "",
                  }),
              });
              setDialogue(true);
            }}
          />
        ),
      },
      {
        name: t("field.logo_half"),
        value: (
          <LogoField
            user={data.user}
            logo={data.user.logoHalf}
            onDelete={() => {
              setConfirm({
                handle: async () =>
                  handleAdminPutData({
                    userId: userId,
                    logoHalf: "",
                  }),
              });
              setDialogue(true);
            }}
          />
        ),
      },
      {
        name: t("field.logo_full"),
        value: (
          <LogoField
            user={data.user}
            logo={data.user.logoFull}
            onDelete={() => {
              setConfirm({
                handle: async () =>
                  handleAdminPutData({
                    userId: userId,
                    logoFull: "",
                  }),
              });
              setDialogue(true);
            }}
          />
        ),
      },
      {
        name: t("field.gender"),
        value: (
          <>
            {data.user.gender}
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                setConfirm({
                  handle: async () =>
                    handleAdminPutData({
                      userId: userId,
                      gender: data.user.gender === "M" ? "F" : "M",
                    }),
                });
                setDialogue(true);
              }}
            >
              更改
            </Button>
          </>
        ),
      },
      {
        name: t("field.dob"),
        value: (
          <div className={classes.field}>
            <TextField
              type="date"
              variant="outlined"
              defaultValue={data.user.dob}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  dob: e.target.value,
                });
              }}
            />
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => {
                setConfirm({
                  handle: async () =>
                    handleAdminPutData({ userId: userId, dob: userData.dob }),
                });
                setDialogue(true);
              }}
            >
              更新
            </Button>
          </div>
        ),
      },
      {
        name: t("field.aboutMe"),
        value: (
          <div className={classes.field}>
            <TextareaAutosize
              rows={3}
              defaultValue={data.user.aboutMe}
              style={{ minWidth: 100, fontSize: 15 }}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  aboutMe: e.target.value,
                });
              }}
            />
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => {
                setConfirm({
                  handle: async () =>
                    handleAdminPutData({
                      userId: userId,
                      aboutMe: userData.aboutMe,
                    }),
                });
                setDialogue(true);
              }}
            >
              更新
            </Button>
          </div>
        ),
      },
      {
        name: t("field.dateOutline"),
        value: (
          <div className={classes.field}>
            <TextareaAutosize
              rows={3}
              defaultValue={data.user.dateOutline}
              style={{ minWidth: 100, fontSize: 15 }}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  dateOutline: e.target.value,
                });
              }}
            />
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => {
                setConfirm({
                  handle: async () =>
                    handleAdminPutData({
                      userId: userId,
                      dateOutline: userData.dateOutline,
                    }),
                });
                setDialogue(true);
              }}
            >
              更新
            </Button>
          </div>
        ),
      },
      {
        name: t("field.city"),
        value: findCity(data.user.city),
      },
      {
        name: t("field.status"),
        value: (
          <div className={classes.field}>
            <Typography>
              {t(`status.${data.user.status.toLowerCase()}`)}
            </Typography>
            <Button
              className={classes.button}
              disabled={
                data.user.status === UserStatusEnum.NotVerify ? false : true
              }
              variant="contained"
              onClick={() => {
                // data.user[field]
                setConfirm({
                  handle: async () => {
                    await handleAdminPutData({
                      userId: userId,
                      status: UserStatusEnum.Verified,
                    });
                  },
                });
                setDialogue(true);
              }}
            >
              更新為已驗證會員
            </Button>
          </div>
        ),
        // value: t(`status.${data.user.status.toLowerCase()}`),
      },
      {
        name: t("field.restricted"),
        value: (
          <div className={classes.field}>
            <Typography>
              {t(`restricted.${data.user.restrictedLevel.toLowerCase()}`)}
            </Typography>
            <TextField
              type="text"
              variant="outlined"
              placeholder={t("common:term.reject") + t("common:term.reason")}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
            <Button
              className={classes.button}
              variant="contained"
              disabled={
                data.user.restrictedLevel === RestrictedLevelEnum.Normal
              }
              onClick={() => {
                // data.user[field]
                setConfirm({
                  handle: async () => {
                    await handleAdminPutData({
                      userId: userId,
                      restrictedLevel: RestrictedLevelEnum.Normal,
                      reason,
                    });
                  },
                });
                setDialogue(true);
              }}
            >
              {t("common:action.modify") + t("restricted.normal")}
            </Button>
            <Button
              className={classes.button}
              disabled={!reason ? true : false}
              variant="contained"
              onClick={() => {
                // data.user[field]
                setConfirm({
                  handle: async () => {
                    await handleAdminPutData({
                      userId: userId,
                      restrictedLevel: RestrictedLevelEnum.MillionbbOnly,
                      reason,
                    });
                  },
                });
                setDialogue(true);
              }}
            >
              {t("common:action.modify") + t("restricted.millionbb_only")}
            </Button>
            <Button
              className={classes.button}
              disabled={!reason ? true : false}
              variant="contained"
              onClick={() => {
                // data.user[field]
                setConfirm({
                  handle: async () => {
                    await handleAdminPutData({
                      userId: userId,
                      restrictedLevel: RestrictedLevelEnum.Banned,
                      reason,
                    });
                  },
                });
                setDialogue(true);
              }}
            >
              {t("common:action.modify") + t("restricted.banned")}
            </Button>
          </div>
        ),
        // value: t(`status.${data.user.status.toLowerCase()}`),
      },
      {
        name: t("field.is_millionbb"),
        value: (
          <div>
            {data.user.millionbb
              ? t("status.activated")
              : t("status.not_activated")}
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => {
                // data.user[field]
                setConfirm({
                  handle: async () => {
                    await handleAdminPutData({
                      userId: userId,
                      millionbb: !data.user.millionbb,
                    });
                  },
                });
                setDialogue(true);
              }}
            >
              {data.user.millionbb
                ? `${t("common:action.modify")}${t("status.not_activated")}`
                : `${t("common:action.modify")}${t("status.activated")}`}
            </Button>
          </div>
        ),
      },
      {
        name: t("field.is_princess_dating"),
        value: (
          <div>
            {data.user.princessDating
              ? t("status.activated")
              : t("status.not_activated")}

            <Button
              className={classes.button}
              variant="contained"
              onClick={() => {
                setConfirm({
                  handle: async () => {
                    await handleAdminPutData({
                      userId: userId,
                      princessDating: !data.user.princessDating,
                    });
                  },
                });
                setDialogue(true);
              }}
            >
              {data.user.princessDating
                ? `${t("common:action.modify")}${t("status.not_activated")}`
                : `${t("common:action.modify")}${t("status.activated")}`}
            </Button>
          </div>
        ),
      },
      {
        name: t("field.rateLevel"),
        value:
          data.user.gender === "F"
            ? t(`rate_level.${data.user.rateLevel.toLowerCase()}`)
            : t("rate_level.normal"),
      },
    ];

    const addAboutDisplayFields = [
      "aboutBody",
      "aboutDrink",
      "aboutEconomy",
      "aboutHeight",
      "aboutSmoke",
      "aboutWaist",
      "contactPrivacy",
      "dateExpense",
    ];

    const addInfoFields = [
      "vip",
      "email",
      "phone",
      "ageMin",
      "ageMax",
      "referrer",
      "referralCode",
    ];

    const addControlFields = [
      "emptyWine",
      "wine",
      "credit",
      "creditSpent",
      "frdQuota",
      "msgQuota",
    ];
    const addDateFields = ["lastSeen", "premiumEndDt", "silverEndDt"];
    const addInputFields = ["line", "telegram", "wechat", "whatsapp"];

    addDateFields.forEach((field) => {
      listItem.push({
        name: t(`field.${field}`),
        value: data.user[field]
          ? new Date(parseInt(data.user[field], 10)).toLocaleDateString()
          : "-",
      });
    });

    addInfoFields.forEach((field) => {
      listItem.push({
        name: t(`field.${field}`),
        value: data.user[field]
          ? data.user[field]
          : data.user[field] === 0
          ? "0"
          : "-",
      });
    });

    addAboutDisplayFields.forEach((field) => {
      listItem.push({
        name: t(`field.${field}`),
        value: data.user[field]
          ? t(`about.${data.user[field].toLowerCase()}`)
          : "-",
      });
    });

    addControlFields.forEach((field) => {
      listItem.push({
        name: t(`field.${field}`),
        value: (
          <div className={classes.field}>
            <Typography style={{ minWidth: 100 }}>
              {data.user[field]}
            </Typography>
            <TextField
              variant="outlined"
              onChange={(e) => {
                setUserData({
                  ...userData,
                  [field]: e.target.value,
                });
              }}
            />
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => {
                if (
                  /^[0-9]{1,}$/.test(userData[field]) === false ||
                  userData[field].length > 9
                ) {
                  alert("Invalid Quota");
                  return;
                }
                // data.user[field]
                setConfirm({
                  handle: async () => {
                    const value =
                      parseInt(userData[field], 10) - data.user[field];
                    handleAdminUserUpgrade({ userId: userId, [field]: value });
                  },
                });
                setDialogue(true);
              }}
            >
              更新
            </Button>
            {/* <Button
            className={classes.button}
            variant="contained"
            onClick={() => {
              if (/^[0-9]{1,}$/.test(userData[field]) === false || userData[field].length > 9) {
                alert('Invalid Quota');
                return;
              }
              if (data.user[field] - userData[field] < 0) {
                alert(`${t(`field.${field}`)} 不能少於 0`);
                return;
              }
              setConfirm({
                handle: async () =>
                  handleAdminUserUpgrade({
                    userId: userId,
                    [field]: -parseInt(userData[field], 10),
                  }),
              });
              setDialogue(true);
            }}>
            -
          </Button> */}
          </div>
        ),
      });
    });

    addInputFields.forEach((field) => {
      listItem.push({
        name: t(`field.${field}`),
        value: (
          <div className={classes.field}>
            <TextField
              variant="outlined"
              defaultValue={data.user[field]}
              onChange={(e) => {
                setUserData({ ...userData, [field]: e.target.value });
              }}
            />
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => {
                if (userData[field] && userData[field].length > 30) {
                  alert("Invalid Input");
                  return;
                }
                setConfirm({
                  handle: async () =>
                    handleAdminPutData({
                      userId: userId,
                      [field]: userData[field],
                    }),
                });
                setDialogue(true);
              }}
            >
              更新
            </Button>
          </div>
        ),
      });
    });

    return (
      <>
        <Dialog open={isDialogue} onClose={() => setDialogue(false)}>
          <DialogTitle>{t("field.confirm_dialog_title")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("field.confirm_dialog_content_text")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogue(false)} color="primary">
              {t("field.cancel")}
            </Button>
            <Button onClick={confirm.handle} color="primary" autoFocus>
              {t("field.confirm")}
            </Button>
          </DialogActions>
        </Dialog>

        <Paper className={classes.paper}>
          <Typography>{t("field.title")}</Typography>
          <List>
            {listItem.map(({ name, value }, i) => (
              <ListItem key={i} divider={true}>
                <Grid item xs={value ? 4 : 12}>
                  {name}
                </Grid>
                {value && (
                  <Grid item xs={8}>
                    {value}
                  </Grid>
                )}
              </ListItem>
            ))}
            <ListItem>
              <Grid item xs={4}>
                更新密碼：
              </Grid>
              <TextField
                type="password"
                variant="outlined"
                defaultValue={data.user.password}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    password: e.target.value,
                  });
                }}
              />

              <Button
                className={classes.button}
                variant="contained"
                onClick={() => {
                  if (userData.password === "" || userData.password === null) {
                    alert("Password not valid");
                    return;
                  }
                  setConfirm({
                    handle: async () => {
                      await handleAdminPutData({
                        userId: userId,
                        password: userData.password,
                      });
                    },
                  });
                  setDialogue(true);
                }}
              >
                更新
              </Button>
            </ListItem>
            <ListItem>
              <Grid item xs={4}>
                付款記錄
              </Grid>
              <IconButton
                onClick={() =>
                  window.open(`/admin/payments/userId/${userId}`, "_blank")
                }
              >
                <CreditCard />
              </IconButton>
            </ListItem>
          </List>
        </Paper>
      </>
    );
  }
);

export default UserDetails;
