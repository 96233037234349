import gql from 'graphql-tag';

export const adminLogin = gql`
  mutation adminLogin($adminLoginInput: AdminLoginInput!) {
    adminLogin(input: $adminLoginInput) {
      idToken
      adminId
      username
      email
    }
  }
`;
