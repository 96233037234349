import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TableCell, TextField, TableRow } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import { client, queries } from 'ql';
import { MBATable, Query } from 'components/table/MBATable';
import { UserNickname } from 'components/common/UserNickname';

enum CreditLogFilterTypeEnum {
  Username = 1,
  Currency = 2,
  Action = 3,
}

export const CreditLog: FC = () => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    filter: '',
    value: '',
  });
  const { t } = useTranslation('creditlog');
  let temp = '';
  return (
    <MBATable
      title={t('menu:creditlog')}
      options={{ filtering: true }}
      isLoading={loading}
      columns={[
        { title: t('field.id'), field: 'id', filtering: false },
        {
          title: t('field.user'),
          field: 'username',
          filtering: true,
          render: ({ user }: any, FilterRow) => <UserNickname {...user} />,
        },
        { title: t('field.currency'), field: 'currency', filtering: true },
        { title: '數量', field: 'amount' },
        { title: t('field.action'), field: 'action', filtering: true },
        { title: t('field.date'), field: 'createDt', filtering: false },
      ]}
      data={async ({ page, pageSize }: Query<any>) => {
        setLoading(true);
        const { data } = await client.query({
          query: queries.getCreditLog,
          variables: {
            ...(filter.filter !== '' ? { [filter.filter]: filter.value } : {}),
            page: page + 1,
            pageSize,
          },
        });
        for (const dataObject of data.creditLogs.creditLogs) {
          dataObject.createDt = new Date(parseInt(dataObject.createDt, 10)).toLocaleString();
        }
        setLoading(false);
        return {
          data: data.creditLogs.creditLogs,
          page,
          totalCount: data.creditLogs.count,
        };
      }}
      components={{
        FilterRow: ({ columns, onFilterChanged }: any) => {
          return (
            <>
              <TableRow>
                {columns.map(({ field, filtering, tableData }: any) => (
                  <TableCell key={tableData.id}>
                    {field && filtering && (
                      <TextField
                        variant="outlined"
                        onChange={(e: any) => {
                          temp = e.target.value;
                        }}
                        onKeyPress={(e: any) => {
                          if (e.key === 'Enter') {
                            setFilter({
                              ...(tableData.id === CreditLogFilterTypeEnum.Username
                                ? {
                                    filter: 'username',
                                    value: temp,
                                  }
                                : tableData.id === CreditLogFilterTypeEnum.Currency
                                ? {
                                    filter: 'currency',
                                    value: temp,
                                  }
                                : {
                                    filter: 'action',
                                    value: temp,
                                  }),
                            });
                            onFilterChanged(tableData.id, temp);
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </>
          );
        },
      }}
    />
  );
};

export default CreditLog;
