import gql from 'graphql-tag';
export const getCreditLog = gql`
  query creditLogs(
    $page: Int!
    $pageSize: Int!
    $id: ID
    $username: String
    $currency: String
    $action: String
  ) {
    creditLogs(
      input: {
        page: $page
        pageSize: $pageSize
        id: $id
        username: $username
        currency: $currency
        action: $action
      }
    ) {
      creditLogs {
        id
        userId
        currency
        amount
        action
        createDt
        lastModifyDt
        user {
          userId
          nickname
        }
      }
      page
      pageSize
      count
    }
  }
`;
