import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import {
  Container,
  Grid,
  Hidden,
  Theme,
  Paper,
  Button,
  Input,
  TextField,
} from "@material-ui/core";

import LinkIcon from "@material-ui/icons/Link";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import { makeStyles } from "@material-ui/styles";

import { RouteEnum } from "conf/routes";
import Form from "components/form";
import { FormFieldType, IFormAction, IFormField } from "types/form.d";
import { useUserContext } from "contexts/user";
import { useAdminLogin } from "ql/hooks";

const useStyles = makeStyles((theme: Theme) => ({
  grid: { background: theme.palette.primary.light },
  paper: { background: "rgba(0,0,0,0)", margin: "auto" },

  root: {
    background: theme.palette.primary.main,
    minHeight: "100vh",
    overFlow: "scroll",
    display: "flex",
    alignItems: "center",
  },
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    overFlowY: "scroll",
    marginTop: 100,
    marginBottom: 100,
  },
  gridPaper: {
    borderRadius: 5,
    backgroundColor: "white",
    height: "auto",
    width: "auto",
    minWidth: 380,
    boxShadow: "2px 2px 5px 5px #aaa",
  },
  gridContainer: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  contact: {
    display: "flex",
    alignItems: "center",
    padding: 3,
    color: "rgb(149,149,149)",
    fontSize: 14,
  },
  logo: { height: "auto", maxWidth: 240, padding: 50, paddingTop: 20 },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  primaryMain: { background: theme.palette.primary.main },
}));

const LoginForm: FC = () => {
  const { t } = useTranslation("login");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const classes = useStyles({});
  const { onLoginSuccess } = useUserContext();
  const [adminLogin] = useAdminLogin();

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ minWidth: 100 }}>ID:</div>
        <TextField
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ minWidth: 100 }}>Password:</div>
        <TextField
          className={classes.textField}
          margin="normal"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={async (e) => {
            if (e.key === "Enter") {
              await adminLogin({
                variables: { adminLoginInput: { username, password } },
              })
                .then((resp: any) => {
                  onLoginSuccess(resp.data.adminLogin);
                })
                .finally();
            }
          }}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        style={{ margin: 20, width: "50%" }}
        onClick={() =>
          adminLogin({ variables: { adminLoginInput: { username, password } } })
            .then((resp: any) => {
              onLoginSuccess(resp.data.adminLogin);
            })
            .finally()
        }
      >
        Login
      </Button>
    </>
  );
  // <Form data={{ username, password }} fields={fields} actions={actions} />
};

export const LoginPage: FC<{}> = () => {
  const classes = useStyles({});
  const { isLogin } = useUserContext();

  return isLogin ? (
    <Redirect to={RouteEnum.Home} />
  ) : (
    <div
      className={classes.root}
      style={{ backgroundColor: "rgb(207,207,207)" }}
    >
      <Container
        maxWidth="lg"
        style={{ justifyContent: "center" }}
        className={classes.container}
      >
        <Grid
          container={true}
          md={9}
          xs={7}
          className={classes.gridPaper}
          alignItems="center"
          justify="center"
        >
          <Grid
            item={true}
            xs={8}
            md={5}
            className={classes.gridContainer}
            style={{ padding: 50, maxHeight: 500 }}
          >
            <img
              className={classes.logo}
              src={`${require("assets/image/logo.png")}`}
              alt="logo"
            />
            {/* <Hidden smDown>
              <div style={{ width: '100%' }}>
                <div className={classes.contact}>
                  <LinkIcon color="primary" style={{ marginRight: 5 }} />
                  {`http://wemakeapp.net/`}
                </div>
                <div className={classes.contact}>
                  <MailIcon color="primary" style={{ marginRight: 5 }} />
                  {'info@WeMakeapp.net'}
                </div>
                <div className={classes.contact}>
                  <PhoneIcon color="primary" style={{ marginRight: 5 }} />
                  {'(852) 3428 8328'}
                </div>
              </div>
            </Hidden> */}
          </Grid>
          <Hidden smDown>
            <div
              className={classes.primaryMain}
              style={{ width: 2, height: 400, margin: 30 }}
            ></div>
          </Hidden>
          <Hidden mdUp>
            <div
              className={classes.primaryMain}
              style={{ width: 400, height: 2, margin: 30 }}
            ></div>
          </Hidden>

          <Grid
            item={true}
            xs={12}
            md={5}
            className={classes.gridContainer}
            style={{ padding: 40 }}
          >
            <div
              style={{
                fontSize: 30,
                color: "rgb(219,69,71)",
                paddingBottom: 50,
                paddingTop: 20,
                width: "100%",
                textAlign: "center",
              }}
            >
              Millionbb Administrator Login
            </div>
            <LoginForm />
          </Grid>
        </Grid>
        <img
          src={`${require("assets/image/color_bar.png")}`}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            height: 30,
            width: "100%",
            objectFit: "cover",
          }}
          alt="color"
        />
      </Container>
    </div>
  );
};

export default LoginPage;
