import gql from 'graphql-tag';

export const subscriptions = gql`
  query subscriptions($input: SubscriptionQueryInput!) {
    subscriptions(input: $input) {
      subscriptions {
        subscriptionId
        user {
          userId
          nickname
          email
        }
        paymentType
        plan
        currency
        amount
        status
        nextChargeDt
        createDt
      }
      page
      pageSize
      count
    }
  }
`;
