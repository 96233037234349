import React from 'react';
import { withMobileDialog, Dialog, DialogContent } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';

export const ResponsiveDialog = withMobileDialog({ breakpoint: 'sm' })((p: DialogProps) => {
  const { children, ...props } = p;

  return (
    <Dialog fullWidth={true} {...props}>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
});
