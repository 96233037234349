import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
interface IConfirmDialogue {
  isDialogue: boolean;
  action: string;
  setDialogue: (isDialogue: boolean) => void;
  confirm: any;
  handleCancel: () => void;
}

export const ConfirmDialogue: FC<IConfirmDialogue> = props => {
  return (
    <>
      <Dialog
        open={props.isDialogue}
        onClose={() => props.handleCancel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{`Do you Confirm to ${props.action} ? `}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Do you Confirm to ${props.action} ? `}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.setDialogue(false);
            }}
            color="primary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              props.confirm.handle();
              props.setDialogue(false);
            }}
            color="primary"
            autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ConfirmDialogue;
