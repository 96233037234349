import gql from 'graphql-tag';
import { UserFull } from '../fragment';

export const userVerify = gql`
  ${UserFull}

  mutation userVerify($userVerifyInput: UserVerifyInput!) {
    userVerify(input: $userVerifyInput) {
      ...UserFull
    }
  }
`;

export const userPutVerify = gql`
  ${UserFull}

  mutation userPutVerify($userPutVerifyInput: UserPutVerifyInput!) {
    userPutVerify(input: $userPutVerifyInput) {
      ...UserFull
    }
  }
`;

export default userVerify;
