import gql from 'graphql-tag';
import { UserFull } from '../fragment';

export const user = gql`
  query user($singleUserInput: SingleUserInput!) {
    user(input: $singleUserInput) {
      idToken
      userId
      gender
      email
      phone
      nickname
      dob
      city
      logo
      logoHalf
      logoFull
      ageMin
      ageMax
      whatsapp
      wechat
      telegram
      line
      aboutEconomy
      aboutHeight
      aboutBody
      aboutWaist
      aboutStyle
      aboutDrink
      aboutSmoke
      aboutMe
      dateExpense
      dateOutline
      contactPrivacy
      lang
      showAdult
      credit
      creditSpent
      silverEndDt
      premiumEndDt
      role
      frdQuota
      msgQuota
      status
      millionbb
      princessDating
      lastSeen
      voice
      ageVerify
      referrer
      referralCode
      wine
      emptyWine
      rateLevel
      restrictedLevel
      vip
      rateSummary {
        rate
        count
      }
    }
  }
`;

export const users = gql`
  ${UserFull}

  query users($userSearchInput: UserSearchInput!) {
    users(input: $userSearchInput) {
      ...UserFull
    }
  }
`;

export const searchUsers = gql`
  ${UserFull}

  query searchUsers($userSearchInput: UserSearchInput!) {
    searchUsers(input: $userSearchInput) {
      users {
        ...UserFull
      }
      page
      pageSize
      count
    }
  }
`;
