import gql from 'graphql-tag';
export const fileUploads = gql`
  query fileUploads($page: Int!, $pageSize: Int!) {
    files(input: { page: $page, pageSize: $pageSize }) {
      files {
        fileId
        user {
          userId
          nickname
        }
        userId
        albumId
        filename
        description
        permission
        isAdult
        status
        createDt
        lastModifyDt
      }
      page
      pageSize
      count
    }
  }
`;
export const fileUserUploads = gql`
  query fileUploads($page: Int!, $pageSize: Int!, $username: String, $userId: ID) {
    files(input: { page: $page, pageSize: $pageSize, username: $username, userId: $userId }) {
      files {
        fileId
        user {
          nickname
        }
        userId
        albumId
        filename
        description
        permission
        isAdult
        status
        createDt
        lastModifyDt
      }
      page
      pageSize
      count
    }
  }
`;
