import { SERVER_URL } from 'conf/env';
import { City, Subdivision, Country } from 'millionbb-common';
export const getUserFolder = (userId: string | number) => {
  const id = `${userId}`.padStart(8, '0');

  return `${SERVER_URL}/uploads/${id.slice(0, 4)}/${id.slice(-4)}/`;
};
export const findCity = (cityString: string): string => {
  if (cityString) {
    let [country, sub] = cityString.split('_');
    sub = Subdivision[`${country}_${sub}`] ? Subdivision[`${country}_${sub}`] + ', ' : '';
    country = Country[country];
    return `${City[cityString]}, ${sub}${country}`;
  } else return '-';
};
