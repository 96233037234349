import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from 'assets/i18n/en';
import zhhant from 'assets/i18n/zh-hant';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    defaultNS: 'common',
    fallbackLng: 'zh-hant',
    interpolation: { escapeValue: false },
    ns: ['common'],
    resources: {
      en,
      'zh-Hant': zhhant,
    },
  });

export default i18n;
